@system-font: 'Helvetica Neue', 'Helvetica', Roboto, sans-serif;
@code-font: 'Monaco', 'Lucida Console', monospace;

body {
    background-color: hsl(204, 10%, 88%);
    overflow: hidden;
    font-family: @system-font;
}

.App {
    position: relative;
    .vertical-flex;
    width: 100vw;
    height: 100vh;
    min-width: 600px;
    font-family: @system-font;

    > :not(.Header) {
        width: 100vw;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0); // Chrome-fix: otherwise soft scrollbar will not be visible.
    }
}

a {
    color: @color-selected;
    text-decoration: none;

    &:active {
        color: @color-selected-deep;
    }
}

.link-gradient-hover {
    background: linear-gradient(to top,rgb(25, 182, 255) 10%, rgb(0, 94, 255));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.link-gradient-active {
    background: linear-gradient(to top,rgb(57, 192, 255), @color-selected-deep);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fixed-width,
pre {
    font-family: @code-font;
    user-select: all;
}

button {
    outline: none;
}

button.button-link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 1em;

    &:hover, &:focus {
        text-decoration: none;
    }
}

.alert {
    color: @color-alert-danger;
}

.solidify {
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
}

.arrow {
    cursor: pointer;

    .arrow-styling() {
        display: inline-block;
        font-family: 'seatsdesigner';
        line-height: 1;
        font-size: 0.7em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:not(.back)::after {
        .arrow-styling();
        content: "\e927";
        margin-left: 4px;
    }

    &.back::before {
        .arrow-styling();
        content: "\e927";
        transform: rotate(180deg);
        font-size: 0.8em;
        margin-right: 4px;
    }
}

.external-link {
    &::after {
        content: "\e982";
        display: inline-block;
        font-family: 'seatsdesigner';
        line-height: 1;
        font-size: 0.8em;
        margin-left: 0.5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.loading-placeholder {
    animation: fade-in 2s @ease-out-cubic;
}

.fake-item {
    width: 100%;
    height: 1em;
    border-radius: 0.15em;
    margin-bottom: 1em;
    animation: fake-item-pulse 2s ease-in-out infinite backwards;
    background-color: rgba(0, 0, 0, 0.07);
    transform-origin: center bottom;
    .stepped-child-delay(20);

    &.double {
        flex-grow: 0.5;
    }
}

.stepped-child-delay(@i) when (@i > 0) {
    .stepped-child-delay((@i - 1));
    &:nth-child(@{i}) {
        animation-delay: unit(@i * 0.25, s);
    }
}

@keyframes fake-item-pulse {
    from, 90%, to {
        opacity: 1;
        transform: scale(1);
    }
    45% {
        opacity: 0.8;
        transform: scale(0.92);
    }
}

@keyframes fake-item-vertical-grow {
    from, 90%, to {
        opacity: 0.7;
        transform: scaleY(0.5);
    }
    45% {
        opacity: 1;
        transform: scale(1);
    }
}

.focus-ring {
    box-shadow: 0 0 0 20px @color-focus-outline-transparent;
    transition: box-shadow 0.5s @ease-out-cubic;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 @focus-ring-width @color-focus-outline;
    }
}

.intercom-launcher-frame,
.intercom-messenger-frame {
    transition:
        bottom .6s @ease-out-cubic .2s,
        opacity .3s @ease-out-cubic !important;
}

.table-tools {
    display: inline-block;
    white-space: nowrap;
}

.small-left-and-right-space {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.left-and-right-space {
    margin-left: 1em;
    margin-right: 1em;
}

.small-top-and-bottom-space {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.top-and-bottom-space {
    margin-top: 1em;
    margin-bottom: 1em;
}
