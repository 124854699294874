

.waiting-indicator {
    .LoadingIndicator {
        position: relative;
        top: -6px;
        animation: fade-in 0.3s @ease-out-cubic;
    }

    .done-indicator {
        position: absolute;
        left: 0;
        right: 0;
        top: -2px;
        overflow: visible;
        visibility: hidden;

        > .stamp {
            position: relative;
            width: 28px;
            height: 28px;
            margin: 0 auto;

            .stroke {
                .full-size-absolute;
                border-radius: 100px;
                box-shadow: inset 0 0 0 1.5px @color-alert-valid;
            }
            .icon {
                .full-size-absolute;
                font-size: 18px;
                text-align: center;
                line-height: 28px;
                color: @color-alert-valid;
            }
        }

        > .message {
            margin-top: 0.25em;
            color: @color-panel-face-half;
            font-size: 13px;
            text-align: center;
        }
    }

    &.done {
        .LoadingIndicator {
            width: 16px;
            transform: scale(0.3);
            opacity: 0;
            transition: all 0.5s @ease-out-cubic;
        }

        .done-indicator {
            visibility: visible;

            > .stamp {
                animation: stamp-scale-in 0.45s @ease-long-rubber-band-in 0.2s backwards;

                > .stroke {
                    animation: stamp-stroke-in 0.45s @ease-in-out-cubic 0.1s backwards;
                }
                > .icon {
                    animation: fade-in 0.7s @ease-out-cubic 0.2s backwards;
                }
            }
            > .message {
                animation: fade-in 1s @ease-out-cubic 0.8s backwards;
            }
        }
    }
}
