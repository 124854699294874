.invitations {
    .DataTable tr {
        .cancel-invitation {
            .solidify;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.6s @ease-out-cubic;

            &:active {
                color: rgba(180, 180, 180, 0.95);
            }
        }

        &:hover .cancel-invitation {
            opacity: 1;
        }
    }
}
