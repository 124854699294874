@header-short-height: 52px;
@header-expanded-height: 85px;
@header-short-height-with-admin-banner: 82px;
@header-expanded-height-with-admin-banner: 115px;



.Header {
    .vertical-flex;
    position: relative;
    z-index: 5100;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    color: white;
    font-size: 14px;
    transition: height 0.5s @ease-out-cubic;

    &.with-context-actions {
        height: @header-expanded-height;

        .contextual-actions {
            opacity: 1;
            transition-delay: 0, 0.2s;
        }

        &.with-admin-banner {
            height: @header-expanded-height-with-admin-banner;
        }
    }

    &:not(.with-context-actions) {
        height: @header-short-height;

        .contextual-actions {
            opacity: 0;
        }

        &.with-admin-banner {
            height: @header-short-height-with-admin-banner;
        }
    }

    .floating-container {
        .vertical-flex;
        flex-grow: 1;
        position: relative;
        z-index: 1000;
        width: inherit;
        box-sizing: border-box;
        background: linear-gradient(to top, hsl(210, 19%, 13%), hsl(210, 3%, 15%) 20%, hsl(180, 3%, 22%));
        border-bottom: 1px solid black;
        box-shadow:
            inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.2),
            inset 0 1px 0 0 hsla(55, 100%, 95%, 0.05);
    }

    .superadmin-info {
        background-color: @color-alert-warning-deep;
        padding: 0.4em 1em 0.6em;
        text-align: center;
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .navigation,
    .contextual-actions {
        .horizontal-flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        flex-shrink: 0;
    }

    .navigation {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 52px;
        padding: 0 25px;
        box-sizing: border-box;

        .left-anchor {
            .horizontal-flex;

            .logo-and-status {
                position: relative;
                width: 119.7px;
                height: 20.7px;

                .logo,
                .status {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: opacity 1s @ease-out-cubic;
                }

                .status {
                    pointer-events: none;

                    &.offline {
                        border-radius: 5px;
                        padding: 4px 6px;
                        border: 1px solid white;
                        font-weight: bold;
                        line-height: 1em;
                        text-transform: uppercase;
                        opacity: 0;
                    }
                }

                .logo {
                    width: inherit;
                    height: inherit;
                    transition-delay: 0.4s;

                    > img {
                        width: inherit;
                        height: inherit;
                    }
                }
            }

            .menu-button {
                margin-left: 20px;
            }
        }

        nav {
            .solidify;
            .horizontal-flex;
            position: relative;
            top: -1px;
            padding: 1px;
            border-radius: 2em;
            background: linear-gradient(to top, #1d2022, #181a1a);
            box-shadow:
                inset 0 1px 0 rgba(0, 0, 0, .1),
                0 1px 0 rgba(255, 255, 255, .07);

            .dropdown-menu {
                cursor: pointer;
                .horizontal-flex;
                color: hsl(0, 0%, 85%);
                padding: 3px 25px 3px 23px;

                > .icon {
                    font-size: 20px;
                    margin-right: 10px;
                }

                > .dropdown-icon {
                    position: relative;
                    top: 1px;
                    font-size: 14px;
                    margin-left: 10px;
                }

                &:active,
                &.active {
                    color: #fff;
                }
            }
        }

        nav > a {
            display: inline-block;
            min-width: 120px;
            padding: 5px;
            border-radius: 2em;
            color: rgba(255, 255, 255, .6);
            text-align: center;
            text-shadow: 0 1px 0 rgba(0, 0, 0, .2);
            margin-right: 6px;
            transition: all 0.2s @ease-out-cubic;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: rgba(255, 255, 255, .75);
            }

            &.active {
                text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
                color: rgb(255, 255, 255);
                background: linear-gradient(to top, #474c4e, #505658);
                box-shadow:
                    0 1px 0 rgba(0, 0, 0, .2),
                    inset 0 1px 0 rgba(255, 255, 255, .1);
            }
        }

        .center-navigation {
            .horizontal-flex;

            .Badge {
                margin-right: 12px;
            }
        }

        .menu-button {
            cursor: pointer;
            .horizontal-flex;
            position: relative;
            top: -1px;
            border-radius: 2em;
            margin-left: 6px;

            > .dropdown-icon {
                margin-left: 6px;
                font-size: 16px;
                color: white;
                opacity: 0.4;
            }

            .inset {
                padding: 1px;
                border-radius: 3em;
                background: linear-gradient(to top, #1d2022, #181a1a);
                box-shadow:
                    inset 0 1px 0 rgba(0, 0, 0, .1),
                    0 1px 0 rgba(255, 255, 255, .07);
            }

            button {
                cursor: pointer;
                appearance: none;
                border: none;
                .horizontal-flex;
                justify-content: center;
                width: 30px;
                height: 30px;
                padding: 0;
                border-radius: 3em;
                background: linear-gradient(to top, #474c4e, #505658);
                box-shadow:
                    0 1px 0 rgba(0, 0, 0, .2),
                    inset 0 1px 0 rgba(255, 255, 255, .1);
                color: hsl(0, 0%, 85%);
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);

                .icon-new-notification {
                    position: relative;
                    font-size: 18px;
                    width: 1em;
                    height: 1em;
                    text-shadow: 0 1px 0 rgba(22, 0, 0, 0.1);
                }

                &.large {
                    width: 32px;
                    height: 32px;

                    .icon-new-notification {
                        font-size: 20px;
                    }
                }

                &.alert {
                    background: linear-gradient(to top, hsl(17, 92%, 54%), hsl(5, 100%, 43%) 30%, hsl(29, 100%, 52%));
                    animation: alert-pulse 2.5s @ease-out-cubic infinite;
                    color: hsl(38, 100%, 98%);

                    &:hover {
                        background: linear-gradient(to top, hsl(32, 92%, 54%), hsl(5, 98%, 49%) 30%, hsl(39, 100%, 53%));
                    }

                    &:active {
                        background: linear-gradient(to top,  hsl(17, 91%, 46%), hsl(5, 100%, 37%) 30%, hsl(29, 100%, 45%));
                    }

                    @keyframes alert-pulse {
                        from, 90%, to {
                            filter: none;
                            box-shadow:
                                0 0 100px 20px hsla(17, 98%, 47%, 0),
                                0 0 15px 1px hsla(32, 100%, 52%, 0),
                                inset 0 0 2px 1px hsla(32, 100%, 52%, 0.3);
                        }
                        25%, 50% {
                            filter: brightness(1.3) contrast(1.3);
                            box-shadow:
                                0 0 100px 20px hsl(17, 98%, 47%),
                                0 0 15px 1px hsla(32, 100%, 52%, 0.9),
                                inset 0 0 2px 1px hsla(32, 100%, 52%, 0.8);
                        }
                    }
                }
            }

            .avatar {
                cursor: pointer;
				display: block;
                width: 30px;
                height: 30px;
                border-radius: 100px;
                background-color: hsla(0, 100%, 100%, 0.1);
                background-size: cover;
            }

            &:hover {
                .dropdown-icon {
                    opacity: 0.6;
                }
                button {
                    background: linear-gradient(to top, hsl(197, 5%, 31%), hsl(195, 5%, 35%));
                }
            }
            &:active {
                .dropdown-icon {
                    opacity: 0.4;
                }
                button {
                    background: linear-gradient(to top, hsl(197, 5%, 26%), hsl(195, 5%, 29%));
                }
            }
            &.pressed {
                button {
                    text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
                    background: linear-gradient(to top, hsl(195, 5%, 29%), hsl(197, 5%, 26%));
                }
            }

            & + .menu-button {
                margin-left: 20px;
            }
        }

        .account {
            position: relative;
            top: -1px;
            .solidify;
            .horizontal-flex;

            > .caption {
                .vertical-flex;
                align-items: flex-end;
                line-height: 1em;

                .original {
                    font-size: 11px;
                    color: rgba(231, 231, 231, 0.6);
                }
                .current {
                    font-size: 13px;
                    color: rgb(231, 231, 231);
                }
            }

            .upgrade-button {
                cursor: pointer;
                border-radius: 4px;
                background: hsl(0, 0%, 90%);
                color: hsl(0, 0%, 20%);
                padding: 3px 8px;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 15px;
                margin-bottom: 2px;

                &:hover {
                    background: hsl(0, 0%, 100%);
                }
                &:active {
                    background: hsl(0, 0%, 85%);
                    color: hsl(0, 0%, 0%);
                }
            }
        }
    }

    .contextual-actions {
        .horizontal-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 25px;
        box-sizing: border-box;
        transition:
            padding 0.3s @ease-out-cubic,
            opacity 0.5s @ease-out-cubic;

        > div {
            .horizontal-flex;
        }

        > div > button,
        > div > .button,
        > div > .soft-button {
            position: relative;
            cursor: pointer;
            display: block;
            height: 25px;
            padding: 1px 14px 6px 36px;
            margin-right: 10px;
            box-sizing: border-box;
            border-radius: 4px;
            border: none;
            background: hsla(190, 3%, 38%, 0.75);
            box-shadow:
                inset 0 1px rgba(255, 255, 255, .08),
                0 0 0 1px rgba(0, 0, 0, .05);
            background-clip: padding-box;
            color: white;
            font-size: 0;
            line-height: auto;
            outline: none;
            text-align: left;
            overflow: hidden;
            transition: background-color 0.2s @ease-out-cubic;

            > .icon {
                position: absolute;
                left: 12px;
                top: 3px;
                font-size: 19px;
                color: hsla(0, 0%, 100%, 0.9);
            }

            > .caption {
                padding: 0;
                margin: 0;
                font-size: 13px;
                line-height: 22px;
                text-overflow: ellipsis;
            }

            &:hover {
                background-color: hsla(190, 3%, 38%, 0.85);
            }

            &:active {
                background-color: hsla(193, 7%, 26%, 0.5);
                transition-duration: 0;
                box-shadow:
                    inset 0 1px rgba(255, 255, 255, .08),
                    0 0 0 1px rgba(0, 0, 0, .4);
            }

            &.highlighted {
                background: linear-gradient(to bottom, #0784fa, #006aec);

                &:hover {
                    background: linear-gradient(to bottom, #0a95ff, #0784fa);
                }

                &:active {
                    background: linear-gradient(to bottom, #006aec, #004bcc);
                }
            }
        }

        > div > a.button > .caption {
            position: relative;
            top: 1px;
        }

        > div > .soft-button {
            padding-top: 2px;
            padding-left: 18px;
            background: none !important;
            border: none !important;
            box-shadow: none !important;
            line-height: 1.3em;
            color: rgba(255, 255, 255, 0.8);
            background: transparent !important;

            > .icon {
                position: absolute;
                left: 0;
                top: 6px;
                font-size: 14px;
            }

            > .caption {
                font-size: 14px;
            }

            &:hover {
                color: rgba(255, 255, 255, 0.85);
            }

            &:active {
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }

    .offline-background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to top, #381818, #581d1c);
        opacity: 0;
        transition: opacity 3s @ease-out-cubic;
    }

    &.offline {
        .offline-background {
            opacity: 1;
        }

        .left-anchor .logo-and-status {
            .logo {
                opacity: 0;
            }
            .status.offline {
                opacity: 1;
                transition-delay: 0.8s;
            }
        }
    }

    @media @narrow-width {
        .navigation {
            .left-anchor .logo-and-status .logo {
                width: 99.75px;
                height: 17.25px;
            }
            nav > a {
                min-width: auto;
                padding-right: 15px;
                padding-left: 15px;
            }
            .account .caption {
                display: none;
            }
        }

        .contextual-actions {
            > div > .button {
                margin-right: 2px;
            }
            .SearchInput {
                width: 260px;
            }
        }
    }

    @media @thin-width {
        .navigation {
            padding-left: 8px;
            padding-right: 8px;

            .left-anchor {
                display: none;
            }
            > nav {
                font-size: 13px;
            }
        }
        .contextual-actions {
            padding-left: 8px;
            padding-right: 8px;

            > div > .button {
                padding: 2px 10px 2px 28px;
                > .icon {
                    left: 9px;
                    top: 6px;
                    font-size: 14px;
                }
                > .caption {
                    font-size: 12px;
                }
            }
            .SearchInput {
                width: 220px;
            }
        }
    }
}
