.EventReport {
    width: 100vw;
    padding-bottom: 10px;

    > .container {
        max-width: 1040px;
        min-height: 30vh;
        margin-bottom: 5px;
        padding-top: 30px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;
        margin: 0 auto;
        background-color: white;

        > .loading-placeholder {
            .vertical-flex;
            justify-content: center;
            margin-bottom: 50px;

            > .fake-item {
                width: 50%;

                &:nth-child(1) {
                    font-size: 30px;
                }
            }
        }

        > .title {
            margin: 0 auto 10px;
            font-size: 26px;
            font-weight: 500;
            text-align: center;
        }

        > .sub-title {
            margin: 0 auto 5px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        > .DataTable {
            margin-top: 30px;
            overflow-y: auto;

            tr:last-child > td:first-child {
                border-bottom-left-radius: 6px;
            }

            tr:last-child > td:last-child {
                border-bottom-right-radius: 6px;
            }
        }
    }
}
