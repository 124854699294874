/*** Common animations ***/

@keyframes gui-standby-loading-out {
	from, to {
        transform: scale(1);
        filter: saturate(1.0) opacity(0.9);
	}
	20% {
		transform: scale(0.95);
        filter: saturate(0.4) opacity(0.6);
	}
}
@gui-standby-loading-out-animation: ~"gui-standby-loading-out 1s ease-in-out infinite";

@keyframes gui-standby-loading {
	from, to {
        transform: scale(1);
        filter: saturate(1.0) opacity(0.9);
	}
	20% {
		transform: scale(0.95);
        filter: saturate(0.4) opacity(0.6);
	}
	40% {
		transform: scale(1.06);
        filter: saturate(0.4) opacity(0.9);
	}
}
@gui-standby-loading-animation: ~"gui-standby-loading 1.1s ease-in-out infinite";

@keyframes gui-standby-loading-large {
	from, to {
		opacity: 0.9;
        transform: scale(1);
        filter: saturate(1.0);
	}
	30% {
		opacity: 0.6;
		transform: scale(0.99);
        filter: saturate(0.4);
	}
}
@gui-standby-loading-animation-large: ~"gui-standby-loading-large 1.1s ease-in-out infinite";

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes background-color-fade-in {
    from {
        background-color: hsla(0, 0%, 0%, 0);
    }
}

@keyframes zoom-in {
    from {
        transform: scale(0.4);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slight-zoom-in {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}



/*** Easings ***/

// Ease in/out cubic.
@ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
@ease-out-cubic: cubic-bezier(0.19, 1, 0.22, 1);

// Ease in & out cubic.
@ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

// Quint.
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

// Rubber band easing at the end of the animation.
@ease-rubber-band-in: cubic-bezier(0.37, 1.98, 0.43, 0.76);

// Longer lasting rubber band easing at the end of the animation.
@ease-long-rubber-band-in: cubic-bezier(0.44, 2.16, 0.47, 0.74);
@ease-soft-rubber-band-in: cubic-bezier(0.17, 1.58, 0.22, 0.99);

// Rubber band easing at the beginning of the animation.
@ease-rubber-band-out: cubic-bezier(0.35, -0.7, 0.39, 1);
