.ManagePaymentMethods {
    padding-bottom: 10px;

    > .container {
        max-width: 1040px;
        border-radius: 6px;
        box-sizing: border-box;
        margin: 20px auto;
        background-color: white;
        padding-bottom: 20px;

        > .title {
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 20px;
            padding-top: 30px;
            padding-left: 30px;
        }
    }

    > .loading-placeholder {
        .horizontal-flex;
        justify-content: space-around;
        max-width: 1040px;
        margin: 10px auto 10px;

        > .fake-item {
            width: 18%;
        }
    }

    .actions {
        display: flex;
        margin-top: 8px;
        padding-left: 30px;

        > .action-note {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }

        > .action-note {
            .solidify;
            display: flex;
            height: 1.5em;
            flex-direction: row;
            align-items: center;
            color: @color-panel-face-disabled;

            .icon {
                position: relative;
                top: 1px;
                margin-inline-end: 5px;
                font-size: 16px;
            }

            > .label {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }


    .DataTable {
        overflow-y: auto;

        th:nth-child(5), th:nth-child(6),
        td:nth-child(5), td:nth-child(6) {
            text-align: center;
        }
        td:last-of-type {
            text-align: right;
            & > button {
                display: inline-block;
            }
        }

        tr:last-child > td:first-child {
            border-bottom-left-radius: 6px;
        }

        tr:last-child > td:last-child {
            border-bottom-right-radius: 6px;
        }
    }

    .DataTable table tr {
        &.enter {
            animation-delay: 0.5s;
        }

        & > td.card-status {
            display: flex;
        }

        .action-cell .icon {
            opacity: 0.5;
            transition: opacity 0.2s @ease-out-cubic;

            &:hover {
                opacity: 1;
                transition-delay: 0.1s;
            }

        }
    }
}
