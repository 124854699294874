.Notifications {
    .solidify;
    .vertical-flex;
    position: fixed;
    right: 20px;
    top: 110px;
    z-index: 5000;

    .Notification {
        cursor: pointer;
        position: relative;
        width: 25vw;
        margin-top: 10px;
        border-radius: 6px;
        background: @color-panel-surface;
        box-shadow:
            inset 0 0 0 1px hsla(0, 0%, 100%, 0.8),
            0 3px 10px hsla(0, 0%, 0%, 0.12);

        &::before {
            content: "";
            display: block;
            pointer-events: none;
            border-radius: inherit;
            position: absolute;
            left: -1px;
            top: -1px;
            right: -1px;
            bottom: -1px;
            border: 1px solid hsla(0, 0%, 2%, 0.2);
        }

        .icon,
        .description {
            transition: opacity 0.6s @ease-out-cubic 0.2s;
        }

        .description {
            padding: 9px 15px 10px;
            box-sizing: border-box;
            color: #222;

            > .title {
                font-size: 14px;
                font-weight: 500;
            }
            > .message {
                font-size: 13px;
            }
            .title + .message {
                margin-top: 0.25em;
            }
        }

        .hint {
            pointer-events: none;
            .full-size-absolute;
            .vertical-flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.6s @ease-out-cubic;
            font-size: 13px;
        }

        &:hover {
            &::before {
                border-color: hsla(0, 0%, 2%, 0.25);
            }

            > .icon,
            > .description {
                opacity: 0;
                transition-delay: 0s;
            }

            > .hint {
                opacity: 1;
                transition-delay: 0.2s;
            }
        }

        &:active {
            background: hsl(0, 0%, 94%);
        }

        &.error {
            &::before {
                border-width: 1.5px;
                border-color: hsla(0, 100%, 41%, 0.5);
            }
            &:hover {
                &::before {
                    border-color: hsla(7, 100%, 41%, 0.6);
                }
            }
        }

        &.warning {
            &::before {
                border-width: 1.5px;
                border-color: hsla(39, 100%, 50%, 0.9);
            }
            &:hover {
                &::before {
                    border-color: hsla(36, 100%, 50%, 0.92);
                }
            }
        }

        > .progress-bar {
            position: relative;
            top: 0px;
            height: 1px;
            padding: 0 15px 10px;

            > .bar {
                position: absolute;
                left: 15px;
                right: 15px;
                top: 0;
                height: inherit;
            }

            > .progress {
                position: absolute;
                left: 15px;
                right: 15px;
                height: inherit;
                background: black;
                animation: progress-bar-grow linear forwards;

                @keyframes progress-bar-grow {
                    from {
                        transform: scaleX(1);
                        opacity: 0.7;
                    }
                    to {
                        transform: scaleX(0);
                        opacity: 1;
                    }
                }
            }
        }

        &.notification-enter {
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.5s @ease-long-rubber-band-in;

            &.notification-enter-active {
                opacity: 1;
                transform: scale(1);
            }
        }

        &.notification-exit {
            max-height: 70px;
            transform: scale(1);
            opacity: 1;
            transition:
                transform 0.4s @ease-out-cubic,
                opacity 0.4s @ease-out-cubic,
                max-height 0.4s @ease-out-cubic 0.1s,
                margin-top 0.4s @ease-out-cubic 0.1s;

            &.notification-exit-active {
                margin-top: 0;
                max-height: 0;
                transform: scale(0.7);
                opacity: 0;
            }
        }
    }
}
