.PageSwitcher {
    .horizontal-flex;
    .solidify;
    height: 32px;
    flex-shrink: 0;
    border-bottom: 1px solid hsla(0, 0%, 0%, 10%);
    box-sizing: border-box;

    > a {
        position: relative;
        display: block;
        height: inherit;
        box-sizing: border-box;
        color: #8C8C8C;
        padding: 7px 15px 3px;
        font-size: 14px;
        transition: color 0.3s @ease-out-cubic;

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: -0.5px;
            display: block;
            height: 1px;
            background: rgba(17, 17, 17, 0);
            transition: inherit;
            transition-property: left, right, background-color;
        }

        &:hover {
            color: #666;
        }

        &.selected,
        &:active {
            color: #111;
            transition-duration: 0.2s;

            &::after {
                left: 15px;
                right: 15px;
                background-color: #111;
            }
        }
    }
}
