.ChartArchive {
    height: 100vh !important;
    padding: 10px 0 20px;

    .infinite-scroll-container {
        position: relative;
        left: 15px;
        max-width: 1040px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media @narrow-width {
            left: 0;
            justify-content: center;
        }
    }

    .archived-chart {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 330px;
        height: 380px;
        margin-top: 20px;
        margin-right: 10px;
        background-color: white;
        border-radius: 6px;

        > .fake-item {
            font-size: 24px;

            &.long {
                margin-top: 20px;
                width: 70%;
            }
            &.square {
                width: 160px;
                height: 160px;
            }
            &.short {
                visibility: hidden;
            }
        }

        &.loading {
            animation: @gui-standby-loading-animation-large;

            .title,
            .Thumbnail,
            .ghost-button {
                opacity: 0.6;
            }
        }
    }

    .ghost-button {
        background-color: white;
        border: 1px solid @color-selected;
        color: @color-selected;
        font-size: 14px;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        padding: 4px 20px 5px;
        margin-bottom: 5px;

        &:active {
            color: @color-selected-deep;
            border-color: @color-selected-deep;
        }
    }

    .title {
        padding: 10px 30px;
        min-height: 2.4em;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        word-break: break-all;
    }

    &.empty-list {
        font-size: 18px;
        margin-top: 20px;
    }

    .empty-page-message{
        width: inherit;
    }

}
