.PlanDetails {
	margin: 1rem 0 1.5rem 0;
	padding: 1.5rem 2rem;
	border-radius: 5px;
	background: rgb(244, 244, 244);
	font-size: 1rem;

	> .title {
		display: block;
		font-size: 1.4rem;
		margin-bottom: .25rem;
		font-weight: 600;
	}
}