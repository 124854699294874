.Billing {
    .horizontal-flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 80vh;
    min-height: calc(100vh - @header-short-height);

    code {
        font-family: @code-font;
        font-size: 0.9em;
        background-color: @color-panel-surface;
    }

    .sidebar {
        position: sticky;
        top: 100px;
        display: flex;
        flex-direction: column;
        width: 150px;

        > a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-decoration: none;
            padding: 10px;
            text-align: right;
            font-size: 14px;
            font-weight: bold;
            border-radius: 10px 0 0 10px;
            transform-origin: center right;
            transition:
                background 0.5s @ease-out-quint,
                transform 0.5s @ease-out-quint;

            &.active {
                cursor: default;
                color: black;
                background-color: white;
            }

            &:not(.active):hover {
                transform: scale(1.05);
            }
        }
    }

    .simple-page {
        @font-size: 14px;
        width: 100%;
        max-width: 800px;
        background-color: white;
        min-height: 80vh;
        min-height: calc(100vh - @header-short-height);
        font-size: @font-size;

        .loading-placeholder {
            padding: 15px 25px;

            & + .loading-placeholder {
                border-top: 1px solid #e6e6e6;
            }

            > .fake-item {
                height: 2em;
                width: 50%;
                border-radius: 0.25em;
                margin-top: 1em;
            }
        }

        > .page-title {
            padding-top: 30px;
            padding-left: 30px;
            font-size: 32px;
            font-weight: 600;
        }
    }
}
