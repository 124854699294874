@import "colors";

.PaymentSummary {
	.container {
		padding: 2em;
		text-align: center;

		.iconContainer {
			--icon-size: 64px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			width: var(--icon-size);
			height: var(--icon-size);
			border-radius: 100%;
			text-align: center;
			margin-bottom: 2em;

			& > .icon {
				font-size: 42px;
				font-weight: bold;
			}
		}

		.processingIconContainer {
            .iconContainer;
			border: 3px solid @color-info;

			& > .icon {
				color: @color-info;
			}
		}

		.succeededIconContainer {
            .iconContainer;
			border: 3px solid @color-alert-valid;

			& > .icon {
				color: @color-alert-valid;
			}
		}

		.failedIconContainer {
            .iconContainer;
			border: 3px solid @color-alert-danger;

			& > .icon {
				color: @color-alert-danger;
			}
		}

		.noFinalStateIconContainer {
            .iconContainer;
			border: 3px solid @color-info;

			& > .icon {
				color: @color-info;
			}
		}

		.actionButton {
			margin-top: 1.5em;
		}

		& > .details {
			max-width: 600px;
			font-size: 16px;
			margin: 2em auto 1em auto;
		}

		.heading {
			font-size: 22px;
			margin-bottom: .5em;
		}
	}
}
