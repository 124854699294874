.empty-page-message {
    .solidify;
    .vertical-flex;
    justify-content: center;
    height: 100%;

    .title {
        color: @color-panel-face-half;
        font-size: 42px;
        font-weight: 200;
    }
    .message {
        margin-top: 10px;
        color: @color-panel-face-half;
        font-size: 20px;
        font-weight: 300;
    }
    .button {
        position: relative;
        padding: 5px 13px 6px 45px;
        border: 1px solid @color-selected;
        border-radius: 6px;
        margin-top: 30px;
        color: @color-selected;
        font-size: 22px;
        line-height: auto;

        > .icon {
            position: absolute;
            left: 12px;
            top: 6px;
            font-size: 24px;
        }

        &:active {
            border-color: @color-selected-deep;
            color: @color-selected-deep;
        }
    }
    .arrow {
        margin-top: 30px;
        font-size: 18px;
    }
}
