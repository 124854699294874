.EventList {
    width: 100%;

    .EventListItem {
        .solidify;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
            border-bottom: none;
        }

        > a {
            position: relative;
            display: block;
            padding: 4px 15px 7px 15px;
            height: 30px;
            box-sizing: border-box;
            border: 1px solid transparent;
            border-radius: 6px;
            left: -15px;
            font-size: 14px;

            &:not(:hover) {
                color: @color-soft-link;
            }

            > .event-key {
                width: 95%;
                overflow: hidden;
                position: relative;
                white-space: nowrap;
                line-height: 19px;

                &::before{
                    position: absolute;
                    background-image: linear-gradient(to right, rgba(247, 248, 249, 0), #F7F8F9);
                    right: 0;
                    width: 15px;
                    height: 18px;
                    display: block;
                    content: "";
                }
            }
        }

        &.success > a {
            animation: list-item-success-pulse 1.5s ease-in-out backwards;
        }

        &.failed > a {
            animation: list-item-failed-pulse 1.5s ease-in-out backwards;
        }

        @keyframes list-item-success-pulse {
            from, to {
                border-color: rgba(96, 195, 4, 0);
            }
            15%, 40% {
                color: @color-alert-valid;
                border-color: @color-alert-valid;
                transform: scale(1.01)
            }
        }

        @keyframes list-item-failed-pulse {
            from, to {
                border-color: rgba(219, 14, 17, 0);
            }
            10% {
                color: @color-alert-danger;
                border-color: @color-alert-danger;
                transform: scale(1.01)
            }
            30%, 80% {
                border-color: @color-alert-danger;
            }
        }

        > .Button.preset-round {
            position: absolute;
            right: 0px;
            top: 5px;
            opacity: 0;
        }

        &.contextual-menu-opened,
        &:hover {
            > .Button.preset-round {
                opacity: 1;
                transition-delay: 0.2s;
            }
        }

        &.contextual-menu-opened {
            > a {
                color: @color-selected;
                border-color: @color-selected;
            }

            > .Button.preset-round {
                color: white;
                background: @color-selected;
            }
        }
    }
}
