.OccupancyProgressBar {
    cursor: default;
    user-select: none;
    width: 100%;

    .not-for-sale {
        background: hsl(219, 13%, 62%);
        box-shadow:
            inset 0 0 0 1px hsl(219, 13%, 48%),
            0 5px 10px hsla(219, 32%, 41%, 0.22);
        text-shadow: 0 1px 1px hsl(219, 13%, 48%);
    }

    .booked {
        background: hsl(211, 100%, 50%);
        box-shadow:
            inset 0 0 0 1px hsl(224, 79%, 49%),
            0 5px 10px hsla(211, 100%, 36%, 0.26);
        text-shadow: 0 1px 1px hsl(224, 79%, 49%);
    }

    .custom-status {
        background: hsl(333, 69%, 64%);
        box-shadow:
            inset -1px 0 0 1px hsl(336, 73%, 51%),
            0 5px 10px hsla(336, 100%, 31%, 0.27);
        text-shadow: 0 1px 1px hsl(336, 65%, 51%);
    }

    .temporarily-held {
        background: hsl(35, 93%, 64%);
        box-shadow:
            inset 0 0 0 1px hsl(25, 77%, 60%),
            0 5px 10px hsla(28, 100%, 45%, 0.35);
        text-shadow: 0 1px 1px hsl(25, 77%, 60%);
    }

    .disabled-by-rules {
        background: url("/assets/stripes-green.png");
        background-color: hsl(159, 52%, 57%);
        background-size: 11px;
        box-shadow: inset 0 0 0 1px hsl(135, 45%, 48%),
            0 5px 10px hsla(135, 100%, 31%, 0.25);
        text-shadow: 0 1px 1px hsl(135, 45%, 48%);
    }

    .free {
        background: hsl(0, 0%, 96%);
        box-shadow: inset 0 0 0 1px hsl(0, 0%, 88%);
    }

    > .top-labels {
        display: flex;
        flex-direction: horizontal;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 6px;
        color: hsl(0, 0%, 50%);
    }

    > .bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 22px;
        border-radius: 4px;
        background: hsl(0, 0%, 96%);
        box-shadow: inset 0 0 0 1px hsl(0, 0%, 88%);

        > .progress {
            height: inherit;
            min-width: 1px;
            transition:
                transform 0.2s @ease-out-quint,
                width 0.7s @ease-out-quint;

            &:first-child {
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
            }

            &:last-child {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }

            &.highlighted {
                transform: scaleY(1.15);
                border-radius: 2px;
                filter: contrast(1.03) brightness(1.03);
            }
        }
    }

    > .legend {
        .horizontal-flex;
        flex-wrap: wrap;
        margin-top: 2px;
        font-size: 14px;

        > .item {
            .horizontal-flex;
            margin-top: 4px;
            border-radius: 10px;
            padding: 2px 12px 2px 6px;
            transition: 0.2s @ease-out-quint;
            transition-property: color, background-color, box-shadow;

            &:not(:last-child) {
                margin-right: 10px;
            }

            > .color {
                width: 8px;
                height: 8px;
                border-radius: 100%;
                margin-right: 6px;
            }

            > .label {
                position: relative;
                top: -1px;
                font-weight: 500;
            }

            &.highlighted {
                color: white;

                > .color {
                    background: white;
                    box-shadow: none;
                }
            }
        }
    }
}
