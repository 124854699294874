.timeline {
	display: inline-block;
	width: 100%;

	.timelineContainer {
		padding-left: 25px;
		margin-top: 1em;
		max-height: 500px;
		overflow-y: scroll;
		overflow-x: visible;
		position: relative;

		.timeline-line {
			border-left: #888787 dashed 1px;
			position: absolute;
			width: 1px;
			height: calc(100% - 48px);
			left: 18px;
			top: 4px;
		}
	}

	.title {
		margin: 0;

		.icon-button {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			outline: none;
			border: 0;
			background: transparent;
			font-size: large;
			cursor: pointer;
		}

		.oldest-first {
			&::before {
				content: "\e93d";
				display: inline-block;
				transform: rotate(180deg);
				font-family: 'seatsdesigner';
				line-height: 1;
				font-size: 0.8em;
				margin-right: 0.5em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}

		.newest-first {
			&::before {
				content: "\e93d";
				display: inline-block;
				font-family: 'seatsdesigner';
				line-height: 1;
				font-size: 0.8em;
				margin-right: 0.5em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
	}

	.timeline-event {
		margin-bottom: 1em;
		padding: 3px;
		position: relative;
		top: -20px;

		.timeline-event-point {
			width: 11px;
			height: 11px;
			border-radius: 100%;
			background: #888787;
			position: relative;
			left: -17px;
			top: 18px;
			border: 2px solid #fff;

			& > .timeline-event-point-inner {
				width: 5px;
				height: 5px;
				border-radius: 100%;
				background: #444444;
				position: relative;
				left: 3px;
				top: 3px;
			}
		}

		.timeline-event-container {
			display: flex;
			justify-content: space-between;
		}

		.timeline-event-title {
			font-size: medium;
			& > .icon {
				font-size: small;
				margin-right: 0.25em;
				&.error {
					color: @color-alert-error;
				}
			}
		}

		.timeline-event-user {
			font-size: smaller;
			&::before {
				content: "\e98a";
				display: inline-block;
				font-family: 'seatsdesigner';
				line-height: 1;
				font-size: 0.8em;
				margin-right: 0.5em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
			
			& > button {
				border: none;
				font-size: x-small;
				margin-left: 8px;
				border-radius: 100%;
				width: 16px;
				height: 16px;
				padding: 0;
				border: 1px solid #ebebeb;
				cursor: pointer;
			}
		}

		.timeline-event-details {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.33s ease-out;
			&.visible {
				max-height: 200px;
			}
		}

		.timeline-event-datetime {
			font-size: 15px;
			color: #656565;
			& > span {
				font-size: smaller;
			}

			& > .time {
				margin-left: 5px;
			}
		}

		.timeline-event-data {
			margin-top: 10px;
		}

		.timeline-data-value:before {
			content: ': '
		}
	}
}