.Settings {
    .horizontal-flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 80vh;
    min-height: calc(100vh - @header-short-height);

    code {
        font-family: @code-font;
        font-size: 0.9em;
        background-color: @color-panel-surface;
    }

    .sidebar {
        position: sticky;
        top: 100px;
        display: flex;
        flex-direction: column;
        width: 150px;

        > a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-decoration: none;
            padding: 10px;
            text-align: right;
            font-size: 14px;
            font-weight: bold;
            border-radius: 10px 0 0 10px;
            transform-origin: center right;
            transition:
                background 0.5s @ease-out-quint,
                transform 0.5s @ease-out-quint;

            &.active {
                cursor: default;
                color: black;
                background-color: white;
            }

            &:not(.active):hover {
                transform: scale(1.05);
            }
        }
    }

    .simple-page {
        @font-size: 14px;
        width: 100%;
        max-width: 800px;
        background-color: white;
        min-height: 80vh;
        min-height: calc(100vh - @header-short-height);
        font-size: @font-size;

        .loading-placeholder {
            padding: 15px 25px;

            & + .loading-placeholder {
                border-top: 1px solid #e6e6e6;
            }

            > .fake-item {
                height: 2em;
                width: 50%;
                border-radius: 0.25em;
                margin-top: 1em;
            }
        }

        > .page-title {
            padding: 30px;
            font-size: 32px;
            font-weight: 600;
        }

        > .setting {
            padding: 25px 30px;

            &::after {
                content: "";
                display: block;
                clear: both;
            }

            &:last-child {
                margin-bottom: 30px;
            }

            > .notice {
                position: sticky;
                position: -webkit-sticky;
                top: 20px;
                float: right;
                max-width: 280px;
                padding: 15px 20px;
                background-color: @color-notice-background;
                border: 1px solid @color-notice-border;
                border-radius: 6px;
                box-sizing: border-box;

                > .title {
                    font-size: 16px;
                    font-weight: bold;
                }

                > .description {
                    line-height: 1.5em;

                    &:not(:first-child) {
                        margin-top: 0.7em;
                    }

                    > p {
                        margin: 0;
                        padding: 0;

                        & + p {
                            margin-top: 8px;
                        }
                    }

                    span {
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        font-family: @code-font;
                    }
                }
            }

            > .title {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            > .sub-title {
                margin-top: 30px;
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 500;
            }

            > .key {
                display: inline-block;
                padding: 8px 20px;
                border-radius: 6px;
                background-color: @color-notice-background;
                border: 1px solid @color-notice-border;
                font-family: @code-font;
                font-size: 15px;

                &.editable {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 10px 8px 20px;

                    .btn-container {
                        .vertical-flex;
                        border-left: 1px solid hsl(0, 0%, 84%);
                        margin-left: 15px;

                        button.button-link {
                            position: relative;
                            top: 1px;
                            color: gray;
                            padding-left: 13px;
                            padding-right: 3px;
                            font-size: 18px;

                            &:hover {
                                color: hsl(0, 0%, 40%);
                            }

                            &:active {
                                color: hsl(0, 0%, 20%);
                            }
                        }
                    }
                }
            }

            > .text {
                max-width: 360px;
                margin-top: 20px;
                line-height: 1.5em;
            }

            > .NumericInput,
            > .OptionSwitcher {
                margin-top: 15px;
            }

            > .subtitle {
                font-size: 18px;
                font-weight: 500;
                margin-top: 40px;
            }

            .notice + .subtitle,
            .subtitle:first-child {
                margin-top: 0;
            }

            .subtitle + * {
                margin-top: 10px;
            }

            .error {
                color: #EA1E1D;
            }

            .form-field {
                margin-top: 20px;

                label,
                input {
                    display: block;
                    width: 100%;
                    max-width: 260px;
                }

                input {
                    margin-top: 6px;
                    font-size: @font-size;
                    padding: 6px 12px;
                    border-radius: 5px;
                    border: 1px solid #e6e6e6;
                }

                &.single-line {
                    .horizontal-flex;
                    align-items: flex-end;

                    input,
                    label {
                        display: block;
                    }

                    input[type=radio] {
                        width: 1em;
                        height: 1em;
                        margin: 0;
                        padding: 0;
                    }

                    label {
                        width: auto;
                    }

                    input + label {
                        margin-left: 8px;
                    }
                }
            }

            .warning-notice {
                cursor: default;
                user-select: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 300px;
                margin-top: 15px;

                > .icon {
                    font-size: 22px;
                    color: hsl(0, 0%, 60%);
                    margin-inline-end: 15px;
                }

                > .label {
                    color: hsl(0, 0%, 40%);
                }
            }

            & + .setting {
                border-top: 1px solid #e6e6e6;
            }

            .errors, .success {
                margin-top: 10px;
            }

            button:disabled {
                background-color: lightgray;
                pointer-events: none;
            }
        }

        > .setting-page-title {
            padding: 0 30px 0;
            font-size: 30px;
            font-weight: 600;
        }

        > .setting-page-description {
            padding: 0 30px 30px;
            font-size: 15px;
            
            > p {
                max-width: 500px;
                margin: 10px 0 0;
                padding: 0;
                line-height: 1.5em;
            }
        }
    }
}
