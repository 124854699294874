.ChartDetail {
    .vertical-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    background: white;
    transform: none !important; // Overriding so chart fullscreen can be truly positioned at the top left of the screen.

    .page {
        display: flex;
        flex-grow: 1;
        width: 100%;
        overflow: hidden;

        > .NavigationList {
            width: 40vw;
            max-width: 370px;
        }

        .no-match {
            color: #636f74;
            font-weight: bold;
            text-shadow: 0 1px 0 white;
        }

        > .contents {
            width: 100%;
            min-width: 600px;
            flex-shrink: 1;
            overflow-y: auto;
            overflow-x: hidden;

            > .ChartDetailSubpage {
                > .Thumbnail > .loading-placeholder {
                    > .fake-item {
                        width: 250px;
                        height: 250px;
                        margin-right: 25px;
                    }
                }
                > .description > .loading-placeholder {
                    > .fake-item:nth-child(1) {
                        width: 50%;
                        height: 2em;
                    }
                    > .fake-item:nth-child(2) {
                        width: 40%;
                    }
                }
            }
        }
    }
}

.ChartDetailSubpage {
    .horizontal-flex;
    align-items: flex-start;
    padding: 30px;

    .Thumbnail {
        width: 30vw;
        max-width: 240px;
        max-height: 240px;
        padding: 10px;
        margin: 0;
        flex-shrink: 1;
    }

    > .description {
        width: 100%;
        flex-shrink: 1;
        padding: 10px 0 10px 40px;
        box-sizing: border-box;

        .title {
            margin-bottom: 5px;
            font-size: 32px;
            font-weight: 500;
            word-break: break-all;
        }

        .definition {

            > .label {
                display: block;
                width: 100%;
                margin-top: 20px;
                font-size: 15px;
                font-weight: 500;
                word-break: break-all;
            }

            > .value {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                max-width: 700px;
                margin-top: 3px;
                box-sizing: border-box;
                font-size: 14px;
                word-break: break-all;

                > .content {
                    margin-top: 5px;

                    &.fixed-width {
                        padding: 2px 6px;
                        border-radius: 4px;
                        background-color: @color-notice-background;
                        border: 1px solid @color-notice-border;
                        font-size: 12px;
                    }
                }

                .input-div {
                    position: relative;

                    &::before {
                        display: block;
                        content: "#";
                        position: absolute;
                        left: 3px;
                        top: 2.5px;
                        z-index: 1;
                        color: @color-panel-face-half;
                    }
                }

                input {
                    position: relative;
                    outline: none;
                    width: 100px;
                    padding: 1px 3px 2px 14px;
                    height: 1.4em;
                    border: 0;
                    border-radius: 5px;
                    margin: 0 10px 4px 0;
                    font-size: inherit;
                    box-shadow: 0 0 0 @focus-ring-width @color-focus-outline;
                    animation: grow-from-width-0 .4s @ease-out-cubic forwards;
                    transition: box-shadow .3s @ease-out-cubic;
                    background: transparent;

                    &.invalid-input {
                        box-shadow: 0 0 0 @focus-ring-width @color-alert-danger;
                    }

                    &.loading {
                        pointer-events: none;
                        animation: @gui-standby-loading-animation;
                    }

                    @keyframes grow-from-width-0 {
                        from {
                            width: 0px;
                        }
                    }
                }

                .floating-error {
                    position: fixed;
                    margin-top: 3px;
                    pointer-events: none;
                    background-color: @color-alert-danger;
                    border: 0;
                    padding: 2px 12px 3px;
                    border-radius: 1em;
                    color: white;
                    font-size: 12px;
                    transform-origin: left top;
                    animation: slight-zoom-in 0.2s @ease-long-rubber-band-in;
                }

                .button {
                    display: inline-block;
                    color: @color-selected;
                    font-size: 14px;
                    padding-right: 100px;

                    > .icon {
                        cursor: pointer;

                        &:hover {
                            .link-gradient-hover;
                        }
                        &:hover {
                            .link-gradient-active;
                        }
                    }
                }
            }
        }
    }

    .tags-dropdown {
        flex-wrap: wrap;
        .horizontal-flex;
        padding: 6px 18px 12px;
        background: #272727;
        border: 1px solid #4D4D4D;
        border-radius: 6px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, .15);
        transition: all 0.5s @ease-out-cubic;

        > .tag {
            cursor: pointer;
            padding: 2px 4px;
            color: hsl(0, 0%, 80%);
            margin-top: 6px;
            border: 1px solid transparent;
            border-radius: 4px;

            &:not(:last-child) {
                margin-right: 12px;
            }

            &::before {
                content: "#";
                display: inline;
                color: @color-selected-soft;
                margin-right: 2px;
            }

            &:hover {
                border-color: hsl(0, 0%, 25%);
            }

            &:active {
                background: black;
                border-color: @color-selected;
                color: @color-selected;
            }
        }
    }

}
