.SearchInput {
    position: relative;
    width: 300px;
    font-size: 13px;

    > .search-bar {
        .solidify;
        .horizontal-flex;
        position: relative;
        width: inherit;
        height: 25px;
        border-radius: 6px;
        padding: 0 25px 0 28px;
        box-sizing: border-box;

        > input {
            width: inherit;
            height: inherit;
            box-sizing: border-box;
            background: transparent;
            border: 0;
            padding: 2px 0;
            outline: none;
            color: inherit;
            font-size: inherit;
            flex-grow: 1;
        }

        > .icon {
            position: absolute;
            left: 7px;
            top: 5px;
            font-size: 14px;
        }

        > .clear-button {
            cursor: pointer;
            position: absolute;
            right: 1px;
            top: 1px;
            box-sizing: border-box;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            background: transparent;
            border: none;
            padding: 4px;
            transition: color 0.4s @ease-out-cubic;
        }

        > .tag {
            position: relative;
            border-radius: 4px;
            background: hsla(0, 0%, 100%, 0.1);
            border: 1px solid hsla(0, 0%, 100%, 0.12);
            margin-right: 5px;
            white-space: nowrap;
            padding: 1px 6px;

            &::before {
                content: "#";
                position: relative;
                color: @color-panel-face-half;
                margin-right: 5px;
                transition: all .3s @ease-out-cubic;
            }

            > .value {
                display: inline-block;
                transition: all .3s @ease-out-cubic;
            }

            > .icon {
                position: absolute;
                left: 5px;
                top: 3.5px;
                transition: all .3s @ease-out-cubic;
                opacity: 0;
                font-size: 9px;
                transform: scale(0.6);
            }

            &:hover {
                cursor: pointer;
                transition: all .3s @ease-out-cubic;
                border-color: hsla(0, 0%, 100%, 0.3);

                > .value {
                    color: #999;
                }

                > .icon {
                    opacity: 1;
                    transform: scale(1);
                }

                &::before {
                    opacity: 0;
                    transform: translateX(-6px);
                }
            }

            &:active {
                cursor: pointer;
                background-color: hsla(0, 0%, 0%, 0.6);
            }
        }

        &.with-value {
            > .icon {
                color: @color-selected !important;
            }
        }

        &.selected-tag-for-removal {
            > .tag {
                border-color: @color-selected;
                > .value {
                    color: @color-selected;
                }

                &::before {
                    color: @color-selected-soft
                }
            }
        }
    }

    &.with-dropdown {
        display: flex;
        flex-direction: row;
        width: auto;
        justify-content: center;
        align-items: center;

        > .search-bar {
            width: 300px;
        }
    }

    > .DropDown {
        height: 25px;
        width: 130px;
        margin-left: 5px;

        > .container {
            height: inherit;
        }

        .value {
            font-size: 13px;
            height: inherit;
            font-weight: normal;

            &::after {
                right: 6px;
                font-size: 12px;
            }
        }

        select {
            height: inherit;
        }
    }

    > .tags-dropdown {
        pointer-events: none;
        .horizontal-flex;
        flex-wrap: wrap;
        position: absolute;
        left: 0;
        right: 0;
        top: 28px;
        padding: 6px 18px 12px;
        background: #272727;
        border: 1px solid #4D4D4D;
        border-radius: 6px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, .15);
        transform: scale(0.98);
        opacity: 0;
        transition: all 0.5s @ease-out-cubic;

        > .tag {
            cursor: pointer;
            padding: 2px 4px;
            color: hsl(0, 0%, 80%);
            margin-top: 6px;
            border: 1px solid transparent;
            border-radius: 4px;

            &:not(:last-child) {
                margin-right: 12px;
            }

            &::before {
                content: "#";
                display: inline;
                color: @color-panel-face-half;
                margin-right: 2px;
            }

            &:hover {
                border-color: hsl(0, 0%, 25%);
            }

            &:active {
                border-color: hsl(0, 0%, 35%);
                background: black;
            }
        }
    }

    &.opened > .tags-dropdown {
        pointer-events: all;
        transform: scale(1);
        opacity: 1;
    }

    &.opened > .tags-dropdown {
        > .tag.active {
            border-color: @color-selected;
            color: @color-selected;

            &::before {
                color: @color-selected-soft
            }
        }
    }
}

.SearchInput > .search-bar {
    border: 1px solid hsla(0, 0%, 0%, .2);
    color: hsla(0, 0%, 0%, 0.8);

    &:not(.focus):hover {
        cursor: pointer;
        border-color: hsla(0, 0%, 0%, .22);
        background-color: hsla(0, 0%, 0%, .02);
    }
    &.focus {
        border-color: hsla(0, 0%, 0%, .3);
    }

    > input {
        &::placeholder {
            color: hsla(0, 0%, 0%, 0.4);
        }
    }

    > .icon {
        color: hsla(0, 0%, 0%, 0.3);
    }

    > .clear-button {
        color: hsla(0, 0%, 0%, 0.5);

        &:hover {
            color: hsla(0, 0%, 0%, 0.7);
        }
    }
}

.Header .SearchInput > .search-bar {
    border-radius: 4px;
    border: none;
    background: hsla(190, 3%, 38%, 0.75);
    box-shadow:
        inset 0 1px rgba(255, 255, 255, .08),
        0 0 0 1px rgba(0, 0, 0, .05);
    background-clip: padding-box;
    color: rgba(255, 255, 255, 0.9);

    &:not(.focus):not(:active):hover {
        cursor: pointer;
        border-color: hsla(0, 0%, 100%, .4);
        background-color: hsla(0, 0%, 100%, .15);
    }

    > input {
        &::placeholder {
            color: hsla(0, 0%, 100%, 0.55);
        }
    }

    > .icon {
        color: hsla(0, 0%, 100%, 0.5);
    }

    > .clear-button {
        color: hsla(0, 0%, 100%, 0.7);

        &:hover {
            color: hsla(0, 0%, 100%, 0.8);
        }
    }
}


.Header .SearchInput {
    &.opened > .search-bar,
    > .search-bar.focus,
    > .search-bar:active {
        background-color: hsla(220, 5%, 11%, 0.5);
        box-shadow:
            0 0 0 2px @color-selected,
            inset 0 1px 1px rgba(0, 0, 0, .15);
        border-color: hsla(0, 0%, 100%, .5);
        color: white;
    }

}
