@import "../colors";
@import "../common";
@import "../layouts";
@import "../animation";

.progress {
	--inactive-size: 16px;
	--active-size: 36px;
	--completed-size: 28px;
	--outer-color: hsl(0, 0%, 80%);
	--inner-color-complete: hsl(0, 0%, 80%);
	--item-gap: 4em;
	--connector-width: 2px;

	margin: 0 auto 3em 0;
	text-align: center;

	.description {
		font-size: 18px;
	}

	.steps {
		margin: 3em 0 2em 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: var(--item-gap);

		.stepContainer {
			position: relative;
		}

		.connector {
			display: block;
			width: calc(var(--item-gap) + 4px);
			height: 0;
			border-bottom: var(--connector-width) solid var(--outer-color);
			position: absolute;
			left: calc(100% - 2px);
			top: calc(50% - (var(--connector-width) * 0.5));
			z-index: 0;
		}

		.step {
			.solidify;
			display: flex;
			z-index: 1;
			align-items: center;
			justify-content: center;
			position: relative;
			background: #eee;
			border-radius: 100%;
			color: #808080;
			font-weight: bold;
			font-size: 24px;
			
			&.current {
				background: black;
				color: white;
				width: var(--active-size);
				height: var(--active-size);
			}

			&.notStarted {
				background: var(--outer-color);
				width: var(--inactive-size);
				height: var(--inactive-size);
				& > span {
					display: none;
				}
			}

			&.complete {
				background: var(--inner-color-complete);
				width: var(--completed-size);
				height: var(--completed-size);

				& > span {
					color: white;
					font-size: calc(var(--completed-size) * 0.75);
				}
			}
		}
	}

	.stepName {
		font-size: 18px;
	}
}