.Thumbnail {
    @thumbnail-size: 160px;

    position: relative;
    .vertical-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: @thumbnail-size;
    max-height: @thumbnail-size;
    margin-left: 20px;
    margin-right: 30px;
    padding: 10px;
    flex-shrink: 0;

    > img {
        max-width: inherit;
        max-height: inherit;
        transition:
            transform 1s @ease-out-cubic,
            filter 1s @ease-out-cubic,
            opacity 1s @ease-out-cubic;
    }

    &.with-options {
        > .options {
            .full-size-absolute;
            .vertical-flex;
            z-index: 1;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.6s @ease-out-cubic;

            > button,
            > a {
                cursor: pointer;
                display: block;
                border: none;
                width: 100%;
                max-width: @thumbnail-size;
                background: rgba(255, 255, 255, .3);
                backdrop-filter: brightness(1.03);
                border-radius: 8px;
                box-shadow: 0 1px 10px rgba(0, 11, 31, 0.08);
                padding: 8px 8px 9px;
                color: black;
                text-align: center;
                font-size: 14px;
                transform: scale(0.5);
                opacity: 0;
                transition: 0.5s linear;
                transition-property: transform, opacity;

                &:hover {
                    background: rgba(255, 255, 255, .4);
                }
                &:active {
                    background: rgba(241, 241, 241, 0.6);
                    box-shadow: 0 1px 10px rgba(0, 11, 31, 0.16);
                }
            }
        }

        &:hover {
            > img {
                transform: scale(0.95) translate3d(0, 0, 0);
                filter: blur(10px) opacity(0.8);
            }
            > .options {
                opacity: 1;

                > button,
                > a {
                    opacity: 1;
                    transform: scale(1);
                    transition-duration: 0.3s;
                    transition-timing-function: @ease-out-cubic;

                    &:nth-child(2) {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(3) {
                        transition-delay: 0.2s;
                    }
                }
            }
        }
    }
}
