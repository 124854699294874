.SubscriptionPlanDropDown {
    .value {
        font-size: inherit;
    }

    select {
        height: 2em;
        width: 180px;
    }
}
