.LoadingIndicator {
    @size: 5px;

    .horizontal-flex;
    justify-content: space-between;
    width: 80px;
    height: @size;

    .indicator {
        width: @size;
        height: @size;
        border-radius: 50%;
        background: #333;
        animation: loading-indicator 1s ease-in-out infinite;
        animation-fill-mode: forwards;

        &:nth-child(2) {
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            animation-delay: 0.2s;
        }
        &:nth-child(4) {
            animation-delay: 0.3s;
        }
        &:nth-child(5) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes loading-indicator {
    from, 50%, to {
        opacity: 0.3;
        transform: scale(0.8);
    }
    10% {
        opacity: 1;
        transform: scale(1);
    }
}
