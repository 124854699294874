.IconButtonToSelect {
	--size: 20px;
	--arrows-width: calc(var(--size) * 0.8);

	> .container {
		position: relative;
		top: 2px;
		height: var(--size);
		width: var(--size);
		padding: 0 10px;
		border-radius: 6px;
		color: @color-panel-face-half;

		> select {
			appearance: none;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border: 0;
			background: none;
			color: transparent;
		}

		> .icon {
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: calc(var(--size) * 0.2);
			top: 0;
			right: var(--arrows-width);
			bottom: 2px;
			font-size: var(--size);
		}

		> .arrows {
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0;
			width: var(--arrows-width);
			bottom: 0;
			font-size: calc(var(--size) * 0.5);
		}
	}
}