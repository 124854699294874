.NewModalWizard {
    pointer-events: none;
    .solidify;
    position: fixed;
    z-index: 5600;
    .zero-top-right-bottom-left;
    .vertical-flex;
    justify-content: center;

    > .backdrop {
        pointer-events: all;
        .full-size-absolute;
        background-color: rgba(190, 190, 190, 0.97);
        animation: fade-in 1s @ease-out-cubic;

        &:active {
            background-color: rgba(180, 180, 180, 0.95);
        }
    }

    > .wizard {
        pointer-events: all;
        position: relative;
        z-index: 1;
        padding: 25px 40px;
        background: hsl(0, 0%, 97%);
        border-radius: 6px;
        box-shadow: 0 10px 35px rgba(0, 0, 0, .15);
        animation: zoom-in 0.4s @ease-soft-rubber-band-in;

        button.button-link {
            color: @color-selected;
            margin-left: 5px;
            margin-right: 5px;
        }

        > .overlay-message {
            .full-size-absolute;
            z-index: 100;
            .vertical-flex;
            justify-content: center;
            border-radius: inherit;
            background: inherit;
            animation: fade-in 1s @ease-out-cubic;
            color: #222;

            > .title,
            > .message,
            > .actions {
                animation: fade-in 1s @ease-out-cubic 1s backwards;
            }

            > .title {
                font-size: 24px;
                margin-bottom: 0.75em;
            }

            > .message {
                font-size: 16px;
                padding: 0 20px;
                text-align: center;
                margin-bottom: 0;
            }

            > .actions {
                margin-bottom: 0;
            }
        }

        .title {
            max-width: 400px;
            margin: 0 auto 18px;
            font-size: 24px;
            text-align: center;
            font-weight: 500;
        }

        .subtitle {
            max-width: 400px;
            margin: 32px auto 15px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        .message {
            padding: 0 10px;
            margin: 0 auto 25px;
            box-sizing: border-box;
            max-width: 400px;
            font-size: 15px;
            text-align: center;
            line-height: 1.5em;
        }

        .facts {
            max-width: 400px;
            margin: 30px auto;

            > .fact {
                margin-top: 8px;
                padding: 15px 20px;
                border-radius: 6px;
                background: white;
                font-size: 15px;
                line-height: 1.2em;
            }
        }

        .form {
            margin-top: 10px;
            margin-bottom: 10px;

            input[disabled]{
                border: 1px solid hsl(0, 0%, 85%);
            }

            .DropDown {
                .value {
                    font-size: 18px;
                }
            }
        }

        .message + .form {
            margin-top: 0;
        }

        .draft-validation-warning {
            margin: 20px auto 0;
            width: 300px;
            text-align: left;

            > .validation-item {
                .horizontal-flex;
                align-self: stretch;
                list-style: none;
                padding: 10px 5px;
                margin: 2px 0 0 0;
                background-color: white;
                border-radius: 6px;

                &.warning {
                    color: @color-alert-warning-deep;
                }

                &.error {
                    color: @color-alert-danger;
                }

                > .icon {
                    padding: 0 10px 0 8px;
                    font-size: 19px;
                }

                > .caption {
                    line-height: 1.1em;
                }
            }
        }

        .actions {
            position: relative;
            height: 37px;
            margin-top: 12px;
            .horizontal-flex;
            justify-content: center;

            .action {
                cursor: pointer;
                display: inline-block;
                width: auto;
                border: none;
                background: transparent;
                color: hsl(0, 0%, 40%);
                font-size: 17px;
                font-weight: normal;
                padding: 0.5em 1em;

                &:active {
                    color: hsl(0, 0%, 30%);
                }

                &[disabled] {
                    cursor: not-allowed;
                    color: hsl(0, 0%, 70%) !important;

                    &:active {
                        color: hsl(0, 0%, 60%);
                    }
                }

                &.highlighted {
                    color: @color-selected;

                    &:active {
                        color: @color-selected-deep;
                    }
                }

                &.highlighted.danger {
                    color: @color-alert-danger;

                    &:active {
                        color: @color-alert-danger-deep;
                    }
                }
            }
        }
    }

    &.closed {
        opacity: 0;
        transition: opacity 0.5s @ease-out-cubic;

        > .dialog {
            transform: scale(0.95);
            transition: transform 0.5s @ease-out-cubic;
        }
    }
}

@keyframes stamp-scale-in {
    from {
        transform: scale(0.1);
        opacity: 0;
    }
    5% {
        transform: scale(0.1);
        opacity: 1;
    }
    to {
        transform: scale(1);
    }
}

@keyframes stamp-stroke-in {
    from {
        box-shadow: inset 0 0 0 17px @color-alert-valid;
    }
    50% {
        box-shadow: inset 0 0 0 17px @color-alert-valid;
    }
    to {
        box-shadow: inset 0 0 0 1.5px @color-alert-valid;
    }
}
