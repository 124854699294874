.ConfigurationKeys {
    width: 100%;
    background-color: white;
    margin: 0;
    padding: 10px 0;

    .accordion-container {
        width: 60%;
        display: block;
        margin: 10px auto;
        padding: 0;

        .companies-list {
            .horizontal-flex;
            flex-wrap: wrap;
            font-size: 14px;

            .company {
                width: 40%;
                flex-grow: 1;
                padding: 8px 25px;

                &:nth-child(4n+1),
                &:nth-child(4n+2) {
                    background: hsl(0, 0%, 97%);
                }
            }
        }

        .number-of-companies {
            position: absolute;
            right: 40px;
            top: 7px;
            color: hsl(220, 2%, 60%);
            font-size: 12px;
            width: 150px;
            text-align: left;

            .count {
                display: inline-block;
                width: 45px;
                margin-right: 4px;
                color: hsl(220, 2%, 20%);
                font-size: 16px;
                text-align: right;
            }
        }
    }
}
