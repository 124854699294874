.Companies {
    width: 100vw;
    padding-bottom: 10px;

    > .container {
        max-width: 1040px;
        min-height: 30vh;
        margin-bottom: 5px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;
        margin: 0 auto;
        background-color: white;
    }

    > .loading-placeholder {
        .horizontal-flex;
        justify-content: space-around;
        max-width: 1040px;
        margin: 10px auto 10px;

        > .fake-item {
            width: 18%;
        }
    }

    .DataTable {
        overflow-y: auto;

        tr:last-child > td:first-child {
            border-bottom-left-radius: 6px;
        }

        tr:last-child > td:last-child {
            border-bottom-right-radius: 6px;
        }
    }

    .DataTable table tr {
        &.enter {
            animation-delay: 0.5s;
        }
    }

    .company-filters {
        padding: 18px 0;
        .form-field {
            margin: 1em 0 2em 0;
            .container {
                padding: 18px;
                margin: 18px 0;
                display: flex;
                align-items: flex-end;
            }
            border-bottom: 1px solid #e0e0e0;
            display: flex;
            .inline-field {
                display: inline-block;
                margin-right: 1em;
                label {
                    display: block;
                    padding-bottom: 5px;
                }
                .select, input {
                   max-width: 80px;
                }
                overflow: hidden;
            }

            input#startYear {
                border-color: rgb(118, 118, 118);
                border-radius: unset;
            }

            select {
                padding: 8px;
                &#startMonth {
                    width: 90px;
                }
            }

            .country-dropdown {
                max-width: 180px;
            }

            .FormButton {
                height: 40px;
                padding: 10px;
            }
        }
    }
}
