.DataTable {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &.no-scroll {
        overflow: hidden;
    }

    .infinite-scroll-container {
        height: 0px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        overflow-y: scroll;
    }

    table th {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        padding-top: 20px;
        padding-bottom: 10px;

        &:first-child {
            padding-left: 30px;
        }
    }

    table th.sortable {
        position: relative;
        cursor: pointer;
        user-select: none;

        .icon {
            position: absolute;
            margin-left: 4px;
            font-size: 12px;
            line-height: 20px;
            visibility: visible;

            &.flipped {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:hover > .icon {
            .link-gradient-hover;
        }

        &:active,
        &:active > .icon {
            .link-gradient-active;
        }

        &.selected {
            color: @color-selected;
        }

        &.loading {
            animation: @gui-standby-loading-animation;
            pointer-events: none;
        }
    }

    table tr {
        position: relative;

        td:first-child {
            padding-left: 30px;
        }

        td:last-child {
            padding-right: 20px;
        }

        &:hover {
            td {
                background: @color-list-background-hover;

                &.has-button > .Button {
                    pointer-events: all;
                    opacity: 1;
                    transform: scale(1);
                    transition-delay: 0.2s;
                }
            }
        }

        &.contextual-menu-opened {
            > td {
                background: @color-selected;
                color: white;

                &.action-cell > span {
                    color: white;
                }
            }

            .Button.preset-round {
                color: @color-selected;
                background: white;
                opacity: 1;
                transform: scale(1);
            }
        }

        &.deactivated {
            color: @color-panel-face-quarter;
        }

        &.enter {
            animation: table-tr-enter 2s @ease-out-cubic backwards 0.2s;
        }

        @keyframes table-tr-enter {
            from {
                opacity: 0;
                transform: translateX(100%)
            }
            to {
                opacity: 1;
                transform: translateX(0)
            }
        }
    }

    table td {
        text-align: left;
        padding: 8px 10px 9px 5px;
        border-top: 1px solid @color-list-border;
        vertical-align: middle;
        font-size: 14px;

        strong,
        &.quantity {
            font-weight: 500;
        }

        .soft {
            color: #999999;
        }

        &.fixed-width {
            font-size: 11px;
        }

        > pre {
            display: inline-block;
            font-size: 11px;
            padding: 0;
            margin: 0 12px 0 0;
        }

        .Badge {
            cursor: default;
            user-select: none;
            pointer-events: auto;
            margin: 0 0 0 20px;
        }

        .display-label {
            color: #999999;
            font-size: 12px;
            white-space: nowrap;
        }

        &.has-button {
            text-align: right;
            padding-right: 0 !important;

            > .Button {
                pointer-events: none;
                opacity: 0;
                transform: scale(0.6);
                transition: 0.5s @ease-out-cubic 0.1s;
                transition-property: opacity, transform;
            }
        }

        &.action-cell {
            > span {
                position: relative;
                cursor: pointer;
                color: @color-selected;
            }

            &:active {
                color: @color-selected-deep;
            }

            .icon {
                position: absolute;
                margin-top: -0.1em;
                padding-left: 0.5em;
                font-size: 1.5em;
                opacity: 0;
                transition: opacity 0.2s @ease-out-cubic;
            }

            &:hover {
                .icon {
                    opacity: 1;
                    transition-delay: 0.1s;
                }
            }
        }

        > .nowrap {
            display: inline-block;
            white-space: nowrap;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        > .date {
            width: 94px;
            margin-right: 0 !important;
        }

        > .Button.preset-round {
            position: relative;
            top: 0;
        }
    }

    .SearchInput {
        margin: 5px auto 25px;
    }

    .center-message {
        padding: 30px;
        color: @color-panel-face-half-calm;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
    }

    .loading-placeholder:not(tr) {
        .horizontal-flex;
        justify-content: space-around;

        > .fake-item {
            width: 18%;
        }

        & + .loading-placeholder {
            display: none;
        }
    }

    tr.loading-placeholder {
        td {
            border-top-color: transparent;

            .fake-item {
                height: 20px;
                margin: 0;
            }
        }
    }
}
