.ManageSubscription {
    width: 100vw;
    padding-bottom: 10px;

    > .container {
        max-width: 1040px;
        min-height: 30vh;
        margin-bottom: 5px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;
        margin: 0 auto;
        background-color: white;
        padding: 30px;

        > .title {
            font-size: 32px;
            font-weight: bold;
            padding-top: 10px;
            margin-bottom: 50px;
            text-align: center;

            &:first-child {
                margin-top: 10px;
            }

            > .soft {
                margin-top: 2px;
                font-size: 50%;
                color: hsl(201, 8%, 65%);
            }
        }

        > .subscription-facts {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            > .fact {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 25px 50px 30px;
                width: 250px;
                min-height: 160px;
                text-align: center;

                &:not(:first-child) {
                    margin-left: 20px;
                    border-left: 1px solid hsl(201, 14%, 92%);
                }

                > .title {
                    font-size: 18px;
                    color: hsl(201, 8%, 65%);
                    font-weight: bold;
                }

                > .value {
                    font-size: 32px;
                    font-weight: bold;
                }

                > .description {
                    margin-top: 30px;
                    font-size: 14px;
                    line-height: 1.3em;
                    color: hsl(201, 8%, 35%);
                }

                > .action {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 40px;
                    font-size: 13px;

                    > .hint {
                        margin-top: 12px;
                        font-size: 12px;
                        line-height: 1.2em;
                        color: hsl(201, 8%, 65%);
                    }
                }

                > .separator {
                    height: 40px;
                }
            }
        }

        > .loading-placeholder {
            max-width: 600px;
            margin: 0 auto;
            padding: 50px 0 20px;
            .horizontal-flex;
            justify-content: space-between;

            .fake-item {
                width: 30px;
                height: 200px;
                animation-name: fake-item-vertical-grow;
            }
        }

        > .links {
            text-align: center;
        }
    }
}

.usage-urls-dialog {
    width: 50vw;

    .message {
        margin: 0 !important;
        max-width: 100% !important;
    }

    .usage-report-modal-content {
        max-height: 70vh;
        display: block;
        box-sizing: border-box;
        overflow-x: hidden;

        ul {
            list-style-type: none;
            list-style-position: inside;
            margin: 0;
            padding: 0;
            text-align: left;
            white-space: nowrap;

            li {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        table {
            width: 100%;
        }
    }
}
