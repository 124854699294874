.Reports {
    width: 100vw;
    padding-bottom: 10px;

    > .container {
        max-width: 1040px;
        min-height: 30vh;
        margin-bottom: 5px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;
        margin: 0 auto;
        background-color: white;
        padding: 30px;

        > .title {
            font-size: 26px;
            font-weight: 500;
            margin-top: 60px;
            margin-bottom: 20px;

            &:first-child {
                margin-top: 10px;
            }

            > .soft {
                margin-top: 2px;
                font-size: 50%;
                color: hsl(201, 8%, 65%);
            }
        }

        > .time-controls {
            margin: 20px 0 50px;

            > .DropDown {
                position: relative;
                width: 360px;
            }

            > .OptionSwitcher {
                float: right;
                margin: 0;
            }
        }

        > .sub-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        > .loading-placeholder {
            max-width: 600px;
            margin: 0 auto;
            padding: 50px 0 20px;
            .horizontal-flex;
            justify-content: space-between;

            .fake-item {
                width: 30px;
                height: 200px;
                animation-name: fake-item-vertical-grow;
            }
        }

        > .usage-details {
            display: flex;
            flex-direction: row;
            min-height: 300px;
            height: 70vh;
            margin-top: 10px;
            border-radius: 6px;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .08);

            .NavigationList {
                width: 40vw;
                max-width: 340px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .04);
            }

            .usage-by-events {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 25px 0 25px 0;

                > .title {
                    margin-left: 30px;
                    font-size: 22px;
                    font-weight: 500;
                }
                > .chart-key {
                    margin-top: 15px;
                    margin-left: 30px;
                    font-size: 16px;
                    font-weight: 500;

                    .fixed-width {
                        padding: 2px 6px;
                        border-radius: 4px;
                        background-color: @color-notice-background;
                        border: 1px solid @color-notice-border;
                        font-size: 13px;
                        font-weight: normal;
                    }
                }
                > .total {
                    margin-top: 10px;
                    margin-bottom: 30px;
                    margin-left: 30px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        > .summary {
            margin-bottom: 50px;

            > .title {
                text-align: center;
                font-size: 32px;
                font-weight: 500;

                > .icon {
                    margin: 0 10px;
                    font-size: 0.7em;
                    cursor: pointer;

                    &.hidden {
                        visibility: hidden;
                    }
                }

            }

            > .subTitle {
                margin-top: 2px;
                text-align: center;
                font-size: 0.8em;
            }

            > .summaryItemContainer {
                margin-top: 25px;
                display: flex;
                justify-content: center;

                > .summaryItem {
                    margin: 0 30px;
                    text-align: center;
                    width: 220px;

                    > .title {
                        font-size: 1.4em;
                        margin-bottom: 5px;
                    }

                    > .number {
                        font-size: 1.7em;
                        font-weight: bold;
                    }
                }
            }

            > .noUsageReport {
                text-align: center;
                font-size: 1.5em;
                margin-top: 10px;
            }
        }

        > .details {

            overflow-y: auto;

            .usage {

                > .mainUsage {
                    font-size: 1.2em;
                    font-weight: bold;
                    margin-bottom: 7px;
                }

                .button-link {
                    vertical-align: middle;
                    color: @color-selected;
                }
                .button-link:active{
                    color: @color-selected-deep;
                }
            }

            .whitelabeled {
                text-align: center;
            }
        }

        > .billing {

            .billing-item {
                margin-bottom: 5px;
            }
        }
    }
}

.usage-urls-dialog {
    width: 50vw;

    .message {
        margin: 0 !important;
        max-width: 100% !important;
    }

    .usage-report-modal-content {
        max-height: 70vh;
        display: block;
        box-sizing: border-box;
        overflow-x: hidden;

        ul {
            list-style-type: none;
            list-style-position: inside;
            margin: 0;
            padding: 0;
            text-align: left;
            white-space: nowrap;

            li {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        table {
            width: 100%;
        }
    }
}
