.FormButton {
    cursor: pointer;
    padding: 0.625em 1.125em;
    margin: 0;
    border-radius: 5px;
    background: #444;
    border: none;
    color: white;
    font-size: inherit;
    line-height: 1em;

    &[disabled] {
        cursor: auto;
        background: #9d9d9d;
        color: #cbcbcb;
    }

    &:not(:first_child) {
        margin-top: 20px;
    }

    &:not([disabled]):hover {
        background: rgb(74, 80, 82);
    }

    &:not([disabled]):active {
        background: #333;
    }

    &.loading {
        pointer-events: none;
        animation: @gui-standby-loading-animation;
    }

    &.noMargin {
        margin: 0;
    }

    &.horizontalMargin {
        margin: 2px;
    }

    &.danger {
        background: @color-alert-danger;
    }

    &.no-danger {
        background: @color-alert-valid;
    }
}
