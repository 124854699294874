.InactiveWorkspacesList {

    .section.wide {
        padding-top: 0;

        .page-title {
            padding: 30px;
            margin-bottom: 0;
        }
    }
}

.create-workspace-dialog {
    width: 320px;
}
