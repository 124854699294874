.Accordion {
    margin-top: 10px;

    .title {
        .solidify();
        cursor: pointer;
        position: relative;
        width: 100%;
        padding: 8px 25px;
        background: linear-gradient(to top, hsl(0, 1%, 92%), hsl(0, 1%, 97%));
        box-shadow: inset 0 0 0 1px rgba(0, 0, 10, 0.04);
        box-sizing: border-box;
        border-radius: 4px;
        color: hsl(0, 0%, 7%);
        font-size: 15px;
        text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.4);
        transition: box-shadow @ease-out-cubic 0.2s;

        .icon {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 14px;
            color: hsl(0, 0%, 13%);

            &.flipped {
                top: 9px;
                transform: rotate(180deg)
            }
        }

        &:active {
            background: linear-gradient(to top, hsl(0, 1%, 89%), hsl(0, 1%, 94%));
        }

        &:hover {
            box-shadow: inset 0 0 0 1px rgba(0, 0, 10, .15);
        }

        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: linear-gradient(to top, hsl(0, 1%, 85%), hsl(0, 1%, 93%));

            &:active {
                background: linear-gradient(to top, hsl(0, 1%, 82%), hsl(0, 1%, 90%));
            }

            .icon {
                color: hsl(0, 0%, 7%);
            }
        }
    }

    .container {
        padding: 0;
        margin: 0;
        max-height: calc(70vh - @header-expanded-height);
        border: 1px solid hsl(200, 5%, 87%);
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        display: none;
        overflow-y: auto;

        &.expanded {
            display: block;
        }
    }
}
