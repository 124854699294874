.chartDesignerContainer {
    .vertical-flex;
    height: 100vh;

    > .designer {
        .vertical-flex;
        flex-grow: 1;
        width: 100%;

        > iframe {
            flex-grow: 1;
        }
    }
}
