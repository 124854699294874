.ContextualMenu {
    pointer-events: all;
    cursor: default;
    position: fixed;
    .zero-top-right-bottom-left;
    z-index: 5200;
    background-color: hsla(0, 0%, 0%, 0.1);
    visibility: hidden;

    > .menu {
        pointer-events: all;
        position: fixed;
        padding: 5px 0;
        margin: 5px;
        background: rgb(250, 250, 250);
        border-radius: 5px;
        box-shadow:
            0 0 0 1px rgba(0, 0, 0, .05),
            0 8px 22px rgba(0, 0, 0, .2),
            inset 0 1px 0 1px rgba(255, 255, 255, .25);
        animation: slight-zoom-in 0.1s @ease-out-cubic;
        max-height: 70vh;
        overflow-y: auto;

        > .scroll-wrap {
            .vertical-flex;
        }

        .separator {
            width: 100%;
            height: 1px;
            background: rgb(226, 226, 226);
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .validation-item {
            .horizontal-flex;
            padding: 5px 1.5em 5px 1em;
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;

            &.warning {
                color: @color-alert-warning-deep;
            }

            &.error {
                color: @color-alert-danger;
            }

            > .icon {
                margin-right: 0.5em;
                font-size: 17px;
            }
        }
    }

    &.open {
        visibility: visible;
        animation: contextual-menu-in 2s @ease-out-cubic;
    }
}

@keyframes contextual-menu-in {
    from {
        background-color: hsla(0, 0%, 0%, 0);
    }
    to {
        background-color: hsla(0, 0%, 0%, 0.1);
    }
}
