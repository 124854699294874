.MapPicker {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 300px;
	border-radius: 6px;
	background: white;
	border: 1px solid hsl(0, 0%, 85%);

	> .map {
		position: relative;
		width: inherit;
		height: 158px;
		background: url("/assets/world-map@2x.png") center top no-repeat;
		background-size: contain;

		> .MapLocation {
			--size: 18px;
			position: absolute;
			width: var(--size);
			height: var(--size);
			margin-left: calc(var(--size) / -2);
			margin-top: calc(var(--size) / -2);
			border-radius: var(--size);
			border: 1px solid;
			z-index: 1;
			transition: all 0.25s @ease-out-quint;

			&:not(.selected) {
				cursor: pointer;
				background: white;
				border-color: @color-selected;
				box-shadow:
					0 0 0 @color-selected,
					0 6px 6px hsla(0, 0%, 60%, 0.35);

				&:hover {
					box-shadow:
						0 0 0 1px @color-selected,
						0 6px 6px hsla(0, 0%, 60%, 0.35);
				}

				&:active {
					background-color: hsl(0, 0%, 94%);
				}
			}

			&.selected {
				background: @color-selected;
				border-color: white;
				box-shadow: 0 6px 6px hsla(200, 100%, 37%, 0.3);

				&::before,
				&::after {
					content: "";
					display: block;
					.full-size-absolute;
					z-index: 0;
					background: @color-selected;
					border-radius: inherit;
					opacity: 0.5;
					transform: scale(2);
					animation: map-location-pulse 4.2s @ease-out-cubic infinite backwards 0.6s;
				}

				&::after {
					animation-name: map-location-pulse-alt;
				}
			}
		}
	}

	> .label {
		padding: 18px;
		font-size: 16px;
		text-align: center;

		&.placeholder {
			color: @color-panel-face-soft;
		}
	}

	> .help {
		cursor: help;
		position: absolute;
		right: 5px;
		bottom: 5px;

		> .icon {
			color: hsl(0, 0%, 20%);
			background-color: hsl(0, 0%, 92%);
			border-radius: 1em;
			font-size: 15px;
			padding: 4px;
			transition: 0.3s @ease-out-quint;
			transition-property: color, background-color;
		}

		> .tooltip {
			pointer-events: none;
			position: absolute;
			right: 30px;
			bottom: -20px;
			width: 190px;
			padding: 9px 13px;
			background: white;
			border-radius: 10px;
			box-shadow:
				0 4px 8px -3px hsla(0, 0%, 0%, 0.15),
				0 8px 28px -2px hsla(0, 0%, 0%, 0.15);
			color: hsl(0, 0%, 30%);
			font-size: 14px;
			opacity: 0;
			transform: scale(0.5);
			transform-origin: right center;
			transition: 0.3s @ease-out-quint;
			transition-property: opacity, transform;
		}

		&:hover {
			> .tooltip {
				opacity: 1;
				transform: scale(1);
				transition-delay: 0.1s;
			}

			> .icon {
				color: hsl(0, 0%, 85%);
				background-color: hsl(0, 0%, 20%);
				transition-delay: 0.1s;
			}
		}
	}

	&.error {
		> .label {
			color: @color-alert-error;
		}
	}
}

@keyframes map-location-pulse {
	from {
		opacity: 0.4;
		transform: scale(0.8);
	}
	to {
		opacity: 0;
		transform: scale(5);
	}
}

@keyframes map-location-pulse-alt {
	from, 10% {
		opacity: 0.4;
		transform: scale(0.8);
	}
	to {
		opacity: 0;
		transform: scale(5);
	}
}
