@section-side-padding: 30px;

.sectioned-page {
	.horizontal-flex;
	align-items: flex-start;
	justify-content: center;

    > .sectioned-page-container {
        @font-size: 14px;
        width: 100%;
        max-width: 800px;
        background-color: white;
        min-height: 80vh;
        min-height: calc(100vh - @header-short-height);
        font-size: @font-size;

        &.with-expanded-header {
            min-height: calc(100vh - @header-expanded-height);
        }

        & .button-container {
            text-align: center;
            margin-bottom: 4em;
        }

        .loading-placeholder {
            padding: 15px 25px;

            & + .loading-placeholder {
                border-top: 1px solid #e6e6e6;
            }

            > .fake-item {
                height: 2em;
                width: 50%;
                border-radius: 0.25em;
                margin-top: 1em;
            }
        }

        > .section {
            padding: 25px @section-side-padding;

            &.wide {
                padding-left: 0;
                padding-right: 0;
            }

			&:not(:first-child) {
				border-top: 1px solid #e6e6e6;
			}

			&::after {
				content: "";
				display: block;
				clear: both;
			}

			> .notice {
                position: sticky;
                position: -webkit-sticky;
                top: 20px;
				float: right;
				max-width: 280px;
				padding: 15px 20px;
				background-color: @color-notice-background;
				border: 1px solid @color-notice-border;
				border-radius: 6px;
				box-sizing: border-box;

				> .title {
					font-size: 16px;
					font-weight: bold;
				}
				> .description {
					margin-top: 0.7em;
					line-height: 1.5em;

                    > p {
                        margin: 0;
                        padding: 0;

                        & + p {
                            margin-top: 8px;
                        }
                    }

					span {
						display: inline-block;
						margin: 0;
						padding: 0;
						font-family: @code-font;
                    }
				}
			}

			> .title {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 30px;
            }

            > .page-title {
                font-size: 32px;
				font-weight: 600;
				margin-bottom: 20px;
            }

            > .page-description {
                font-size: 16px;
                font-weight: 400;
                color: @color-panel-face-soft;
                line-height: 1.5em;
            }

            > ul {
                padding-left: 20px;

                li {
                    margin-top: 0.25em;
                }
            }

            > .key {
				display: inline-block;
				padding: 8px 20px;
				border-radius: 6px;
				background-color: @color-notice-background;
				border: 1px solid @color-notice-border;
				font-family: @code-font;
				font-size: 15px;
			}

			> .text {
                max-width: 360px;
                margin-top: 25px;
				line-height: 1.5em;
            }

            .display-title {
                font-size: 28px;
                font-weight: 500;
                text-align: center;
            }

            .display-text {
                width: 400px;
                margin: 10px auto;
                font-size: 16px;
                line-height: 1.5em;
                text-align: center;
            }

            > .NumericInput,
            > .OptionSwitcher {
                margin-top: 15px;
            }

			> .subtitle {
				font-size: 18px;
				font-weight: 500;
				margin-top: 40px;
            }

            .subtitle + * {
                margin-top: 10px;
            }

            span.error {
                color: #EA1E1D;
            }

            hr.divider {
                margin-top: 20px;
                margin-bottom: 20px;
                border: 1px solid hsl(0, 0%, 90%);
                border-width: 0 0 1px 0;
            }
        }
    }

    .form-field {
        margin-top: 20px;

        label,
        input[type="text"],
        input[type="password"] {
            display: block;
            width: 100%;
            max-width: 260px;
            box-sizing: border-box;
        }

        input,
        input[type="text"],
        input[type="password"] {
            margin-top: 6px;
            font-size: inherit;
            padding: 6px 12px;
            border-radius: 5px;
            border: 1px solid #e6e6e6;
        }

        select {
            margin-top: 6px;
        }

        &.single-line {
            .horizontal-flex;
            align-items: flex-end;

            input,
            label {
                display: block;
            }

            input[type=radio] {
                width: 1em;
                height: 1em;
                margin: 0;
                padding: 0;
            }

            label {
                width: auto;
            }

            input + label {
                margin-left: 8px;
            }
        }
    }

    .form-text {
        font-size: 14px;
        line-height: 1.75em;
        margin-top: 50px;

        p {
            margin-top: 0.75em;
            margin-bottom: 0;
        }
    }

    .form-text + .form-actions {
        margin-top: 40px;
    }
}
