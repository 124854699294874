.DropDown {
    position: relative;

    > .container {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .value {
        .full-size-absolute;
        z-index: 1;
        pointer-events: none;
        font-size: 22px;
        font-weight: 500;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0);
        padding: 0.25em 2em 0.25em 0.5em;

        &::after {
            content: "\e960";
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -0.5em;
            font-size: 16px;
            font-family: "seatsdesigner";
        }
    }

    select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        background: transparent;
        color: transparent; // Prevents double value on <select> on Windows.
        font-size: 13px;
        outline: none;
        transition: all 0.5s @ease-out-cubic;

        > option {
            color: black;
        }

        &:hover {
            border-color: rgba(0, 0, 0, .2);
        }

        &:active {
            color: rgba(0, 0, 0, .05);
            background-color: rgba(0, 0, 0, .05);
        }
    }
}
