@import "../colors.less";

.changePasswordForm {
	.errors {
		padding: 0.5rem 0;
		.error {
			color: @color-alert-danger;
			&:before {
				content: "* ";
			}
		}
	}

	button[type="submit"] {
		margin-top: 1rem;
		color: white;
	}
}