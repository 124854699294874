.Webhooks {
    display: flex;
    flex-direction: column;

    > .title {
        margin: 0 20px;
        padding: 30px;
        font-size: 32px;
        font-weight: 600;
        background-color: white;
    }

    > .iframe {
        height: 100%;
        margin: 0 20px;
        border: none
    }
}
