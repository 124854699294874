.AdminForm {
    width: 300px;

    .waiting-indicator {
        width: 90px;
        margin: 30px auto;
        position: relative;
    }

    .errors {
        .vertical-flex;
        align-items: flex-start;
        .solidify;
        margin: 15px 0 0;
    }

    .form-field.single-field {
        display: flex;

        input[type="checkbox"] {
            width: unset;
        }
    }

    .copy-charts-warning {
        margin: 20px auto 0;
        width: 300px;
        text-align: left;

        > .warning-item {
            .horizontal-flex;
            align-self: stretch;
            list-style: none;
            padding: 10px 5px;
            margin: 2px 0 0 0;
            background-color: white;
            border-radius: 6px;

            &.warning {
                color: @color-alert-warning-deep;
            }

            &.error {
                color: @color-alert-danger;
            }

            > .icon {
                padding: 0 10px 0 8px;
                font-size: 19px;
            }

            > .caption {
                line-height: 1.1em;
            }
        }
    }
}

.UserEventSearcher {

    label {
        font-weight: bold;
    }

    input.filter, input.formatter {
        width: 90%;
    }

    .DropDown {
        width: 320px;

        .value {
            font-weight: normal;
            font-size: 16px;
            padding-top: 0.75em;
        }
    }

    .filterError {
        color: red;
    }

    .numMatchingEvents {
        font-size: 0.7em;
    }

    .title {
        margin-bottom: 0 !important;
    }

    .columns {

        .add {
            margin-top: 0.5em;
        }

        .delete {
            margin-left: 8px;
        }
    }

    .search {
        margin-top: 3em;
    }
}

.rendering-event-dialog {
    width: 50vw;

    .message {
        margin: 0 !important;
        max-width: 100% !important;

        textarea {
            width: 100%;
            height: 300px;
        }
    }
}

.edit-subscription-event-form {

    .form-field {
        margin-top: 1em;

        input {
            width: 220px;
        }

        select {
            margin-bottom: 5px;
        }
    }

    .add {
        float: right;
    }
}

#billing-report .container {
    max-width: 1320px;

    .DataTable {
        max-height: 500px;

        table {

            th {
                position: sticky;
                top: 0;
                background: white;
            }
        }
    }
}

#deploymentPipelines {

    .DropDown {
        height: 25px;
        width: 350px;
        margin-top: 20px;
        margin-left: 13px;
    }
}

.deploymentPipeline {

    margin-top: 20px;
    margin-bottom: 20px;

    h1 {
        background-color: #4D4D4D;
        color: white;
        padding: 5px 20px;
    }

    .content {

        display: flex;
        justify-content: center;

        .environment {
            padding: 10px;
            width: 300px;

            .links {
                text-align: center;

                a {
                    margin-right: 15px;
                }
            }

            .title {
                font-size: 1.4em;
                font-weight: bold;
                display: inline-block;
                line-height: 35px;
            }

            .validationError {
                background-color: #db0e11;
            }

            .statusBadge {
                display: inline-block;
                margin-left: 5px;
                margin-right: 5px;
                font-size: 0.9em;

                .left {
                    padding: 5px;
                    background-color: #6f7477;
                    color: white;
                }

                .status {
                    padding: 5px;
                    color: white;

                    &.success {
                        background-color: #2ebb18;
                    }

                    &.failure {
                        background-color: #db0e11;
                    }

                    &.in_progress {
                        background-color: #fac532;
                        color: black;
                    }

                    &.never_ran {
                        background-color: #fac532;
                        color: black;
                    }
                }
            }

            .commitInfo {
                margin-top: 3px;
                font-family: @code-font;
                font-size: 0.9em;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .item {
                margin: 10px 0;
                padding: 10px;
                border: 1px solid #e0e0e0;
                border-radius: 6px;
                background-color: #f5f5f5;

                .itemTitle {
                    display: inline-block;
                    font-size: 1.1em;
                    font-weight: bold;
                    margin-bottom: 5px;
                    line-height: 25px;
                }

                .commitHashError {
                    display: inline-block;
                    margin-left: 10px;
                    background-color: #db0e11;
                    padding: 5px;
                    color: white;
                    border-radius: 6px;
                }

                .commitHash {
                    display: inline-block;
                    font-family: @code-font;
                    font-size: 0.9em;
                }
            }
        }

        .action {
            margin-top: 100px;
            width: 150px;
            display: flex;
            justify-content: center;

            .diff {
                margin-top: 5px;
                text-align: center;
            }
        }
    }
}

#region {
    .body {
        margin: 15px 10px;

        .region {
            font-size: 15px;
            font-weight: 500;
        }
    }
}

#regionMigrationForm {
    .DropDown .value {
        font-size: 17px;
    }
}
