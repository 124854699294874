.UpdateBillingDetails {

    .vat {
        margin-top: 10px;

        input {
            margin-top: 5px;
            height: 35px;
            width: 99%;
            border-radius: 5px;
            border-color: #999999;
            border-width: 1px;
            font-size: large;
        }
    }

    .actions {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        > a.action {
            cursor: pointer;
            padding: 0.625em 1.125em;
            margin: 0;
            border-radius: 5px;
            border: 1px solid #888;
            background: white;
            color: #333;
            font-size: inherit;
            line-height: 1em;
            box-sizing: border-box;
            box-shadow: inset 0 0 0 0 #888;
            transition:
                0.1s @ease-out-quint background,
                0.1s @ease-out-quint box-shadow;

            &:hover {
                background: hsl(0, 0%, 95%);
                box-shadow: inset 0 0 0 1px #888;
            }

            &:active {
                background: hsl(0, 0%, 90%);
                box-shadow: inset 0 0 0 1px #444;
            }
        }

        > .action + .action {
            margin-left: 16px;
        }
    }
}
