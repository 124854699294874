.AccountForm {
    --form-field-spacing: 10px;
    display: flex;
    flex-direction: column;

    &.dialog {
        width: 300px;
        border-radius: 6px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
    }

    &.with-quicklinks {
        margin-top: 75px;
    }

    input {
        margin: 0;
        outline: 0;
        padding: 7px 14px;
        line-height: 1em;
        border-radius: 6px;
        border: 1px solid hsl(0, 0%, 85%);
        color: #000;
        font: inherit;
        transition: border-color .2s @ease-out-cubic;

        &:focus {
            border-color: @color-selected;
        }
    }

    .form {
        .vertical-flex;
    }

    .form-field {
        & + .form-field {
            margin-top: var(--form-field-spacing);
        }

        > input {
            width: 300px;
            flex-grow: 1;
            box-sizing: border-box;
        }
    }

    &.columns-when-narrow {
        @media (max-height: 734px) {
            width: 626px;

            .form {
                flex-direction: row;
            }
            .form .column:not(:first-child) {
                margin-left: 20px;
            }
        }
        @media (min-height: 735px) {
            .form .column:not(:first-child) {
                margin-top: var(--form-field-spacing);
            }
        }
    }

    .errors {
        .vertical-flex;
        align-items: flex-start;
        .solidify;
        margin: 0 0 8px;

        > .error {
            padding: 2px 10px;
            border-radius: 1em;
            color: white;
            background: @color-alert-danger;
            font-size: 12px;
            font-weight: 500;
            margin: 4px auto;
        }
    }
}
