.FeatureAnalytics {
    width: 100vw;
    padding-bottom: 10px;

    >.container {
        max-width: 1040px;
        min-height: 30vh;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;
        margin: 0 auto;
        background-color: white;
        padding: 30px;
    }

    .view-links-button {
        margin-left: 4px;
    }

}
