.Badge {
	display: inline-block;
	position: relative;
	pointer-events: none;
	padding: 0.3em 0.75em;
	margin-left: 1em;
	border-radius: 0.4em;
	background-color: hsl(0, 0%, 93%);
	color: hsl(0, 0%, 40%);
    font-family: 'Helvetica', 'Roboto', 'Arial', sans-serif;
	font-size: 0.8em;
	font-weight: bold;
	text-transform: uppercase;
    line-height: 1.2em;
	transition: all 0.5s @ease-out-cubic;

	&.current {
		background-color: hsl(210, 1%, 34%);
		color: hsl(210, 8%, 95%);
	}

	&.main {
		background-color: hsl(0, 0%, 92%);
		color: hsl(0, 0%, 40%);
	}

	&.production {
		background-color: hsl(334, 65%, 91%);
		color: hsl(335, 79%, 41%);
	}

	&.test {
		background-color: hsl(140, 50%, 88%);
		color: hsl(143, 73%, 29%);
	}

	&.deleted {
		background-color: hsl(334, 65%, 96%);
		color: hsl(335, 61%, 53%);
	}

	&.dark {
		&.production {
			background-color: hsl(335, 74%, 21%);
			color: hsl(335, 100%, 54%);
		}

		&.test {
			background-color: hsl(141, 78%, 18%);
			color: hsl(77, 75%, 56%);
		}
	}

	& + .Badge {
		margin-left: 0.4em;
	}

    &.with-icon {
        height: auto;
        padding: 2px 6px 2px;
        font-size: 10px;
        vertical-align: middle;

        > .icon {
            position: relative;
            top: 1px;
            font-size: 12px;
            margin-inline-end: 3px;
        }

        > .label {
            position: relative;
            top: -1px;
        }
    }
}

.action-cell {
	&:hover .icon + .Badge {
		margin-left: 42px;
	}
}
