.NavigationList {
    @outer-padding: 10px;
    @inner-padding: 15px;

    background-color: hsl(0, 0%, 94%);
    border-right: 1px solid hsl(0, 0%, 92%);
    padding: @outer-padding;
    box-sizing: border-box;
    overflow-y: auto;

    .item {
        .solidify;
        display: block;
        width: 100%;
        box-sizing: border-box;
        flex-shrink: 0;
    }

    .title {
        left: 0;
        top: 0;
        .horizontal-flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px @inner-padding 10px;

        > .caption {
            color: black;
            font-size: 15px;
            font-weight: 500;

            > .sub-caption {
                margin-top: 5px;
                font-size: 12px;
                font-weight: normal;
            }
        }

        > .button {
            cursor: pointer;
            color: @color-selected;
            font-size: 13px;
            font-weight: 500;

            > .icon {
                position: relative;
                top: 1px;
                font-size: 13px;
                margin-right: 5px;
            }

            &:hover,
            &:hover > .icon {
                .link-gradient-hover;
            }

            &:active,
            &:active > .icon {
                .link-gradient-active;
            }
        }

        &:not(:first-child) {
            margin-top: 20px;
            border-top: 1px solid hsl(0, 0%, 87%);
        }

        & + .title {
            margin-top: 10px;
        }
    }

    .link,
    .option,
    .nav-button {
        cursor: pointer;
        min-height: 29px;
        padding: 6px @inner-padding 7px;
        color: hsl(0, 0%, 20%);
        font-size: 13px;

        &.selected {
            color: white;
            background-color: @color-selected;
        }

        .fixed-width {
            pointer-events: none;
        }

        > .main-caption {
            &.fixed-width {
                font-size: 11px;
            }
        }

        > .sub-caption {
            margin-top: 1px;
            font-size: 10px;
            font-weight: normal;
            opacity: 0.7;

            &.fixed-width {
                font-size: 10px;
            }
        }
    }

    .main-caption {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .link {
        --sub-padding: 0px;
        --icon-padding: 0px;
        position: relative;
        border-radius: 6px;
        padding-left: calc(var(--sub-padding) + var(--icon-padding) + @inner-padding);
        word-break: break-all;

        &.sub {
            --sub-padding: @inner-padding;
        }

        &.with-icon {
            --icon-padding: @inner-padding + 14px;
        }

        > .icon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: calc(var(--sub-padding) + @inner-padding);
            top: -1px;
            bottom: 0;
            font-size: 18px;
            width: 25px;

            &.icon-event-done {
                font-size: 14px;
            }
        }

        &.highlighted {
            animation: list-item-success-pulse 1.5s ease-in-out backwards;
        }

        &.indicate-done {
            color: hsl(0, 0%, 50%);
            text-decoration: line-through;

            &.selected {
                color: hsl(201, 48%, 93%);
            }
        }
    }

    .nav-button  {
        border: none;
        background-color: transparent;
        text-align: left;
    }

    .loading {
        pointer-events: none;
        animation: @gui-standby-loading-animation;
    }
}
