.preview-container {
    background-color: white;

    .options {
        min-width: 200px;
        position: fixed;
        border: 1px solid black;
        right: 20px;
        top: 20px;
        padding: 20px;
        background-color: white;

        .label {
            padding-left: 0.5em;
        }
    }

    .seating-chart {
        min-width: 450px;
        flex-grow: 1;
        height: 100%;
    }
}
