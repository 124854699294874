.NewFeaturesList {
    .full-size-absolute;
    background: linear-gradient(175deg,
        hsl(204, 47%, 95%) 0%,
        hsl(190, 9%, 84%) 30%,
        hsl(337, 25%, 87%) 70%,
        hsl(340, 27%, 78%) 100%
    );
    animation: fade-in 1s @ease-out-quint;
    color: hsl(360, 3%, 15%);

    > .go-back-button {
        pointer-events: auto;
        cursor: pointer;
        .horizontal-flex;
        position: absolute;
        left: 0;
        top: 0;
        padding: 35px 40px;
        z-index: 2;
        font-size: 24px;
        font-weight: 600;
        animation: misty-title-enter 2s @ease-out-cubic;

        > .icon {
            margin-right: 10px;
            font-size: 22px;
        }

        &:hover {
            color: @color-selected;
        }

        &:active {
            color: @color-selected-deep;
        }
    }

    > .title {
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        font-size: 56px;
        font-weight: 600;
        text-align: center;
        animation: misty-title-enter 3s @ease-out-quint;
        background: -webkit-linear-gradient(hsl(249, 60%, 18%), hsl(209, 27%, 44%));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    > .features-list {
        pointer-events: auto;
        .full-size-absolute;
        z-index: 1;
        top: 120px;
        padding-top: 50px;
        padding-bottom: 15vh;
        overflow-y: auto;
        mask-image: linear-gradient(to bottom, hsla(0, 0%, 100%, 0) 20px, hsla(0, 0%, 100%, 1) 90px);
        mask-position: center top;
        mask-size: 100% 100%;

        > a.feature-link {
            pointer-events: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 20px;
            margin: 30px auto 0;
            opacity: 0.75;
            font-weight: bold;
            text-align: center;
        }
    }

    .Feature {
        position: relative;
        pointer-events: auto;
        display: block;
        .horizontal-flex;
        width: 750px;
        margin: 0 auto;
        flex-shrink: 0;
        background: white;
        border-radius: 12px;
        box-shadow: 0 8px 25px hsla(0, 0%, 0%, 0.12);
        animation: feature-enter 1.2s @ease-out-quint backwards 1s;
        margin-top: 50px;

        &:nth-child(1) { animation-delay: 0s }
        &:nth-child(2) { animation-delay: 0.2s }
        &:nth-child(3) { animation-delay: 0.4s }
        &:nth-child(4) { animation-delay: 0.6s }
        &:nth-child(5) { animation-delay: 0.8s }

        > .thumbnail,
        > video {
            pointer-events: none;
            width: 400px;
            height: 250px;
            border-radius: 12px 0 0 12px;
        }

        > .details {
            position: relative;
            .vertical-flex;
            align-items: flex-start;
            width: 350px;
            height: 250px;
            box-sizing: border-box;
            padding: 23px 25px;

            > .title {
                color: black;
                font-size: 26px;
                font-weight: 500;
            }

            > .body {
                flex-grow: 1;
                margin-top: 0.5em;
                color: hsl(0, 0%, 6%);
                font-size: 16px;
                line-height: 1.5em;
            }

            > .link {
                font-size: 16px;
            }
        }

        > .beta {
            position: absolute;
            left: -2px;
            top: -1px;
            width: 65px;
            height: 66px;
            border-radius: 0;
        }

        &:not(:nth-child(-n+3)) {
            height: 125px;
            opacity: 0.8;

            > .thumbnail {
                width: 200px;
                height: 125px;
            }

            > .details {
                width: 550px;
                height: 125px;
                padding: 16px 25px;

                > .title {
                    width: 350px;
                    font-size: 23px;
                }

                > .body {
                    width: 350px;
                    margin-top: 0.5em;
                    font-size: 15px;
                    line-height: 1.4em;
                }

                > .link {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }

            &.with-link:hover {
                opacity: 1;
            }

            &:not(.with-link) {
                > .details > .title {
                    width: 450px;
                }
            }

            & + .Feature {
                margin-top: 25px;
            }
        }

        &.with-link:not(:hover) {
            .link {
                color: hsl(0, 0%, 60%);
            }
        }

        &.with-link:active {
            box-shadow:
                0 0 0 2px @color-selected,
                0 8px 25px hsla(0, 0%, 0%, 0.12);
        }
    }

    @keyframes misty-title-enter {
        from {
            transform-origin: center bottom;
            transform: scale(0.95);
            filter: blur(5px) opacity(0);
        }
        to {
            transform-origin: center bottom;
            transform: scale(1);
            filter: blur(0) opacity(1);
        }
    }

    @keyframes feature-enter {
        from {
            transform: translateY(-20px) scale(0.7);
            filter: blur(5px) opacity(0);
        }
        to {
            transform: translateY(0) scale(1);
            filter: blur(0) opacity(1);
        }
    }
}
