.OptionSwitcher {
	> .switcher {
		display: inline-block;
		cursor: default;
		border-radius: 100vw;
		background-color: @color-notice-background;
		border: 1px solid @color-notice-border;
		padding: 1px;
		box-shadow: inset 0 3px 6px rgba(0, 0, 0, .05);
	}

	.option {
		display: inline-block;
		padding: 10px 20px;
		border-radius: 100vw;
		color: #444;
		font-size: 14px;
		line-height: 1em;

		&.selected {
			color: white;
			background: #444;
		}

		&:not(.selected) {
			cursor: pointer;
		}
	}

	&.loading {
		.option {
			pointer-events: none;

			&.selected {
				animation: @gui-standby-loading-animation;
			}
		}
	}

	&.disabled {
		.option {
			cursor: not-allowed;
			color: hsl(0, 0%, 60%);

			&.selected {
				color: hsl(0, 0%, 90%);
				background: hsl(0, 0%, 50%);
			}
		}
	}
}
