.User {

    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        background-color: white;
        min-height: 30vh;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .section {
            width: 100%;
            max-width: 800px;
            box-sizing: border-box;
            padding: 10px;
            margin: 10px;
            border-bottom: 1px solid #e6e6e6;

            .title {
                font-size: 24px;
                font-weight: 500;
                margin: 0 10px;
            }

            .item {
                margin: 10px;
                box-sizing: border-box;

                .value {
                    background-color: @color-notice-background;
                    border: 1px solid #ebebeb;
                    font-family: 'Monaco', 'Lucida Console', monospace;
                    padding: 8px 20px;
                    border-radius: 6px;
                    color: black;
                    display: inline-block;
                }
            }
        }

        .comment {
            .title {
                margin: 0;
            }

            .comment-area {
                box-sizing: border-box;
                width: 100%;
                height: 100px;
                overflow-y: scroll;
                resize: none;
                border: none;
                border-radius: 6px;
                padding: 6px;
                margin: 10px 0;
                font-size: 14px;
                background-color: @color-notice-background;

                &.loading {
                    pointer-events: none;
                    animation: @gui-standby-loading-animation-large;
                }

                &.success {
                    animation: list-item-success-pulse 1.5s ease-in-out backwards;
                }

                &.failed {
                    animation: list-item-failed-pulse 1.5s ease-in-out backwards;
                }
            }

            .btn {
                margin: auto;
                display: block;
            }
        }

        .user-controls {
            .OptionSwitcher {
                margin: 10px;
            }
        }

    }

}
