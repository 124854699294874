.ContextualMenu.AccountMenu {
	visibility: visible;
	background: hsla(0, 0%, 5%, 25%);
	animation: background-color-fade-in 0.3s @ease-out-cubic backwards;

	> .menu {
		right: 10px;
		top: 0;
		border-radius: 11px;
		min-width: 200px;
		padding: 5px;
		transform-origin: top right;

		.profile {
			.horizontal-flex;
			width: 100%;
			color: hsl(0, 0%, 10%);
			padding: 7px 10px 15px;
			box-sizing: border-box;

			.avatar {
				position: relative;
				display: block;
				width: 30px;
				height: 30px;
				margin-right: 15px;
				border-radius: 100px;
				background-color: hsl(0, 0%, 90%);
				background-size: cover;
				box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.2);
			}

			.name {
				font-size: 15px;
			}
		}

		.impersonation {
			.vertical-flex;
			background: hsl(240, 2%, 92%);
			padding: 5px;
			border-radius: 6px;
			width: 240px;
			margin-top: 5px;
		}

		.Button.preset-contextual-menu-item {
			padding-top: 4px;
			padding-bottom: 4px;
			padding-left: 14px;
			border-radius: 6px;

			.icon {
				font-size: 22px;
				margin-right: 17px;

				&.icon-logout {
					position: relative;
					left: 2px;
				}
			}

			.caption {
				font-weight: normal;
			}
		}

		.separator {
			background-color: hsla(0, 0%, 0%, 0.1);
		}

		.company {
			width: 100%;
			padding: 8px 13px;
			box-sizing: border-box;
			color: black;
			font-size: 13px;
			font-weight: 500;
			text-align: left;
			text-transform: uppercase;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
		}
	}
}
