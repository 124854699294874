/*** Common animations ***/
@keyframes gui-standby-loading-out {
  from,
  to {
    transform: scale(1);
    filter: saturate(1) opacity(0.9);
  }
  20% {
    transform: scale(0.95);
    filter: saturate(0.4) opacity(0.6);
  }
}
@keyframes gui-standby-loading {
  from,
  to {
    transform: scale(1);
    filter: saturate(1) opacity(0.9);
  }
  20% {
    transform: scale(0.95);
    filter: saturate(0.4) opacity(0.6);
  }
  40% {
    transform: scale(1.06);
    filter: saturate(0.4) opacity(0.9);
  }
}
@keyframes gui-standby-loading-large {
  from,
  to {
    opacity: 0.9;
    transform: scale(1);
    filter: saturate(1);
  }
  30% {
    opacity: 0.6;
    transform: scale(0.99);
    filter: saturate(0.4);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes background-color-fade-in {
  from {
    background-color: hsla(0, 0%, 0%, 0);
  }
}
@keyframes zoom-in {
  from {
    transform: scale(0.4);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes slight-zoom-in {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
/*** Easings ***/
/* Status colors */
/* Interactive component colors */
/* Panel colors */
/* Misc */
body {
  background-color: hsl(204, 10%, 88%);
  overflow: hidden;
  font-family: 'Helvetica Neue', 'Helvetica', Roboto, sans-serif;
}
.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-width: 600px;
  font-family: 'Helvetica Neue', 'Helvetica', Roboto, sans-serif;
}
.App > :not(.Header) {
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
}
a {
  color: #0784fa;
  text-decoration: none;
}
a:active {
  color: #005cc5;
}
.link-gradient-hover {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.link-gradient-active {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fixed-width,
pre {
  font-family: 'Monaco', 'Lucida Console', monospace;
  user-select: all;
}
button {
  outline: none;
}
button.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1em;
}
button.button-link:hover,
button.button-link:focus {
  text-decoration: none;
}
.alert {
  color: #db0e11;
}
.solidify {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
}
.arrow {
  cursor: pointer;
}
.arrow:not(.back)::after {
  display: inline-block;
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.7em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e927";
  margin-left: 4px;
}
.arrow.back::before {
  display: inline-block;
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.7em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e927";
  transform: rotate(180deg);
  font-size: 0.8em;
  margin-right: 4px;
}
.external-link::after {
  content: "\e982";
  display: inline-block;
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.8em;
  margin-left: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loading-placeholder {
  animation: fade-in 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.fake-item {
  width: 100%;
  height: 1em;
  border-radius: 0.15em;
  margin-bottom: 1em;
  animation: fake-item-pulse 2s ease-in-out infinite backwards;
  background-color: rgba(0, 0, 0, 0.07);
  transform-origin: center bottom;
}
.fake-item:nth-child(1) {
  animation-delay: 0.25s;
}
.fake-item:nth-child(2) {
  animation-delay: 0.5s;
}
.fake-item:nth-child(3) {
  animation-delay: 0.75s;
}
.fake-item:nth-child(4) {
  animation-delay: 1s;
}
.fake-item:nth-child(5) {
  animation-delay: 1.25s;
}
.fake-item:nth-child(6) {
  animation-delay: 1.5s;
}
.fake-item:nth-child(7) {
  animation-delay: 1.75s;
}
.fake-item:nth-child(8) {
  animation-delay: 2s;
}
.fake-item:nth-child(9) {
  animation-delay: 2.25s;
}
.fake-item:nth-child(10) {
  animation-delay: 2.5s;
}
.fake-item:nth-child(11) {
  animation-delay: 2.75s;
}
.fake-item:nth-child(12) {
  animation-delay: 3s;
}
.fake-item:nth-child(13) {
  animation-delay: 3.25s;
}
.fake-item:nth-child(14) {
  animation-delay: 3.5s;
}
.fake-item:nth-child(15) {
  animation-delay: 3.75s;
}
.fake-item:nth-child(16) {
  animation-delay: 4s;
}
.fake-item:nth-child(17) {
  animation-delay: 4.25s;
}
.fake-item:nth-child(18) {
  animation-delay: 4.5s;
}
.fake-item:nth-child(19) {
  animation-delay: 4.75s;
}
.fake-item:nth-child(20) {
  animation-delay: 5s;
}
.fake-item.double {
  flex-grow: 0.5;
}
@keyframes fake-item-pulse {
  from,
  90%,
  to {
    opacity: 1;
    transform: scale(1);
  }
  45% {
    opacity: 0.8;
    transform: scale(0.92);
  }
}
@keyframes fake-item-vertical-grow {
  from,
  90%,
  to {
    opacity: 0.7;
    transform: scaleY(0.5);
  }
  45% {
    opacity: 1;
    transform: scale(1);
  }
}
.focus-ring {
  box-shadow: 0 0 0 20px hsla(207, 100%, 55%, 0);
  transition: box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.focus-ring:focus {
  outline: none;
  box-shadow: 0 0 0 3px hsla(207, 100%, 55%, 0.3);
}
.intercom-launcher-frame,
.intercom-messenger-frame {
  transition: bottom 0.6s cubic-bezier(0.19, 1, 0.22, 1) 0.2s, opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1) !important;
}
.table-tools {
  display: inline-block;
  white-space: nowrap;
}
.small-left-and-right-space {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.left-and-right-space {
  margin-left: 1em;
  margin-right: 1em;
}
.small-top-and-bottom-space {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.top-and-bottom-space {
  margin-top: 1em;
  margin-bottom: 1em;
}
.horizontal-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vertical-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zero-top-right-bottom-left {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.full-size-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*** Media Queries ***/
.empty-page-message {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.empty-page-message .title {
  color: #7f7f7f;
  font-size: 42px;
  font-weight: 200;
}
.empty-page-message .message {
  margin-top: 10px;
  color: #7f7f7f;
  font-size: 20px;
  font-weight: 300;
}
.empty-page-message .button {
  position: relative;
  padding: 5px 13px 6px 45px;
  border: 1px solid #0784fa;
  border-radius: 6px;
  margin-top: 30px;
  color: #0784fa;
  font-size: 22px;
  line-height: auto;
}
.empty-page-message .button > .icon {
  position: absolute;
  left: 12px;
  top: 6px;
  font-size: 24px;
}
.empty-page-message .button:active {
  border-color: #005cc5;
  color: #005cc5;
}
.empty-page-message .arrow {
  margin-top: 30px;
  font-size: 18px;
}
.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5100;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  color: white;
  font-size: 14px;
  transition: height 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header.with-context-actions {
  height: 85px;
}
.Header.with-context-actions .contextual-actions {
  opacity: 1;
  transition-delay: 0, 0.2s;
}
.Header.with-context-actions.with-admin-banner {
  height: 115px;
}
.Header:not(.with-context-actions) {
  height: 52px;
}
.Header:not(.with-context-actions) .contextual-actions {
  opacity: 0;
}
.Header:not(.with-context-actions).with-admin-banner {
  height: 82px;
}
.Header .floating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;
  z-index: 1000;
  width: inherit;
  box-sizing: border-box;
  background: linear-gradient(to top, hsl(210, 19%, 13%), hsl(210, 3%, 15%) 20%, hsl(180, 3%, 22%));
  border-bottom: 1px solid black;
  box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.2), inset 0 1px 0 0 hsla(55, 100%, 95%, 0.05);
}
.Header .superadmin-info {
  background-color: #eca200;
  padding: 0.4em 1em 0.6em;
  text-align: center;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.Header .navigation,
.Header .contextual-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}
.Header .navigation {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 52px;
  padding: 0 25px;
  box-sizing: border-box;
}
.Header .navigation .left-anchor {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header .navigation .left-anchor .logo-and-status {
  position: relative;
  width: 119.7px;
  height: 20.7px;
}
.Header .navigation .left-anchor .logo-and-status .logo,
.Header .navigation .left-anchor .logo-and-status .status {
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header .navigation .left-anchor .logo-and-status .status {
  pointer-events: none;
}
.Header .navigation .left-anchor .logo-and-status .status.offline {
  border-radius: 5px;
  padding: 4px 6px;
  border: 1px solid white;
  font-weight: bold;
  line-height: 1em;
  text-transform: uppercase;
  opacity: 0;
}
.Header .navigation .left-anchor .logo-and-status .logo {
  width: inherit;
  height: inherit;
  transition-delay: 0.4s;
}
.Header .navigation .left-anchor .logo-and-status .logo > img {
  width: inherit;
  height: inherit;
}
.Header .navigation .left-anchor .menu-button {
  margin-left: 20px;
}
.Header .navigation nav {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -1px;
  padding: 1px;
  border-radius: 2em;
  background: linear-gradient(to top, #1d2022, #181a1a);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.07);
}
.Header .navigation nav .dropdown-menu {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: hsl(0, 0%, 85%);
  padding: 3px 25px 3px 23px;
}
.Header .navigation nav .dropdown-menu > .icon {
  font-size: 20px;
  margin-right: 10px;
}
.Header .navigation nav .dropdown-menu > .dropdown-icon {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-left: 10px;
}
.Header .navigation nav .dropdown-menu:active,
.Header .navigation nav .dropdown-menu.active {
  color: #fff;
}
.Header .navigation nav > a {
  display: inline-block;
  min-width: 120px;
  padding: 5px;
  border-radius: 2em;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  margin-right: 6px;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header .navigation nav > a:last-child {
  margin-right: 0;
}
.Header .navigation nav > a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.Header .navigation nav > a.active {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  color: #ffffff;
  background: linear-gradient(to top, #474c4e, #505658);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.Header .navigation .center-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header .navigation .center-navigation .Badge {
  margin-right: 12px;
}
.Header .navigation .menu-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -1px;
  border-radius: 2em;
  margin-left: 6px;
}
.Header .navigation .menu-button > .dropdown-icon {
  margin-left: 6px;
  font-size: 16px;
  color: white;
  opacity: 0.4;
}
.Header .navigation .menu-button .inset {
  padding: 1px;
  border-radius: 3em;
  background: linear-gradient(to top, #1d2022, #181a1a);
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.07);
}
.Header .navigation .menu-button button {
  cursor: pointer;
  appearance: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 3em;
  background: linear-gradient(to top, #474c4e, #505658);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: hsl(0, 0%, 85%);
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}
.Header .navigation .menu-button button .icon-new-notification {
  position: relative;
  font-size: 18px;
  width: 1em;
  height: 1em;
  text-shadow: 0 1px 0 rgba(22, 0, 0, 0.1);
}
.Header .navigation .menu-button button.large {
  width: 32px;
  height: 32px;
}
.Header .navigation .menu-button button.large .icon-new-notification {
  font-size: 20px;
}
.Header .navigation .menu-button button.alert {
  background: linear-gradient(to top, hsl(17, 92%, 54%), hsl(5, 100%, 43%) 30%, hsl(29, 100%, 52%));
  animation: alert-pulse 2.5s cubic-bezier(0.19, 1, 0.22, 1) infinite;
  color: hsl(38, 100%, 98%);
}
.Header .navigation .menu-button button.alert:hover {
  background: linear-gradient(to top, hsl(32, 92%, 54%), hsl(5, 98%, 49%) 30%, hsl(39, 100%, 53%));
}
.Header .navigation .menu-button button.alert:active {
  background: linear-gradient(to top, hsl(17, 91%, 46%), hsl(5, 100%, 37%) 30%, hsl(29, 100%, 45%));
}
@keyframes alert-pulse {
  from,
  90%,
  to {
    filter: none;
    box-shadow: 0 0 100px 20px hsla(17, 98%, 47%, 0), 0 0 15px 1px hsla(32, 100%, 52%, 0), inset 0 0 2px 1px hsla(32, 100%, 52%, 0.3);
  }
  25%,
  50% {
    filter: brightness(1.3) contrast(1.3);
    box-shadow: 0 0 100px 20px hsl(17, 98%, 47%), 0 0 15px 1px hsla(32, 100%, 52%, 0.9), inset 0 0 2px 1px hsla(32, 100%, 52%, 0.8);
  }
}
.Header .navigation .menu-button .avatar {
  cursor: pointer;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: hsla(0, 0%, 100%, 0.1);
  background-size: cover;
}
.Header .navigation .menu-button:hover .dropdown-icon {
  opacity: 0.6;
}
.Header .navigation .menu-button:hover button {
  background: linear-gradient(to top, hsl(197, 5%, 31%), hsl(195, 5%, 35%));
}
.Header .navigation .menu-button:active .dropdown-icon {
  opacity: 0.4;
}
.Header .navigation .menu-button:active button {
  background: linear-gradient(to top, hsl(197, 5%, 26%), hsl(195, 5%, 29%));
}
.Header .navigation .menu-button.pressed button {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  background: linear-gradient(to top, hsl(195, 5%, 29%), hsl(197, 5%, 26%));
}
.Header .navigation .menu-button + .menu-button {
  margin-left: 20px;
}
.Header .navigation .account {
  position: relative;
  top: -1px;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header .navigation .account > .caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
  line-height: 1em;
}
.Header .navigation .account > .caption .original {
  font-size: 11px;
  color: rgba(231, 231, 231, 0.6);
}
.Header .navigation .account > .caption .current {
  font-size: 13px;
  color: #e7e7e7;
}
.Header .navigation .account .upgrade-button {
  cursor: pointer;
  border-radius: 4px;
  background: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 20%);
  padding: 3px 8px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 2px;
}
.Header .navigation .account .upgrade-button:hover {
  background: hsl(0, 0%, 100%);
}
.Header .navigation .account .upgrade-button:active {
  background: hsl(0, 0%, 85%);
  color: hsl(0, 0%, 0%);
}
.Header .contextual-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
  transition: padding 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header .contextual-actions > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header .contextual-actions > div > button,
.Header .contextual-actions > div > .button,
.Header .contextual-actions > div > .soft-button {
  position: relative;
  cursor: pointer;
  display: block;
  height: 25px;
  padding: 1px 14px 6px 36px;
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  background: hsla(190, 3%, 38%, 0.75);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  color: white;
  font-size: 0;
  line-height: auto;
  outline: none;
  text-align: left;
  overflow: hidden;
  transition: background-color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header .contextual-actions > div > button > .icon,
.Header .contextual-actions > div > .button > .icon,
.Header .contextual-actions > div > .soft-button > .icon {
  position: absolute;
  left: 12px;
  top: 3px;
  font-size: 19px;
  color: hsla(0, 0%, 100%, 0.9);
}
.Header .contextual-actions > div > button > .caption,
.Header .contextual-actions > div > .button > .caption,
.Header .contextual-actions > div > .soft-button > .caption {
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 22px;
  text-overflow: ellipsis;
}
.Header .contextual-actions > div > button:hover,
.Header .contextual-actions > div > .button:hover,
.Header .contextual-actions > div > .soft-button:hover {
  background-color: hsla(190, 3%, 38%, 0.85);
}
.Header .contextual-actions > div > button:active,
.Header .contextual-actions > div > .button:active,
.Header .contextual-actions > div > .soft-button:active {
  background-color: hsla(193, 7%, 26%, 0.5);
  transition-duration: 0;
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.4);
}
.Header .contextual-actions > div > button.highlighted,
.Header .contextual-actions > div > .button.highlighted,
.Header .contextual-actions > div > .soft-button.highlighted {
  background: linear-gradient(to bottom, #0784fa, #006aec);
}
.Header .contextual-actions > div > button.highlighted:hover,
.Header .contextual-actions > div > .button.highlighted:hover,
.Header .contextual-actions > div > .soft-button.highlighted:hover {
  background: linear-gradient(to bottom, #0a95ff, #0784fa);
}
.Header .contextual-actions > div > button.highlighted:active,
.Header .contextual-actions > div > .button.highlighted:active,
.Header .contextual-actions > div > .soft-button.highlighted:active {
  background: linear-gradient(to bottom, #006aec, #004bcc);
}
.Header .contextual-actions > div > a.button > .caption {
  position: relative;
  top: 1px;
}
.Header .contextual-actions > div > .soft-button {
  padding-top: 2px;
  padding-left: 18px;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  line-height: 1.3em;
  color: rgba(255, 255, 255, 0.8);
  background: transparent !important;
}
.Header .contextual-actions > div > .soft-button > .icon {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 14px;
}
.Header .contextual-actions > div > .soft-button > .caption {
  font-size: 14px;
}
.Header .contextual-actions > div > .soft-button:hover {
  color: rgba(255, 255, 255, 0.85);
}
.Header .contextual-actions > div > .soft-button:active {
  color: rgba(255, 255, 255, 0.7);
}
.Header .offline-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, #381818, #581d1c);
  opacity: 0;
  transition: opacity 3s cubic-bezier(0.19, 1, 0.22, 1);
}
.Header.offline .offline-background {
  opacity: 1;
}
.Header.offline .left-anchor .logo-and-status .logo {
  opacity: 0;
}
.Header.offline .left-anchor .logo-and-status .status.offline {
  opacity: 1;
  transition-delay: 0.8s;
}
@media (max-width: 1039px) {
  .Header .navigation .left-anchor .logo-and-status .logo {
    width: 99.75px;
    height: 17.25px;
  }
  .Header .navigation nav > a {
    min-width: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .Header .navigation .account .caption {
    display: none;
  }
  .Header .contextual-actions > div > .button {
    margin-right: 2px;
  }
  .Header .contextual-actions .SearchInput {
    width: 260px;
  }
}
@media (max-width: 740px) {
  .Header .navigation {
    padding-left: 8px;
    padding-right: 8px;
  }
  .Header .navigation .left-anchor {
    display: none;
  }
  .Header .navigation > nav {
    font-size: 13px;
  }
  .Header .contextual-actions {
    padding-left: 8px;
    padding-right: 8px;
  }
  .Header .contextual-actions > div > .button {
    padding: 2px 10px 2px 28px;
  }
  .Header .contextual-actions > div > .button > .icon {
    left: 9px;
    top: 6px;
    font-size: 14px;
  }
  .Header .contextual-actions > div > .button > .caption {
    font-size: 12px;
  }
  .Header .contextual-actions .SearchInput {
    width: 220px;
  }
}
.Upgrade > .sectioned-page > .sectioned-page-container > .section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.AccountForm {
  --form-field-spacing: 10px;
  display: flex;
  flex-direction: column;
}
.AccountForm.dialog {
  width: 300px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.AccountForm.with-quicklinks {
  margin-top: 75px;
}
.AccountForm input {
  margin: 0;
  outline: 0;
  padding: 7px 14px;
  line-height: 1em;
  border-radius: 6px;
  border: 1px solid hsl(0, 0%, 85%);
  color: #000;
  font: inherit;
  transition: border-color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.AccountForm input:focus {
  border-color: #0784fa;
}
.AccountForm .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AccountForm .form-field + .form-field {
  margin-top: var(--form-field-spacing);
}
.AccountForm .form-field > input {
  width: 300px;
  flex-grow: 1;
  box-sizing: border-box;
}
@media (max-height: 734px) {
  .AccountForm.columns-when-narrow {
    width: 626px;
  }
  .AccountForm.columns-when-narrow .form {
    flex-direction: row;
  }
  .AccountForm.columns-when-narrow .form .column:not(:first-child) {
    margin-left: 20px;
  }
}
@media (min-height: 735px) {
  .AccountForm.columns-when-narrow .form .column:not(:first-child) {
    margin-top: var(--form-field-spacing);
  }
}
.AccountForm .errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  margin: 0 0 8px;
}
.AccountForm .errors > .error {
  padding: 2px 10px;
  border-radius: 1em;
  color: white;
  background: #db0e11;
  font-size: 12px;
  font-weight: 500;
  margin: 4px auto;
}
.Settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
  min-height: 80vh;
  min-height: calc(100vh - 52px);
}
.Settings code {
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 0.9em;
  background-color: #f5f5f5;
}
.Settings .sidebar {
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  width: 150px;
}
.Settings .sidebar > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  padding: 10px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px 0 0 10px;
  transform-origin: center right;
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.Settings .sidebar > a.active {
  cursor: default;
  color: black;
  background-color: white;
}
.Settings .sidebar > a:not(.active):hover {
  transform: scale(1.05);
}
.Settings .simple-page {
  width: 100%;
  max-width: 800px;
  background-color: white;
  min-height: 80vh;
  min-height: calc(100vh - 52px);
  font-size: 14px;
}
.Settings .simple-page .loading-placeholder {
  padding: 15px 25px;
}
.Settings .simple-page .loading-placeholder + .loading-placeholder {
  border-top: 1px solid #e6e6e6;
}
.Settings .simple-page .loading-placeholder > .fake-item {
  height: 2em;
  width: 50%;
  border-radius: 0.25em;
  margin-top: 1em;
}
.Settings .simple-page > .page-title {
  padding: 30px;
  font-size: 32px;
  font-weight: 600;
}
.Settings .simple-page > .setting {
  padding: 25px 30px;
}
.Settings .simple-page > .setting::after {
  content: "";
  display: block;
  clear: both;
}
.Settings .simple-page > .setting:last-child {
  margin-bottom: 30px;
}
.Settings .simple-page > .setting > .notice {
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  float: right;
  max-width: 280px;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  box-sizing: border-box;
}
.Settings .simple-page > .setting > .notice > .title {
  font-size: 16px;
  font-weight: bold;
}
.Settings .simple-page > .setting > .notice > .description {
  line-height: 1.5em;
}
.Settings .simple-page > .setting > .notice > .description:not(:first-child) {
  margin-top: 0.7em;
}
.Settings .simple-page > .setting > .notice > .description > p {
  margin: 0;
  padding: 0;
}
.Settings .simple-page > .setting > .notice > .description > p + p {
  margin-top: 8px;
}
.Settings .simple-page > .setting > .notice > .description span {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Monaco', 'Lucida Console', monospace;
}
.Settings .simple-page > .setting > .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.Settings .simple-page > .setting > .sub-title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.Settings .simple-page > .setting > .key {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 6px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 15px;
}
.Settings .simple-page > .setting > .key.editable {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px 8px 20px;
}
.Settings .simple-page > .setting > .key.editable .btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid hsl(0, 0%, 84%);
  margin-left: 15px;
}
.Settings .simple-page > .setting > .key.editable .btn-container button.button-link {
  position: relative;
  top: 1px;
  color: gray;
  padding-left: 13px;
  padding-right: 3px;
  font-size: 18px;
}
.Settings .simple-page > .setting > .key.editable .btn-container button.button-link:hover {
  color: hsl(0, 0%, 40%);
}
.Settings .simple-page > .setting > .key.editable .btn-container button.button-link:active {
  color: hsl(0, 0%, 20%);
}
.Settings .simple-page > .setting > .text {
  max-width: 360px;
  margin-top: 20px;
  line-height: 1.5em;
}
.Settings .simple-page > .setting > .NumericInput,
.Settings .simple-page > .setting > .OptionSwitcher {
  margin-top: 15px;
}
.Settings .simple-page > .setting > .subtitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 40px;
}
.Settings .simple-page > .setting .notice + .subtitle,
.Settings .simple-page > .setting .subtitle:first-child {
  margin-top: 0;
}
.Settings .simple-page > .setting .subtitle + * {
  margin-top: 10px;
}
.Settings .simple-page > .setting .error {
  color: #EA1E1D;
}
.Settings .simple-page > .setting .form-field {
  margin-top: 20px;
}
.Settings .simple-page > .setting .form-field label,
.Settings .simple-page > .setting .form-field input {
  display: block;
  width: 100%;
  max-width: 260px;
}
.Settings .simple-page > .setting .form-field input {
  margin-top: 6px;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}
.Settings .simple-page > .setting .form-field.single-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-end;
}
.Settings .simple-page > .setting .form-field.single-line input,
.Settings .simple-page > .setting .form-field.single-line label {
  display: block;
}
.Settings .simple-page > .setting .form-field.single-line input[type=radio] {
  width: 1em;
  height: 1em;
  margin: 0;
  padding: 0;
}
.Settings .simple-page > .setting .form-field.single-line label {
  width: auto;
}
.Settings .simple-page > .setting .form-field.single-line input + label {
  margin-left: 8px;
}
.Settings .simple-page > .setting .warning-notice {
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  margin-top: 15px;
}
.Settings .simple-page > .setting .warning-notice > .icon {
  font-size: 22px;
  color: hsl(0, 0%, 60%);
  margin-inline-end: 15px;
}
.Settings .simple-page > .setting .warning-notice > .label {
  color: hsl(0, 0%, 40%);
}
.Settings .simple-page > .setting + .setting {
  border-top: 1px solid #e6e6e6;
}
.Settings .simple-page > .setting .errors,
.Settings .simple-page > .setting .success {
  margin-top: 10px;
}
.Settings .simple-page > .setting button:disabled {
  background-color: lightgray;
  pointer-events: none;
}
.Settings .simple-page > .setting-page-title {
  padding: 0 30px 0;
  font-size: 30px;
  font-weight: 600;
}
.Settings .simple-page > .setting-page-description {
  padding: 0 30px 30px;
  font-size: 15px;
}
.Settings .simple-page > .setting-page-description > p {
  max-width: 500px;
  margin: 10px 0 0;
  padding: 0;
  line-height: 1.5em;
}
.ChartArchive {
  height: 100vh !important;
  padding: 10px 0 20px;
}
.ChartArchive .infinite-scroll-container {
  position: relative;
  left: 15px;
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1039px) {
  .ChartArchive .infinite-scroll-container {
    left: 0;
    justify-content: center;
  }
}
.ChartArchive .archived-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 330px;
  height: 380px;
  margin-top: 20px;
  margin-right: 10px;
  background-color: white;
  border-radius: 6px;
}
.ChartArchive .archived-chart > .fake-item {
  font-size: 24px;
}
.ChartArchive .archived-chart > .fake-item.long {
  margin-top: 20px;
  width: 70%;
}
.ChartArchive .archived-chart > .fake-item.square {
  width: 160px;
  height: 160px;
}
.ChartArchive .archived-chart > .fake-item.short {
  visibility: hidden;
}
.ChartArchive .archived-chart.loading {
  animation: gui-standby-loading-large 1.1s ease-in-out infinite;
}
.ChartArchive .archived-chart.loading .title,
.ChartArchive .archived-chart.loading .Thumbnail,
.ChartArchive .archived-chart.loading .ghost-button {
  opacity: 0.6;
}
.ChartArchive .ghost-button {
  background-color: white;
  border: 1px solid #0784fa;
  color: #0784fa;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  padding: 4px 20px 5px;
  margin-bottom: 5px;
}
.ChartArchive .ghost-button:active {
  color: #005cc5;
  border-color: #005cc5;
}
.ChartArchive .title {
  padding: 10px 30px;
  min-height: 2.4em;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  word-break: break-all;
}
.ChartArchive.empty-list {
  font-size: 18px;
  margin-top: 20px;
}
.ChartArchive .empty-page-message {
  width: inherit;
}
.ChartDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background: white;
  transform: none !important;
}
.ChartDetail .page {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}
.ChartDetail .page > .NavigationList {
  width: 40vw;
  max-width: 370px;
}
.ChartDetail .page .no-match {
  color: #636f74;
  font-weight: bold;
  text-shadow: 0 1px 0 white;
}
.ChartDetail .page > .contents {
  width: 100%;
  min-width: 600px;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.ChartDetail .page > .contents > .ChartDetailSubpage > .Thumbnail > .loading-placeholder > .fake-item {
  width: 250px;
  height: 250px;
  margin-right: 25px;
}
.ChartDetail .page > .contents > .ChartDetailSubpage > .description > .loading-placeholder > .fake-item:nth-child(1) {
  width: 50%;
  height: 2em;
}
.ChartDetail .page > .contents > .ChartDetailSubpage > .description > .loading-placeholder > .fake-item:nth-child(2) {
  width: 40%;
}
.ChartDetailSubpage {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  padding: 30px;
}
.ChartDetailSubpage .Thumbnail {
  width: 30vw;
  max-width: 240px;
  max-height: 240px;
  padding: 10px;
  margin: 0;
  flex-shrink: 1;
}
.ChartDetailSubpage > .description {
  width: 100%;
  flex-shrink: 1;
  padding: 10px 0 10px 40px;
  box-sizing: border-box;
}
.ChartDetailSubpage > .description .title {
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: 500;
  word-break: break-all;
}
.ChartDetailSubpage > .description .definition > .label {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  word-break: break-all;
}
.ChartDetailSubpage > .description .definition > .value {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 700px;
  margin-top: 3px;
  box-sizing: border-box;
  font-size: 14px;
  word-break: break-all;
}
.ChartDetailSubpage > .description .definition > .value > .content {
  margin-top: 5px;
}
.ChartDetailSubpage > .description .definition > .value > .content.fixed-width {
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  font-size: 12px;
}
.ChartDetailSubpage > .description .definition > .value .input-div {
  position: relative;
}
.ChartDetailSubpage > .description .definition > .value .input-div::before {
  display: block;
  content: "#";
  position: absolute;
  left: 3px;
  top: 2.5px;
  z-index: 1;
  color: #7f7f7f;
}
.ChartDetailSubpage > .description .definition > .value input {
  position: relative;
  outline: none;
  width: 100px;
  padding: 1px 3px 2px 14px;
  height: 1.4em;
  border: 0;
  border-radius: 5px;
  margin: 0 10px 4px 0;
  font-size: inherit;
  box-shadow: 0 0 0 3px hsla(207, 100%, 55%, 0.3);
  animation: grow-from-width-0 0.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  transition: box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  background: transparent;
}
.ChartDetailSubpage > .description .definition > .value input.invalid-input {
  box-shadow: 0 0 0 3px #db0e11;
}
.ChartDetailSubpage > .description .definition > .value input.loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
@keyframes grow-from-width-0 {
  from {
    width: 0px;
  }
}
.ChartDetailSubpage > .description .definition > .value .floating-error {
  position: fixed;
  margin-top: 3px;
  pointer-events: none;
  background-color: #db0e11;
  border: 0;
  padding: 2px 12px 3px;
  border-radius: 1em;
  color: white;
  font-size: 12px;
  transform-origin: left top;
  animation: slight-zoom-in 0.2s cubic-bezier(0.44, 2.16, 0.47, 0.74);
}
.ChartDetailSubpage > .description .definition > .value .button {
  display: inline-block;
  color: #0784fa;
  font-size: 14px;
  padding-right: 100px;
}
.ChartDetailSubpage > .description .definition > .value .button > .icon {
  cursor: pointer;
}
.ChartDetailSubpage > .description .definition > .value .button > .icon:hover {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ChartDetailSubpage > .description .definition > .value .button > .icon:hover {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ChartDetailSubpage .tags-dropdown {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 18px 12px;
  background: #272727;
  border: 1px solid #4D4D4D;
  border-radius: 6px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.ChartDetailSubpage .tags-dropdown > .tag {
  cursor: pointer;
  padding: 2px 4px;
  color: hsl(0, 0%, 80%);
  margin-top: 6px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.ChartDetailSubpage .tags-dropdown > .tag:not(:last-child) {
  margin-right: 12px;
}
.ChartDetailSubpage .tags-dropdown > .tag::before {
  content: "#";
  display: inline;
  color: hsla(207, 100%, 55%, 0.7);
  margin-right: 2px;
}
.ChartDetailSubpage .tags-dropdown > .tag:hover {
  border-color: hsl(0, 0%, 25%);
}
.ChartDetailSubpage .tags-dropdown > .tag:active {
  background: black;
  border-color: #0784fa;
  color: #0784fa;
}
.ChartList {
  width: 100vw;
}
.ChartList > .container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px 0;
}
.ChartListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: normal;
  background: #f7f8f9;
  height: 180px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid hsl(0, 0%, 87%);
  animation: slight-zoom-in 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.ChartListItem.loading {
  pointer-events: none;
  animation: gui-standby-loading-large 1.1s ease-in-out infinite;
}
.ChartListItem.loading > .chart,
.ChartListItem.loading > .events {
  opacity: 0.6;
}
.ChartListItem > .chart {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  border-radius: inherit;
  box-shadow: 3px 0 20px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.03);
}
.ChartListItem > .chart > .Button.preset-round {
  pointer-events: all;
  position: absolute;
  right: -5px;
  top: 15px;
  opacity: 0;
  font-size: 16px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.ChartListItem > .chart:hover > .Button.preset-round {
  opacity: 1;
  transition-delay: 0.3s;
}
.ChartListItem > .chart > .surface {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  box-sizing: border-box;
  background: white;
  border: 1.5px solid white;
  border-radius: inherit;
  transition: border-color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.ChartListItem > .chart > .surface > .icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -17px;
  font-size: 30px;
  color: hsl(0, 0%, 80%);
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.ChartListItem > .chart > .surface:hover {
  border-color: #0784fa;
}
.ChartListItem > .chart > .surface:hover > .icon {
  opacity: 1;
}
.ChartListItem > .chart > .surface:active {
  border-color: #005cc5;
  background: hsl(0, 0%, 98.5%);
}
.ChartListItem > .chart > .surface:active > .icon {
  color: #005cc5;
  opacity: 1;
}
.ChartListItem > .chart > .description {
  pointer-events: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  padding: 13px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.ChartListItem > .chart > .description .title {
  max-width: 380px;
  padding-right: 40px;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-x: hidden;
}
.ChartListItem > .chart > .description .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: -5px;
  margin-top: 7px;
}
.ChartListItem > .chart > .description .status > .caption {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  margin-right: 5px;
  font-size: 14px;
}
.ChartListItem > .chart > .description .status > .caption.published {
  color: #2ebb18;
}
.ChartListItem > .chart > .description .status > .caption.with-options {
  pointer-events: all;
  cursor: pointer;
  color: #f9af02;
  padding-left: 8px;
}
.ChartListItem > .chart > .description .status > .caption.with-options:hover {
  background-color: hsla(44, 95%, 59%, 0.12);
  color: #eca200;
}
.ChartListItem > .chart > .description .status > .caption.with-options:active,
.ChartListItem > .chart > .description .status > .caption.with-options.showing-options {
  background-color: hsla(44, 95%, 59%, 0.2);
  color: #eca200;
}
.ChartListItem > .chart > .description .status > .caption > .icon {
  margin-right: 0.25em;
  font-size: 15px;
}
.ChartListItem > .chart > .description .status > .caption > .icon-arrow-medium-down {
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}
.ChartListItem > .chart > .description .status > .caption > .icon-arrow-medium-down.flipped {
  transform: translateY(-1px) rotateX(180deg);
}
.ChartListItem > .chart > .description .tags {
  max-height: 2.4em;
  margin-top: 0.5em;
  overflow: hidden;
  font-size: 15px;
  color: #999;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 300px;
  overflow-y: hidden;
}
.ChartListItem > .chart > .description .tags > .tag {
  display: inline-block;
  margin-right: 8px;
}
.ChartListItem > .chart > .description .tags > .tag::before {
  content: "#";
}
.ChartListItem > .chart > .description .validation {
  display: inline-block;
  pointer-events: all;
  position: relative;
  color: #eca200;
  font-size: 14px;
  border-radius: 6px;
  left: -5px;
  margin-top: 5px;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons .icon {
  margin-right: 0.25em;
  font-size: 16px;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons .icon-arrow-medium-down {
  position: relative;
  top: 1px;
  margin-left: 5px;
  font-size: 12px;
  display: inline-block;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons .icon-arrow-medium-down.flipped {
  transform: translateY(-1px) rotateX(180deg);
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons:hover {
  background-color: hsla(44, 95%, 59%, 0.12);
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons:active,
.ChartListItem > .chart > .description .validation > .validation-text-and-icons.showing-options {
  background-color: hsla(44, 95%, 59%, 0.2);
  color: #eca200;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons.error {
  color: #db0e11;
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons.error:hover {
  background-color: hsla(358, 93%, 86%, 0.3);
}
.ChartListItem > .chart > .description .validation > .validation-text-and-icons.error:active,
.ChartListItem > .chart > .description .validation > .validation-text-and-icons.error.showing-options {
  background-color: hsla(358, 93%, 86%, 0.4);
  color: #db0e11;
}
.ChartListItem > .events {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  position: relative;
  width: 390px;
  flex-shrink: 0;
  padding: 15px 0 15px 25px;
  box-sizing: border-box;
}
.ChartListItem > .events > .title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 8px;
}
.ChartListItem > .events > .footer-link {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  font-size: 14px;
  text-align: center;
}
.ChartListItem > .events > .create-new-event {
  align-self: flex-end;
  position: absolute;
  right: 15px;
  top: 15px;
}
.ChartListItem > .events > .create-new-event.loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.ChartListItem > .events > .create-new-event .icon {
  position: relative;
  top: 1px;
  margin-right: 5px;
  font-size: 13px;
}
.ChartListItem > .events > .create-new-event button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: #0784fa;
  font-size: 14px;
}
.ChartListItem > .events > .create-new-event button:hover > span {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ChartListItem > .events > .create-new-event button:active > span {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ChartListItem.contextual-menu-opened > .chart > .surface,
.ChartListItem.contextual-menu-opened > .chart > .thumbnail {
  pointer-events: none;
}
.ChartListItem.contextual-menu-opened > .chart > .surface {
  border-color: #0784fa;
}
.ChartListItem.contextual-menu-opened > .chart > .Button.preset-round {
  background-color: #0784fa;
  opacity: 1;
  color: white;
}
.ChartListItem.loading-placeholder {
  position: relative;
  max-width: 1040px;
}
.ChartListItem.loading-placeholder > .chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 170px;
  padding-left: 210px;
  padding-right: 40px;
  padding-top: 20px;
}
.ChartListItem.loading-placeholder > .chart .fake-item {
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.08);
}
.ChartListItem.loading-placeholder > .events {
  padding-top: 30px;
  padding-right: 40px;
}
.ChartListItem.loading-placeholder > .events .fake-item {
  font-size: 16px;
  background-color: rgba(57, 85, 121, 0.12);
}
@media (max-width: 1039px) {
  .ChartListItem {
    border-radius: 0;
    min-height: 180px;
    height: auto;
  }
  .ChartListItem .surface {
    min-height: 180px;
  }
  .ChartListItem > .chart {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-left: 10px;
    padding-bottom: 15px;
  }
  .ChartListItem > .chart > .description {
    padding-bottom: 10px;
  }
  .ChartListItem > .chart > .description .title {
    max-width: 100%;
    font-size: 22px;
  }
  .ChartListItem > .chart > .description .tags {
    font-size: 14px;
  }
  .ChartListItem.placeholder > .chart {
    padding-left: 40px;
  }
}
@media (max-width: 740px) {
  .ChartListItem {
    border-radius: 0;
    min-height: 180px;
    height: auto;
  }
  .ChartListItem .surface {
    min-height: 180px;
  }
  .ChartListItem > .chart > .description .title {
    max-width: none;
  }
  .ChartListItem > .events {
    display: none;
  }
}
.EventDetail {
  --side-padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  flex-grow: 1;
  height: 100%;
  position: relative;
  padding: 25px 0 0;
  box-sizing: border-box;
}
.EventDetail > .top-panels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 var(--side-padding);
  box-sizing: border-box;
}
.EventDetail > .top-panels > .controls-panel > .over-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
  color: hsl(0, 0%, 60%);
}
.EventDetail > .top-panels > .controls-panel > .over-title:hover {
  color: #21a0f5;
}
.EventDetail > .top-panels > .controls-panel > .over-title > .icon {
  position: relative;
  top: 1px;
  margin-inline-end: 5px;
  font-size: 16px;
}
.EventDetail > .top-panels > .controls-panel > .over-title > .label {
  font-size: 14px;
  font-weight: 500;
}
.EventDetail > .top-panels > .controls-panel > .title {
  font-weight: 500;
  font-size: 26px;
  -webkit-user-select: all;
  user-select: all;
}
.EventDetail > .top-panels > .controls-panel > .title > button {
  border: none;
  margin: 0 0 0 2px;
  padding: 3px 7px;
  background: none;
  outline: none;
  cursor: pointer;
  color: #0784fa;
  font-size: 0.7em;
}
.EventDetail > .top-panels > .controls-panel > .title.small {
  font-size: 18px;
}
.EventDetail > .top-panels > .controls-panel > .title.placeholder {
  color: hsl(0, 0%, 60%);
}
.EventDetail > .top-panels > .controls-panel > .title.fixed-width {
  display: inline-block;
  background: hsl(0, 0%, 98%);
  border: 0.75px solid hsl(0, 0%, 88%);
  padding: 1px 5px 1px 8px;
  border-radius: 0.2em;
}
.EventDetail > .top-panels > .controls-panel > .title.fixed-width > button {
  position: relative;
  top: -1px;
  margin: 0 0 0 10px;
  padding: 1px 3px 1px 10px;
  border-left: 1px solid hsl(0, 0%, 85%);
  font-size: 0.8em;
}
.EventDetail > .top-panels > .controls-panel > .title + .title {
  margin-top: 6px;
}
.EventDetail > .top-panels > .controls-panel > .event-actions {
  display: flex;
  margin-top: 8px;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > .action-note:not(:last-child),
.EventDetail > .top-panels > .controls-panel > .event-actions > button:not(:last-child) {
  margin-right: 15px;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > .action-note {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  height: 1.5em;
  flex-direction: row;
  align-items: center;
  color: #979797;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > .action-note > .icon {
  position: relative;
  top: 1px;
  margin-inline-end: 5px;
  font-size: 16px;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > .action-note > .label {
  font-size: 14px;
  font-weight: 500;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5em;
  color: #0784fa;
  padding: 3px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: transparent;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button > .caption {
  color: inherit;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button > .icon {
  position: relative;
  top: 1px;
  font-size: 16px;
  margin-right: 5px;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button:hover > .icon,
.EventDetail > .top-panels > .controls-panel > .event-actions > button:hover > .caption {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button:active > .icon,
.EventDetail > .top-panels > .controls-panel > .event-actions > button:active > .caption {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.EventDetail > .top-panels > .controls-panel > .event-actions > button.loading {
  pointer-events: none;
  animation: gui-standby-loading-out 1s ease-in-out infinite;
}
.EventDetail > .top-panels > .info-panel {
  flex-grow: 1;
  margin-left: 50px;
  max-width: 500px;
}
.EventDetail > .top-panels > .info-panel > .OccupancyProgressBar {
  margin-bottom: 22px;
}
.EventDetail > .top-panels > .info-panel > .definition {
  font-size: 14px;
  color: hsl(0, 0%, 65%);
}
.EventDetail > .top-panels > .info-panel > .definition:not(:first-child) {
  margin-top: 7px;
}
.EventDetail > .top-panels > .info-panel > .definition > .label {
  margin-right: 0.2em;
}
.EventDetail > .PageSwitcher {
  margin-top: 20px;
  width: 100%;
  padding: 0 calc(var(--side-padding) - 15px);
}
.EventDetail > .subpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
}
.EventDetail > .subpage > .hint {
  margin: 10px var(--side-padding) 15px;
  color: hsl(0, 0%, 30%);
  font-size: 13px;
}
.EventDetail > .subpage > .event-manager {
  width: 100%;
  min-height: calc(100% - 40px);
  flex-shrink: 1;
  overflow: hidden;
}
.EventDetail > .subpage > .DataTable {
  position: relative;
  width: 100%;
  padding-top: 30px;
}
.EventDetail > .subpage > .loading-placeholder {
  width: 100%;
}
.EventDetail > .subpage > .loading-placeholder > .fake-item {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.08);
}
.EventDetail > .fake-item,
.EventDetail > .loading-placeholder > .fake-item {
  width: 50%;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.08);
}
.EventDetail > .fake-item.big,
.EventDetail > .loading-placeholder > .fake-item.big {
  width: 70%;
  font-size: 30px;
}
.EventDetail .SeasonDetail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.EventDetail .SeasonDetail > .events-column {
  flex-grow: 1;
  width: 50%;
  padding: var(--side-padding);
  box-sizing: border-box;
}
.EventDetail .SeasonDetail > .events-column > .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.EventDetail .SeasonDetail > .events-column > .event > .container {
  display: block;
  padding: 10px;
}
.EventDetail .SeasonDetail > .events-column > .event > .container:hover {
  color: #21a0f5;
}
.EventDetail .SeasonDetail > .events-column > .event + .event {
  border-top: 1px solid hsl(0, 0%, 91%);
}
.EventList {
  width: 100%;
}
.EventList .EventListItem {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.EventList .EventListItem:last-child {
  border-bottom: none;
}
.EventList .EventListItem > a {
  position: relative;
  display: block;
  padding: 4px 15px 7px 15px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 6px;
  left: -15px;
  font-size: 14px;
}
.EventList .EventListItem > a:not(:hover) {
  color: #6186a8;
}
.EventList .EventListItem > a > .event-key {
  width: 95%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  line-height: 19px;
}
.EventList .EventListItem > a > .event-key::before {
  position: absolute;
  background-image: linear-gradient(to right, rgba(247, 248, 249, 0), #F7F8F9);
  right: 0;
  width: 15px;
  height: 18px;
  display: block;
  content: "";
}
.EventList .EventListItem.success > a {
  animation: list-item-success-pulse 1.5s ease-in-out backwards;
}
.EventList .EventListItem.failed > a {
  animation: list-item-failed-pulse 1.5s ease-in-out backwards;
}
@keyframes list-item-success-pulse {
  from,
  to {
    border-color: rgba(96, 195, 4, 0);
  }
  15%,
  40% {
    color: #2ebb18;
    border-color: #2ebb18;
    transform: scale(1.01);
  }
}
@keyframes list-item-failed-pulse {
  from,
  to {
    border-color: rgba(219, 14, 17, 0);
  }
  10% {
    color: #db0e11;
    border-color: #db0e11;
    transform: scale(1.01);
  }
  30%,
  80% {
    border-color: #db0e11;
  }
}
.EventList .EventListItem > .Button.preset-round {
  position: absolute;
  right: 0px;
  top: 5px;
  opacity: 0;
}
.EventList .EventListItem.contextual-menu-opened > .Button.preset-round,
.EventList .EventListItem:hover > .Button.preset-round {
  opacity: 1;
  transition-delay: 0.2s;
}
.EventList .EventListItem.contextual-menu-opened > a {
  color: #0784fa;
  border-color: #0784fa;
}
.EventList .EventListItem.contextual-menu-opened > .Button.preset-round {
  color: white;
  background: #0784fa;
}
.chartDesignerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.chartDesignerContainer > .designer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.chartDesignerContainer > .designer > iframe {
  flex-grow: 1;
}
.ModalDialog.SplashScreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1A1A1A;
}
.ModalDialog.SplashScreen > .backdrop {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: radial-gradient(ellipse at center, #495864, #333638 30%, #2a2c2e 50%, #111111);
  background-size: cover;
  animation: fade-in 4s cubic-bezier(0.19, 1, 0.22, 1) 0.5s backwards;
}
.ModalDialog.SplashScreen > .backdrop::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("/assets/noise@2x.png") repeat center center;
  background-size: 300px 300px;
  opacity: 0.01;
}
.ModalDialog.SplashScreen > .logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  height: 25px;
  background: url("/assets/seatsio-logo-white.svg") no-repeat center center;
  background-size: contain;
  animation: fade-in 1.4s cubic-bezier(0.19, 1, 0.22, 1) 0.6s backwards;
}
.ModalDialog.SplashScreen > .dialog {
  animation: slight-zoom-in 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
  animation-fill-mode: backwards;
}
.ModalDialog.SplashScreen > .quick-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;
  padding: 0 15px;
  margin-top: 30px;
  text-align: center;
}
.ModalDialog.SplashScreen > .quick-links > .quick-link {
  display: block;
  font-size: 15px;
  padding: 1em;
  min-width: 150px;
  color: #6186a8;
  animation: shiny-quick-link-fade-in 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s backwards;
}
.ModalDialog.SplashScreen > .quick-links > .quick-link:nth-child(2) {
  animation-delay: 1.4s;
}
.ModalDialog.SplashScreen > .quick-links > .quick-link:hover {
  color: #72b1d6;
  text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.1);
}
.ModalDialog.SplashScreen > .quick-links > .quick-link:active {
  color: #4f6c88;
  text-shadow: 0 0 12px hsla(0, 0%, 0%, 0.1);
}
@keyframes shiny-quick-link-fade-in {
  from {
    color: hsl(185, 100%, 86%);
    text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.3);
    opacity: 0;
  }
  30% {
    color: hsl(185, 100%, 86%);
    text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.3);
    opacity: 1;
  }
}
.ContextualMenu.AccountMenu {
  visibility: visible;
  background: hsla(0, 0%, 5%, 0.25);
  animation: background-color-fade-in 0.3s cubic-bezier(0.19, 1, 0.22, 1) backwards;
}
.ContextualMenu.AccountMenu > .menu {
  right: 10px;
  top: 0;
  border-radius: 11px;
  min-width: 200px;
  padding: 5px;
  transform-origin: top right;
}
.ContextualMenu.AccountMenu > .menu .profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: hsl(0, 0%, 10%);
  padding: 7px 10px 15px;
  box-sizing: border-box;
}
.ContextualMenu.AccountMenu > .menu .profile .avatar {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 100px;
  background-color: hsl(0, 0%, 90%);
  background-size: cover;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.2);
}
.ContextualMenu.AccountMenu > .menu .profile .name {
  font-size: 15px;
}
.ContextualMenu.AccountMenu > .menu .impersonation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsl(240, 2%, 92%);
  padding: 5px;
  border-radius: 6px;
  width: 240px;
  margin-top: 5px;
}
.ContextualMenu.AccountMenu > .menu .Button.preset-contextual-menu-item {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 14px;
  border-radius: 6px;
}
.ContextualMenu.AccountMenu > .menu .Button.preset-contextual-menu-item .icon {
  font-size: 22px;
  margin-right: 17px;
}
.ContextualMenu.AccountMenu > .menu .Button.preset-contextual-menu-item .icon.icon-logout {
  position: relative;
  left: 2px;
}
.ContextualMenu.AccountMenu > .menu .Button.preset-contextual-menu-item .caption {
  font-weight: normal;
}
.ContextualMenu.AccountMenu > .menu .separator {
  background-color: hsla(0, 0%, 0%, 0.1);
}
.ContextualMenu.AccountMenu > .menu .company {
  width: 100%;
  padding: 8px 13px;
  box-sizing: border-box;
  color: black;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.Badge {
  display: inline-block;
  position: relative;
  pointer-events: none;
  padding: 0.3em 0.75em;
  margin-left: 1em;
  border-radius: 0.4em;
  background-color: hsl(0, 0%, 93%);
  color: hsl(0, 0%, 40%);
  font-family: 'Helvetica', 'Roboto', 'Arial', sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2em;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.Badge.current {
  background-color: hsl(210, 1%, 34%);
  color: hsl(210, 8%, 95%);
}
.Badge.main {
  background-color: hsl(0, 0%, 92%);
  color: hsl(0, 0%, 40%);
}
.Badge.production {
  background-color: hsl(334, 65%, 91%);
  color: hsl(335, 79%, 41%);
}
.Badge.test {
  background-color: hsl(140, 50%, 88%);
  color: hsl(143, 73%, 29%);
}
.Badge.deleted {
  background-color: hsl(334, 65%, 96%);
  color: hsl(335, 61%, 53%);
}
.Badge.dark.production {
  background-color: hsl(335, 74%, 21%);
  color: hsl(335, 100%, 54%);
}
.Badge.dark.test {
  background-color: hsl(141, 78%, 18%);
  color: hsl(77, 75%, 56%);
}
.Badge + .Badge {
  margin-left: 0.4em;
}
.Badge.with-icon {
  height: auto;
  padding: 2px 6px 2px;
  font-size: 10px;
  vertical-align: middle;
}
.Badge.with-icon > .icon {
  position: relative;
  top: 1px;
  font-size: 12px;
  margin-inline-end: 3px;
}
.Badge.with-icon > .label {
  position: relative;
  top: -1px;
}
.action-cell:hover .icon + .Badge {
  margin-left: 42px;
}
.Button {
  position: relative;
  font-size: 14px;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  outline: none !important;
}
.Button .icon,
.Button .caption,
.Button img {
  pointer-events: none;
}
.Button.preset-contextual-menu-item {
  width: 100%;
  padding: 3px 20px 3px 16px;
  color: #141414;
  text-align: left;
}
.Button.preset-contextual-menu-item > .wrapper {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Button.preset-contextual-menu-item > .wrapper > .icon {
  display: block;
  margin-right: 8px;
  font-size: 18px;
}
.Button.preset-contextual-menu-item > .wrapper > .caption {
  display: block;
  font-size: 14px;
  padding-bottom: 1px;
  font-weight: 500;
}
.Button.preset-contextual-menu-item:hover:not(:disabled) {
  color: #fff;
  background: #0784fa;
}
.Button.preset-contextual-menu-item:active:not(:disabled) {
  color: #fff;
  background: #005cc5;
}
.Button.preset-contextual-menu-item:disabled {
  color: #979797;
}
.Button.preset-contextual-menu-item .wrapper.with-soft-icon {
  position: relative;
  padding-right: 1.5em;
}
.Button.preset-contextual-menu-item .wrapper.with-soft-icon .softIcon {
  position: absolute;
  right: -0.5em;
  opacity: 0.5;
  font-size: 0.9em;
}
.Button.preset-round {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 0px;
  top: 6px;
  z-index: 10;
  width: 1.42em;
  height: 1.42em;
  border: none;
  border-radius: 20px;
  background: #e5e5e5;
  margin-right: 25px;
  text-align: center;
  font-size: 14px;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.Button.preset-round:hover {
  background: #e0e0e0;
}
.Button.preset-round:active {
  color: white;
  background: #005cc5;
}
.ContextualMenu {
  pointer-events: all;
  cursor: default;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5200;
  background-color: hsla(0, 0%, 0%, 0.1);
  visibility: hidden;
}
.ContextualMenu > .menu {
  pointer-events: all;
  position: fixed;
  padding: 5px 0;
  margin: 5px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 8px 22px rgba(0, 0, 0, 0.2), inset 0 1px 0 1px rgba(255, 255, 255, 0.25);
  animation: slight-zoom-in 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  max-height: 70vh;
  overflow-y: auto;
}
.ContextualMenu > .menu > .scroll-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ContextualMenu > .menu .separator {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ContextualMenu > .menu .validation-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 1.5em 5px 1em;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.ContextualMenu > .menu .validation-item.warning {
  color: #eca200;
}
.ContextualMenu > .menu .validation-item.error {
  color: #db0e11;
}
.ContextualMenu > .menu .validation-item > .icon {
  margin-right: 0.5em;
  font-size: 17px;
}
.ContextualMenu.open {
  visibility: visible;
  animation: contextual-menu-in 2s cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes contextual-menu-in {
  from {
    background-color: hsla(0, 0%, 0%, 0);
  }
  to {
    background-color: hsla(0, 0%, 0%, 0.1);
  }
}
.DataTable {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.DataTable.no-scroll {
  overflow: hidden;
}
.DataTable .infinite-scroll-container {
  height: 0px;
}
.DataTable table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: scroll;
}
.DataTable table th {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 10px;
}
.DataTable table th:first-child {
  padding-left: 30px;
}
.DataTable table th.sortable {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.DataTable table th.sortable .icon {
  position: absolute;
  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
  visibility: visible;
}
.DataTable table th.sortable .icon.flipped {
  transform: rotate(180deg);
}
.DataTable table th.sortable:hover,
.DataTable table th.sortable:hover > .icon {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.DataTable table th.sortable:active,
.DataTable table th.sortable:active > .icon {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.DataTable table th.sortable.selected {
  color: #0784fa;
}
.DataTable table th.sortable.loading {
  animation: gui-standby-loading 1.1s ease-in-out infinite;
  pointer-events: none;
}
.DataTable table tr {
  position: relative;
}
.DataTable table tr td:first-child {
  padding-left: 30px;
}
.DataTable table tr td:last-child {
  padding-right: 20px;
}
.DataTable table tr:hover td {
  background: hsl(0, 0%, 98%);
}
.DataTable table tr:hover td.has-button > .Button {
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.2s;
}
.DataTable table tr.contextual-menu-opened > td {
  background: #0784fa;
  color: white;
}
.DataTable table tr.contextual-menu-opened > td.action-cell > span {
  color: white;
}
.DataTable table tr.contextual-menu-opened .Button.preset-round {
  color: #0784fa;
  background: white;
  opacity: 1;
  transform: scale(1);
}
.DataTable table tr.deactivated {
  color: #cacaca;
}
.DataTable table tr.enter {
  animation: table-tr-enter 2s cubic-bezier(0.19, 1, 0.22, 1) backwards 0.2s;
}
@keyframes table-tr-enter {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.DataTable table td {
  text-align: left;
  padding: 8px 10px 9px 5px;
  border-top: 1px solid hsl(0, 0%, 91%);
  vertical-align: middle;
  font-size: 14px;
}
.DataTable table td strong,
.DataTable table td.quantity {
  font-weight: 500;
}
.DataTable table td .soft {
  color: #999999;
}
.DataTable table td.fixed-width {
  font-size: 11px;
}
.DataTable table td > pre {
  display: inline-block;
  font-size: 11px;
  padding: 0;
  margin: 0 12px 0 0;
}
.DataTable table td .Badge {
  cursor: default;
  user-select: none;
  pointer-events: auto;
  margin: 0 0 0 20px;
}
.DataTable table td .display-label {
  color: #999999;
  font-size: 12px;
  white-space: nowrap;
}
.DataTable table td.has-button {
  text-align: right;
  padding-right: 0 !important;
}
.DataTable table td.has-button > .Button {
  pointer-events: none;
  opacity: 0;
  transform: scale(0.6);
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
  transition-property: opacity, transform;
}
.DataTable table td.action-cell > span {
  position: relative;
  cursor: pointer;
  color: #0784fa;
}
.DataTable table td.action-cell:active {
  color: #005cc5;
}
.DataTable table td.action-cell .icon {
  position: absolute;
  margin-top: -0.1em;
  padding-left: 0.5em;
  font-size: 1.5em;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.DataTable table td.action-cell:hover .icon {
  opacity: 1;
  transition-delay: 0.1s;
}
.DataTable table td > .nowrap {
  display: inline-block;
  white-space: nowrap;
}
.DataTable table td > .nowrap:not(:last-child) {
  margin-right: 10px;
}
.DataTable table td > .date {
  width: 94px;
  margin-right: 0 !important;
}
.DataTable table td > .Button.preset-round {
  position: relative;
  top: 0;
}
.DataTable .SearchInput {
  margin: 5px auto 25px;
}
.DataTable .center-message {
  padding: 30px;
  color: #6f7477;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}
.DataTable .loading-placeholder:not(tr) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.DataTable .loading-placeholder:not(tr) > .fake-item {
  width: 18%;
}
.DataTable .loading-placeholder:not(tr) + .loading-placeholder {
  display: none;
}
.DataTable tr.loading-placeholder td {
  border-top-color: transparent;
}
.DataTable tr.loading-placeholder td .fake-item {
  height: 20px;
  margin: 0;
}
.DropDown {
  position: relative;
}
.DropDown > .container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.DropDown .value {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  font-size: 22px;
  font-weight: 500;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.25em 2em 0.25em 0.5em;
}
.DropDown .value::after {
  content: "\e960";
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -0.5em;
  font-size: 16px;
  font-family: "seatsdesigner";
}
.DropDown select {
  appearance: none;
  width: 100%;
  height: 2.8em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: transparent;
  color: transparent;
  font-size: 13px;
  outline: none;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.DropDown select > option {
  color: black;
}
.DropDown select:hover {
  border-color: rgba(0, 0, 0, 0.2);
}
.DropDown select:active {
  color: rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.05);
}
.SubscriptionPlanDropDown .value {
  font-size: inherit;
}
.SubscriptionPlanDropDown select {
  height: 2em;
  width: 180px;
}
form {
  position: relative;
}
form input.error,
form select.error {
  box-shadow: 0 0 0 2px #db0e11;
}
form.submitting input,
form.submitted input,
form.submitting select,
form.submitted select {
  pointer-events: none;
}
form.submitting .form-field,
form.submitted .form-field {
  pointer-events: none;
  opacity: 0.4;
}
form.submitted .form-actions,
form.submitted .firm-commitment-notice {
  visibility: hidden;
}
form.submitted .submitted-notice {
  opacity: 1;
}
form.submitted .form-field {
  height: 0;
}
form:not(.submitted) .submitted-notice {
  pointer-events: none;
}
form .submitted-notice {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  justify-content: center;
  background: white;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
}
form .errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  margin: 0 0 8px;
}
form .errors > .error {
  padding: 2px 10px;
  border-radius: 1em;
  color: white;
  background: #db0e11;
  font-size: 12px;
  font-weight: 500;
  margin: 4px auto;
}
form .form-field input:not([type="checkbox"]) {
  width: 320px;
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  max-width: 100%;
}
form .form-field input:not([type="checkbox"]).error {
  border-color: red;
}
form .hint {
  margin: 0.5em 0 0;
  max-width: 320px;
  color: #7f7f7f;
  font-size: 13px;
  text-align: left;
  line-height: 1.25em;
}
form .hint.center {
  text-align: center;
}
form .hint.dark {
  color: #141414 !important;
}
form .form-field + .hint {
  margin-top: 25px;
}
form .form-field.single-field {
  justify-content: flex-start;
  align-items: flex-start;
}
form .form-field.single-field.center {
  justify-content: center;
}
form .form-field.single-field input[type="checkbox"] {
  margin: 3px 15px 0 0;
}
form .form-field.single-field .checkbox-label > label {
  max-width: none;
}
form .form-field.single-field .checkbox-label > a {
  display: inline-block;
  font-size: 0.9em;
  margin-top: 0.25em;
}
form .form-field.checkbox-list {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
form .form-field.checkbox-list > label.field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  color: hsl(0, 0%, 15%);
}
form .form-field.checkbox-list > label.field > input {
  margin-right: 9px;
}
form .form-field.checkbox-list > label.field.selected {
  color: black;
  background: hsla(211, 100%, 30%, 0.06);
}
form .form-field.checkbox-list > label.field + label.field {
  margin-top: 2px;
}
form .input-list {
  overflow-y: scroll;
  width: 100%;
  max-height: 203px;
  animation: max-height-from-zero 0.5s cubic-bezier(0.19, 1, 0.22, 1) backwards;
  background-color: #fff;
  border: 1px solid hsla(0, 0%, 0%, 0.08);
  border-radius: 8px;
}
form .input-list.with-sub-captions {
  max-height: 244px;
}
form .input-list .list-item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
form .input-list .list-item:hover {
  background-color: hsl(0, 0%, 98%);
}
form .input-list .list-item input {
  margin-left: 10px;
}
form .input-list .list-item input[type="radio"] {
  flex-shrink: 0;
}
form .input-list .list-item input[type="checkbox"] {
  flex-shrink: 1;
  width: 18px;
  height: 18px;
}
form .input-list .list-item label {
  cursor: pointer;
  display: block;
  flex-shrink: 1;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  color: #464646;
  font-size: 15px;
  text-align: left;
}
form .input-list .list-item label .sub-caption {
  font-size: 13px;
  color: #979797;
}
form .input-list .list-item + .list-item label {
  border-top: 1px solid hsl(0, 0%, 91%);
}
form .input-list .list-item input:checked + label {
  color: #0784fa;
}
form .input-list .list-item input:disabled + label {
  cursor: default;
  color: #979797;
}
form .field-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 25px auto 0;
  background: hsl(0, 0%, 94%);
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.02);
  padding: 3px;
  border-radius: 12px;
}
form .field-options:last-child {
  margin-bottom: 0;
}
form .field-options .field-option {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: hsla(0, 0%, 35%);
  background: hsla(0, 0%, 100%, 0);
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
form .field-options .field-option label {
  cursor: pointer;
  padding: 5px 20px;
}
form .field-options .field-option label .icon {
  font-size: 44px;
  text-align: center;
}
form .field-options .field-option label .caption {
  margin-top: 2px;
  font-size: 14px;
}
form .field-options .field-option input[type="radio"] {
  display: none;
}
form .field-options .field-option input[type="radio"]:checked + label {
  color: #0784fa;
}
form .field-options .field-option input[type="radio"]:disabled + label {
  color: lightgrey;
}
form .field-options .field-option.selected {
  background: white;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1);
  color: hsla(0, 0%, 10%);
}
form .field-options .field-option:hover {
  color: hsla(0, 0%, 10%);
}
form .field-options label {
  text-align: center;
}
form .field-options label:hover {
  cursor: pointer;
}
form .field-options.no-icons {
  width: auto;
}
form .field-options.no-icons .field-option {
  flex-grow: 0;
  box-sizing: border-box;
  border: 1px solid transparent;
}
form .field-options.no-icons .field-option label {
  font-size: 16px;
  padding: 5px 25px;
}
form .field-options.no-icons .field-option:hover {
  cursor: pointer;
}
form .field-options.no-icons .field-option.production.selected {
  border: 1px solid hsl(335, 57%, 82%);
  box-shadow: 0 1px 3px hsla(335, 100%, 44%, 0.3);
}
form .field-options.no-icons .field-option.production.selected label {
  color: hsl(335, 79%, 41%);
}
form .field-options.no-icons .field-option.test.selected {
  border: 1px solid hsl(144, 48%, 78%);
  box-shadow: 0 1px 3px hsla(143, 100%, 40%, 0.3);
}
form .field-options.no-icons .field-option.test.selected label {
  color: hsl(143, 73%, 29%);
}
form .form-group {
  width: 100%;
}
form .form-group.hidden {
  display: none;
}
.two-column {
  margin: 0 auto;
  width: 550px;
}
.two-column .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  width: inherit;
  margin-top: 2.5em;
  font-size: 15px;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1), height 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.two-column .form-field.wide {
  width: 100%;
}
.two-column .form-field > .description {
  width: 240px;
  padding-top: 6px;
}
.two-column .form-field > .description label {
  display: block;
}
.two-column .form-field > .description .hint {
  margin-top: 0.25em;
}
.two-column .form-field > .description .hint:not(a) {
  color: hsl(0, 0%, 55%);
}
.two-column .form-field > .field {
  width: 220px;
}
.two-column .form-field > .field .hint {
  margin-top: 0.5em;
  color: hsl(0, 0%, 55%);
}
.two-column .form-field > .field .hint .price {
  margin-top: 0.5em;
}
.two-column .form-field select {
  width: 220px;
  font-size: 16px;
  box-sizing: border-box;
}
.two-column .form-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  height: 40px;
}
.two-column .form-actions input[type="submit"] {
  cursor: pointer;
  appearance: none;
  display: block;
  height: 2.4em;
  padding: 0 1.8em;
  border: none;
  line-height: 2.3em;
  border-radius: 0.6em;
  background: #0784fa;
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.two-column .form-actions input[type="submit"]:hover {
  background: #21a0f5;
}
.two-column .form-actions input[type="submit"]:active {
  background: #1984db;
}
.two-column .form-actions input[type="submit"]:disabled {
  cursor: default;
  background: hsl(0, 0%, 65%);
}
.sectioned-page > .sectioned-page-container > .section form.newStyle input,
.sectioned-page > .sectioned-page-container > .section form.newStyle select {
  max-width: unset;
  display: block;
  padding: 12px;
  width: 100%;
  margin-top: 1em;
  font-size: inherit;
  border-color: #e6e6e6;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .flex-wrapper {
  display: flex;
  gap: 1em;
  justify-content: space-between;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle label {
  font-size: 15px;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .hint {
  max-width: unset;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .action {
  display: block;
  margin: 40px auto;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .description {
  margin-top: 2.5em;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .termsAgreement {
  margin: 3em 0 0.5em 0;
  display: flex;
  justify-self: start;
  justify-items: left;
  align-items: baseline;
  gap: 0.25em;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .termsAgreement > input {
  width: auto;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .termsAgreement > input {
  position: relative;
  top: 2px;
}
.sectioned-page > .sectioned-page-container > .section form.newStyle .termsAgreement > .terms {
  margin-left: auto;
}
.FormButton {
  cursor: pointer;
  padding: 0.625em 1.125em;
  margin: 0;
  border-radius: 5px;
  background: #444;
  border: none;
  color: white;
  font-size: inherit;
  line-height: 1em;
}
.FormButton[disabled] {
  cursor: auto;
  background: #9d9d9d;
  color: #cbcbcb;
}
.FormButton:not(:first_child) {
  margin-top: 20px;
}
.FormButton:not([disabled]):hover {
  background: #4a5052;
}
.FormButton:not([disabled]):active {
  background: #333;
}
.FormButton.loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.FormButton.noMargin {
  margin: 0;
}
.FormButton.horizontalMargin {
  margin: 2px;
}
.FormButton.danger {
  background: #db0e11;
}
.FormButton.no-danger {
  background: #2ebb18;
}
.LoadingIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  height: 5px;
}
.LoadingIndicator .indicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #333;
  animation: loading-indicator 1s ease-in-out infinite;
  animation-fill-mode: forwards;
}
.LoadingIndicator .indicator:nth-child(2) {
  animation-delay: 0.1s;
}
.LoadingIndicator .indicator:nth-child(3) {
  animation-delay: 0.2s;
}
.LoadingIndicator .indicator:nth-child(4) {
  animation-delay: 0.3s;
}
.LoadingIndicator .indicator:nth-child(5) {
  animation-delay: 0.4s;
}
@keyframes loading-indicator {
  from,
  50%,
  to {
    opacity: 0.3;
    transform: scale(0.8);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
}
.IconButtonToSelect {
  --size: 20px;
  --arrows-width: calc(var(--size) * 0.8);
}
.IconButtonToSelect > .container {
  position: relative;
  top: 2px;
  height: var(--size);
  width: var(--size);
  padding: 0 10px;
  border-radius: 6px;
  color: #7f7f7f;
}
.IconButtonToSelect > .container > select {
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  color: transparent;
}
.IconButtonToSelect > .container > .icon {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(var(--size) * 0.2);
  top: 0;
  right: var(--arrows-width);
  bottom: 2px;
  font-size: var(--size);
}
.IconButtonToSelect > .container > .arrows {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--arrows-width);
  bottom: 0;
  font-size: calc(var(--size) * 0.5);
}
.NavigationList {
  background-color: hsl(0, 0%, 94%);
  border-right: 1px solid hsl(0, 0%, 92%);
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.NavigationList .item {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
}
.NavigationList .title {
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 15px 10px;
}
.NavigationList .title > .caption {
  color: black;
  font-size: 15px;
  font-weight: 500;
}
.NavigationList .title > .caption > .sub-caption {
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
}
.NavigationList .title > .button {
  cursor: pointer;
  color: #0784fa;
  font-size: 13px;
  font-weight: 500;
}
.NavigationList .title > .button > .icon {
  position: relative;
  top: 1px;
  font-size: 13px;
  margin-right: 5px;
}
.NavigationList .title > .button:hover,
.NavigationList .title > .button:hover > .icon {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.NavigationList .title > .button:active,
.NavigationList .title > .button:active > .icon {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.NavigationList .title:not(:first-child) {
  margin-top: 20px;
  border-top: 1px solid hsl(0, 0%, 87%);
}
.NavigationList .title + .title {
  margin-top: 10px;
}
.NavigationList .link,
.NavigationList .option,
.NavigationList .nav-button {
  cursor: pointer;
  min-height: 29px;
  padding: 6px 15px 7px;
  color: hsl(0, 0%, 20%);
  font-size: 13px;
}
.NavigationList .link.selected,
.NavigationList .option.selected,
.NavigationList .nav-button.selected {
  color: white;
  background-color: #0784fa;
}
.NavigationList .link .fixed-width,
.NavigationList .option .fixed-width,
.NavigationList .nav-button .fixed-width {
  pointer-events: none;
}
.NavigationList .link > .main-caption.fixed-width,
.NavigationList .option > .main-caption.fixed-width,
.NavigationList .nav-button > .main-caption.fixed-width {
  font-size: 11px;
}
.NavigationList .link > .sub-caption,
.NavigationList .option > .sub-caption,
.NavigationList .nav-button > .sub-caption {
  margin-top: 1px;
  font-size: 10px;
  font-weight: normal;
  opacity: 0.7;
}
.NavigationList .link > .sub-caption.fixed-width,
.NavigationList .option > .sub-caption.fixed-width,
.NavigationList .nav-button > .sub-caption.fixed-width {
  font-size: 10px;
}
.NavigationList .main-caption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.NavigationList .link {
  --sub-padding: 0px;
  --icon-padding: 0px;
  position: relative;
  border-radius: 6px;
  padding-left: calc(var(--sub-padding) + var(--icon-padding) + 15px);
  word-break: break-all;
}
.NavigationList .link.sub {
  --sub-padding: 15px;
}
.NavigationList .link.with-icon {
  --icon-padding: 15px + 14px;
}
.NavigationList .link > .icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(var(--sub-padding) + 15px);
  top: -1px;
  bottom: 0;
  font-size: 18px;
  width: 25px;
}
.NavigationList .link > .icon.icon-event-done {
  font-size: 14px;
}
.NavigationList .link.highlighted {
  animation: list-item-success-pulse 1.5s ease-in-out backwards;
}
.NavigationList .link.indicate-done {
  color: hsl(0, 0%, 50%);
  text-decoration: line-through;
}
.NavigationList .link.indicate-done.selected {
  color: hsl(201, 48%, 93%);
}
.NavigationList .nav-button {
  border: none;
  background-color: transparent;
  text-align: left;
}
.NavigationList .loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.NewFeaturesList {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(175deg, hsl(204, 47%, 95%) 0%, hsl(190, 9%, 84%) 30%, hsl(337, 25%, 87%) 70%, hsl(340, 27%, 78%) 100%);
  animation: fade-in 1s cubic-bezier(0.23, 1, 0.32, 1);
  color: hsl(0, 3%, 15%);
}
.NewFeaturesList > .go-back-button {
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 35px 40px;
  z-index: 2;
  font-size: 24px;
  font-weight: 600;
  animation: misty-title-enter 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.NewFeaturesList > .go-back-button > .icon {
  margin-right: 10px;
  font-size: 22px;
}
.NewFeaturesList > .go-back-button:hover {
  color: #0784fa;
}
.NewFeaturesList > .go-back-button:active {
  color: #005cc5;
}
.NewFeaturesList > .title {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  font-size: 56px;
  font-weight: 600;
  text-align: center;
  animation: misty-title-enter 3s cubic-bezier(0.23, 1, 0.32, 1);
  background: -webkit-linear-gradient(hsl(249, 60%, 18%), hsl(209, 27%, 44%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.NewFeaturesList > .features-list {
  pointer-events: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  top: 120px;
  padding-top: 50px;
  padding-bottom: 15vh;
  overflow-y: auto;
  mask-image: linear-gradient(to bottom, hsla(0, 0%, 100%, 0) 20px, hsl(0, 0%, 100%) 90px);
  mask-position: center top;
  mask-size: 100% 100%;
}
.NewFeaturesList > .features-list > a.feature-link {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 20px;
  margin: 30px auto 0;
  opacity: 0.75;
  font-weight: bold;
  text-align: center;
}
.NewFeaturesList .Feature {
  position: relative;
  pointer-events: auto;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 750px;
  margin: 0 auto;
  flex-shrink: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 25px hsla(0, 0%, 0%, 0.12);
  animation: feature-enter 1.2s cubic-bezier(0.23, 1, 0.32, 1) backwards 1s;
  margin-top: 50px;
}
.NewFeaturesList .Feature:nth-child(1) {
  animation-delay: 0s;
}
.NewFeaturesList .Feature:nth-child(2) {
  animation-delay: 0.2s;
}
.NewFeaturesList .Feature:nth-child(3) {
  animation-delay: 0.4s;
}
.NewFeaturesList .Feature:nth-child(4) {
  animation-delay: 0.6s;
}
.NewFeaturesList .Feature:nth-child(5) {
  animation-delay: 0.8s;
}
.NewFeaturesList .Feature > .thumbnail,
.NewFeaturesList .Feature > video {
  pointer-events: none;
  width: 400px;
  height: 250px;
  border-radius: 12px 0 0 12px;
}
.NewFeaturesList .Feature > .details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 350px;
  height: 250px;
  box-sizing: border-box;
  padding: 23px 25px;
}
.NewFeaturesList .Feature > .details > .title {
  color: black;
  font-size: 26px;
  font-weight: 500;
}
.NewFeaturesList .Feature > .details > .body {
  flex-grow: 1;
  margin-top: 0.5em;
  color: hsl(0, 0%, 6%);
  font-size: 16px;
  line-height: 1.5em;
}
.NewFeaturesList .Feature > .details > .link {
  font-size: 16px;
}
.NewFeaturesList .Feature > .beta {
  position: absolute;
  left: -2px;
  top: -1px;
  width: 65px;
  height: 66px;
  border-radius: 0;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) {
  height: 125px;
  opacity: 0.8;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) > .thumbnail {
  width: 200px;
  height: 125px;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) > .details {
  width: 550px;
  height: 125px;
  padding: 16px 25px;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) > .details > .title {
  width: 350px;
  font-size: 23px;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) > .details > .body {
  width: 350px;
  margin-top: 0.5em;
  font-size: 15px;
  line-height: 1.4em;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) > .details > .link {
  position: absolute;
  top: 20px;
  right: 20px;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)).with-link:hover {
  opacity: 1;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)):not(.with-link) > .details > .title {
  width: 450px;
}
.NewFeaturesList .Feature:not(:nth-child(-n+3)) + .Feature {
  margin-top: 25px;
}
.NewFeaturesList .Feature.with-link:not(:hover) .link {
  color: hsl(0, 0%, 60%);
}
.NewFeaturesList .Feature.with-link:active {
  box-shadow: 0 0 0 2px #0784fa, 0 8px 25px hsla(0, 0%, 0%, 0.12);
}
@keyframes misty-title-enter {
  from {
    transform-origin: center bottom;
    transform: scale(0.95);
    filter: blur(5px) opacity(0);
  }
  to {
    transform-origin: center bottom;
    transform: scale(1);
    filter: blur(0) opacity(1);
  }
}
@keyframes feature-enter {
  from {
    transform: translateY(-20px) scale(0.7);
    filter: blur(5px) opacity(0);
  }
  to {
    transform: translateY(0) scale(1);
    filter: blur(0) opacity(1);
  }
}
.Notifications {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  top: 110px;
  z-index: 5000;
}
.Notifications .Notification {
  cursor: pointer;
  position: relative;
  width: 25vw;
  margin-top: 10px;
  border-radius: 6px;
  background: #f5f5f5;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.8), 0 3px 10px hsla(0, 0%, 0%, 0.12);
}
.Notifications .Notification::before {
  content: "";
  display: block;
  pointer-events: none;
  border-radius: inherit;
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 1px solid hsla(0, 0%, 2%, 0.2);
}
.Notifications .Notification .icon,
.Notifications .Notification .description {
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
}
.Notifications .Notification .description {
  padding: 9px 15px 10px;
  box-sizing: border-box;
  color: #222;
}
.Notifications .Notification .description > .title {
  font-size: 14px;
  font-weight: 500;
}
.Notifications .Notification .description > .message {
  font-size: 13px;
}
.Notifications .Notification .description .title + .message {
  margin-top: 0.25em;
}
.Notifications .Notification .hint {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 13px;
}
.Notifications .Notification:hover::before {
  border-color: hsla(0, 0%, 2%, 0.25);
}
.Notifications .Notification:hover > .icon,
.Notifications .Notification:hover > .description {
  opacity: 0;
  transition-delay: 0s;
}
.Notifications .Notification:hover > .hint {
  opacity: 1;
  transition-delay: 0.2s;
}
.Notifications .Notification:active {
  background: hsl(0, 0%, 94%);
}
.Notifications .Notification.error::before {
  border-width: 1.5px;
  border-color: hsla(0, 100%, 41%, 0.5);
}
.Notifications .Notification.error:hover::before {
  border-color: hsla(7, 100%, 41%, 0.6);
}
.Notifications .Notification.warning::before {
  border-width: 1.5px;
  border-color: hsla(39, 100%, 50%, 0.9);
}
.Notifications .Notification.warning:hover::before {
  border-color: hsla(36, 100%, 50%, 0.92);
}
.Notifications .Notification > .progress-bar {
  position: relative;
  top: 0px;
  height: 1px;
  padding: 0 15px 10px;
}
.Notifications .Notification > .progress-bar > .bar {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 0;
  height: inherit;
}
.Notifications .Notification > .progress-bar > .progress {
  position: absolute;
  left: 15px;
  right: 15px;
  height: inherit;
  background: black;
  animation: progress-bar-grow linear forwards;
}
@keyframes progress-bar-grow {
  from {
    transform: scaleX(1);
    opacity: 0.7;
  }
  to {
    transform: scaleX(0);
    opacity: 1;
  }
}
.Notifications .Notification.notification-enter {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.5s cubic-bezier(0.44, 2.16, 0.47, 0.74);
}
.Notifications .Notification.notification-enter.notification-enter-active {
  opacity: 1;
  transform: scale(1);
}
.Notifications .Notification.notification-exit {
  max-height: 70px;
  transform: scale(1);
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), max-height 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, margin-top 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
}
.Notifications .Notification.notification-exit.notification-exit-active {
  margin-top: 0;
  max-height: 0;
  transform: scale(0.7);
  opacity: 0;
}
.NumericInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 26px;
  font-size: inherit;
}
.NumericInput > .caption {
  cursor: inherit;
  flex-grow: 1;
  height: 24px;
  margin-right: 4px;
  border-radius: 0.25em;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  transition: padding 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.NumericInput .value {
  position: relative;
  width: 120px;
}
.NumericInput .value .label,
.NumericInput .value input[type=text] {
  display: block;
  box-sizing: border-box;
  width: inherit;
  height: 26px;
  margin: 0;
  border: 1px solid hsla(0, 0%, 50%, 0.12);
  border-bottom-color: hsla(0, 0%, 50%, 0.19);
  border-radius: 0.25em;
  background: #fff;
  font: inherit;
  text-align: center;
  line-height: 1.6em;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .NumericInput .value .label,
  .NumericInput .value input[type=text] {
    border: 0.5px solid hsla(0, 0%, 50%, 0.2);
    border-bottom-color: hsla(0, 0%, 50%, 0.3);
  }
}
.NumericInput .value input[type=text] {
  padding: 0.15em 0.5em;
  line-height: 1.7em;
}
.NumericInput .value input[type=text]:blur {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.NumericInput .value .label {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0 3px hsla(207, 100%, 55%, 0);
  transition: box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.NumericInput .value .label .caption,
.NumericInput .value .label .placeholder {
  box-sizing: border-box;
  padding: 0 0.5em;
  flex-grow: 1;
  height: inherit;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.9em;
}
.NumericInput .value .label .unit {
  margin-left: 0.05em;
  color: #7f7f7f;
}
.NumericInput .value .label .placeholder {
  color: #a8a8a8;
}
.NumericInput .value .label .icon-arrow-light-left,
.NumericInput .value .label .icon-arrow-light-right {
  cursor: default;
  font-size: 0.75em;
  height: inherit;
  line-height: 2.4em;
  color: #cacaca;
  transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.NumericInput .value .label .icon-arrow-light-left.icon-arrow-light-left,
.NumericInput .value .label .icon-arrow-light-right.icon-arrow-light-left {
  padding-left: 2px;
}
.NumericInput .value .label .icon-arrow-light-left.icon-arrow-light-right,
.NumericInput .value .label .icon-arrow-light-right.icon-arrow-light-right {
  padding-right: 2px;
}
.NumericInput .value .label .icon-arrow-light-left.disabled,
.NumericInput .value .label .icon-arrow-light-right.disabled {
  opacity: 0.4;
}
.NumericInput .value .label .icon-arrow-light-left:not(.disabled):hover,
.NumericInput .value .label .icon-arrow-light-right:not(.disabled):hover {
  color: #7f7f7f;
}
.NumericInput .value .label .icon-arrow-light-left:not(.disabled):active,
.NumericInput .value .label .icon-arrow-light-right:not(.disabled):active {
  color: #0784fa;
}
.NumericInput .value .editable {
  width: inherit;
}
.NumericInput.scrubbing > .value > .label {
  border-color: #005cc5;
  box-shadow: 0 0 0 3px #0784fa;
}
.NumericInput.scrubbing > .value > .label .icon-arrow-light-left,
.NumericInput.scrubbing > .value > .label .icon-arrow-light-right {
  color: #7f7f7f;
}
.NumericInput.previewing-value > .value > .label {
  color: #FF0070;
}
.NumericInput:not(.disabled) > .caption {
  cursor: pointer;
}
.NumericInput:not(.disabled) > .caption:hover,
.NumericInput:not(.disabled) > .caption:active {
  color: #005cc5;
  padding-left: 4px;
}
.NumericInput:not(.disabled) > .caption:hover {
  background: rgba(0, 0, 0, 0.02);
}
.NumericInput:not(.disabled) > .caption:active {
  background: rgba(0, 0, 0, 0.04);
}
.NumericInput:not(.disabled) > .value > .label {
  cursor: ew-resize;
}
.NumericInput:not(.disabled) > .value > .label .caption:hover,
.NumericInput:not(.disabled) > .value > .label .placeholder:hover {
  background: rgba(30, 30, 30, 0.03);
}
.NumericInput:not(.disabled) .caption,
.NumericInput:not(.disabled) .placeholder {
  cursor: ew-resize;
}
.NumericInput:not(.disabled):active > .value > .label {
  background: #fff;
}
.NumericInput:not(.disabled).pulse {
  z-index: 1;
}
.NumericInput:not(.disabled).pulse .label {
  animation: focus-ring-pulse 3s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}
.NumericInput.disabled > .value > .label {
  background-color: #fcfcfc;
}
.NumericInput.disabled > .caption,
.NumericInput.disabled > .value > .label {
  color: #979797;
}
.NumericInput.loading > .value {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
  transform-origin: center center;
}
.NumericInput.loading > .value .label,
.NumericInput.loading > .value input[type=text] {
  border-color: black;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .NumericInput .label,
  .NumericInput input[type=text] {
    border: 0.5px solid hsla(0, 0%, 50%, 0.2);
    border-bottom-color: hsla(0, 0%, 50%, 0.3);
  }
}
.MapPicker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 6px;
  background: white;
  border: 1px solid hsl(0, 0%, 85%);
}
.MapPicker > .map {
  position: relative;
  width: inherit;
  height: 158px;
  background: url("/assets/world-map@2x.png") center top no-repeat;
  background-size: contain;
}
.MapPicker > .map > .MapLocation {
  --size: 18px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  margin-left: calc(var(--size) / -2);
  margin-top: calc(var(--size) / -2);
  border-radius: var(--size);
  border: 1px solid;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
.MapPicker > .map > .MapLocation:not(.selected) {
  cursor: pointer;
  background: white;
  border-color: #0784fa;
  box-shadow: 0 0 0 #0784fa, 0 6px 6px hsla(0, 0%, 60%, 0.35);
}
.MapPicker > .map > .MapLocation:not(.selected):hover {
  box-shadow: 0 0 0 1px #0784fa, 0 6px 6px hsla(0, 0%, 60%, 0.35);
}
.MapPicker > .map > .MapLocation:not(.selected):active {
  background-color: hsl(0, 0%, 94%);
}
.MapPicker > .map > .MapLocation.selected {
  background: #0784fa;
  border-color: white;
  box-shadow: 0 6px 6px hsla(200, 100%, 37%, 0.3);
}
.MapPicker > .map > .MapLocation.selected::before,
.MapPicker > .map > .MapLocation.selected::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: #0784fa;
  border-radius: inherit;
  opacity: 0.5;
  transform: scale(2);
  animation: map-location-pulse 4.2s cubic-bezier(0.19, 1, 0.22, 1) infinite backwards 0.6s;
}
.MapPicker > .map > .MapLocation.selected::after {
  animation-name: map-location-pulse-alt;
}
.MapPicker > .label {
  padding: 18px;
  font-size: 16px;
  text-align: center;
}
.MapPicker > .label.placeholder {
  color: #464646;
}
.MapPicker > .help {
  cursor: help;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.MapPicker > .help > .icon {
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 92%);
  border-radius: 1em;
  font-size: 15px;
  padding: 4px;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: color, background-color;
}
.MapPicker > .help > .tooltip {
  pointer-events: none;
  position: absolute;
  right: 30px;
  bottom: -20px;
  width: 190px;
  padding: 9px 13px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px -3px hsla(0, 0%, 0%, 0.15), 0 8px 28px -2px hsla(0, 0%, 0%, 0.15);
  color: hsl(0, 0%, 30%);
  font-size: 14px;
  opacity: 0;
  transform: scale(0.5);
  transform-origin: right center;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: opacity, transform;
}
.MapPicker > .help:hover > .tooltip {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.1s;
}
.MapPicker > .help:hover > .icon {
  color: hsl(0, 0%, 85%);
  background-color: hsl(0, 0%, 20%);
  transition-delay: 0.1s;
}
.MapPicker.error > .label {
  color: #d44944;
}
@keyframes map-location-pulse {
  from {
    opacity: 0.4;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(5);
  }
}
@keyframes map-location-pulse-alt {
  from,
  10% {
    opacity: 0.4;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(5);
  }
}
.OptionSwitcher > .switcher {
  display: inline-block;
  cursor: default;
  border-radius: 100vw;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  padding: 1px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.05);
}
.OptionSwitcher .option {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 100vw;
  color: #444;
  font-size: 14px;
  line-height: 1em;
}
.OptionSwitcher .option.selected {
  color: white;
  background: #444;
}
.OptionSwitcher .option:not(.selected) {
  cursor: pointer;
}
.OptionSwitcher.loading .option {
  pointer-events: none;
}
.OptionSwitcher.loading .option.selected {
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.OptionSwitcher.disabled .option {
  cursor: not-allowed;
  color: hsl(0, 0%, 60%);
}
.OptionSwitcher.disabled .option.selected {
  color: hsl(0, 0%, 90%);
  background: hsl(0, 0%, 50%);
}
.PageSwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  height: 32px;
  flex-shrink: 0;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  box-sizing: border-box;
}
.PageSwitcher > a {
  position: relative;
  display: block;
  height: inherit;
  box-sizing: border-box;
  color: #8C8C8C;
  padding: 7px 15px 3px;
  font-size: 14px;
  transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.PageSwitcher > a::after {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -0.5px;
  display: block;
  height: 1px;
  background: rgba(17, 17, 17, 0);
  transition: inherit;
  transition-property: left, right, background-color;
}
.PageSwitcher > a:hover {
  color: #666;
}
.PageSwitcher > a.selected,
.PageSwitcher > a:active {
  color: #111;
  transition-duration: 0.2s;
}
.PageSwitcher > a.selected::after,
.PageSwitcher > a:active::after {
  left: 15px;
  right: 15px;
  background-color: #111;
}
.ModalDialog {
  pointer-events: none;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  position: fixed;
  z-index: 5600;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ModalDialog > .backdrop {
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(190, 190, 190, 0.97);
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.ModalDialog > .backdrop:active {
  background-color: rgba(180, 180, 180, 0.95);
}
.ModalDialog > .dialog {
  pointer-events: all;
  position: relative;
  z-index: 1;
  padding: 25px 40px;
  background: hsl(0, 0%, 97%);
  border-radius: 6px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
  animation: zoom-in 0.4s cubic-bezier(0.17, 1.58, 0.22, 0.99);
}
.ModalDialog > .dialog button.button-link {
  color: #0784fa;
  margin-left: 5px;
  margin-right: 5px;
}
.ModalDialog > .dialog > .overlay-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background: inherit;
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1);
  color: #222;
}
.ModalDialog > .dialog > .overlay-message > .title,
.ModalDialog > .dialog > .overlay-message > .message,
.ModalDialog > .dialog > .overlay-message > .actions {
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1) 1s backwards;
}
.ModalDialog > .dialog > .overlay-message > .title {
  font-size: 24px;
  margin-bottom: 0.75em;
}
.ModalDialog > .dialog > .overlay-message > .message {
  font-size: 16px;
  padding: 0 20px;
  text-align: center;
  margin-bottom: 0;
}
.ModalDialog > .dialog > .overlay-message > .actions {
  margin-bottom: 0;
}
.ModalDialog > .dialog .title {
  max-width: 400px;
  margin: 0 auto 18px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}
.ModalDialog > .dialog .subtitle {
  max-width: 400px;
  margin: 32px auto 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.ModalDialog > .dialog .message {
  padding: 0 10px;
  margin: 0 auto 25px;
  box-sizing: border-box;
  max-width: 400px;
  font-size: 15px;
  text-align: center;
  line-height: 1.5em;
}
.ModalDialog > .dialog .facts {
  max-width: 400px;
  margin: 30px auto;
}
.ModalDialog > .dialog .facts > .fact {
  margin-top: 8px;
  padding: 15px 20px;
  border-radius: 6px;
  background: white;
  font-size: 15px;
  line-height: 1.2em;
}
.ModalDialog > .dialog .form {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ModalDialog > .dialog .form input[disabled] {
  border: 1px solid hsl(0, 0%, 85%);
}
.ModalDialog > .dialog .form .DropDown .value {
  font-size: 18px;
}
.ModalDialog > .dialog .message + .form {
  margin-top: 0;
}
.ModalDialog > .dialog .draft-validation-warning {
  margin: 20px auto 0;
  width: 300px;
  text-align: left;
}
.ModalDialog > .dialog .draft-validation-warning > .validation-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  list-style: none;
  padding: 10px 5px;
  margin: 2px 0 0 0;
  background-color: white;
  border-radius: 6px;
}
.ModalDialog > .dialog .draft-validation-warning > .validation-item.warning {
  color: #eca200;
}
.ModalDialog > .dialog .draft-validation-warning > .validation-item.error {
  color: #db0e11;
}
.ModalDialog > .dialog .draft-validation-warning > .validation-item > .icon {
  padding: 0 10px 0 8px;
  font-size: 19px;
}
.ModalDialog > .dialog .draft-validation-warning > .validation-item > .caption {
  line-height: 1.1em;
}
.ModalDialog > .dialog .actions {
  position: relative;
  height: 37px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ModalDialog > .dialog .actions .action {
  cursor: pointer;
  display: inline-block;
  width: auto;
  border: none;
  background: transparent;
  color: hsl(0, 0%, 40%);
  font-size: 17px;
  font-weight: normal;
  padding: 0.5em 1em;
}
.ModalDialog > .dialog .actions .action:active {
  color: hsl(0, 0%, 30%);
}
.ModalDialog > .dialog .actions .action[disabled] {
  cursor: not-allowed;
  color: hsl(0, 0%, 70%) !important;
}
.ModalDialog > .dialog .actions .action[disabled]:active {
  color: hsl(0, 0%, 60%);
}
.ModalDialog > .dialog .actions .action.highlighted {
  color: #0784fa;
}
.ModalDialog > .dialog .actions .action.highlighted:active {
  color: #005cc5;
}
.ModalDialog > .dialog .actions .action.highlighted.danger {
  color: #db0e11;
}
.ModalDialog > .dialog .actions .action.highlighted.danger:active {
  color: #be0a0d;
}
.ModalDialog.closed {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.ModalDialog.closed > .dialog {
  transform: scale(0.95);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes stamp-scale-in {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  5% {
    transform: scale(0.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
  }
}
@keyframes stamp-stroke-in {
  from {
    box-shadow: inset 0 0 0 17px #2ebb18;
  }
  50% {
    box-shadow: inset 0 0 0 17px #2ebb18;
  }
  to {
    box-shadow: inset 0 0 0 1.5px #2ebb18;
  }
}
.SearchInput {
  position: relative;
  width: 300px;
  font-size: 13px;
}
.SearchInput > .search-bar {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: inherit;
  height: 25px;
  border-radius: 6px;
  padding: 0 25px 0 28px;
  box-sizing: border-box;
}
.SearchInput > .search-bar > input {
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  padding: 2px 0;
  outline: none;
  color: inherit;
  font-size: inherit;
  flex-grow: 1;
}
.SearchInput > .search-bar > .icon {
  position: absolute;
  left: 7px;
  top: 5px;
  font-size: 14px;
}
.SearchInput > .search-bar > .clear-button {
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: transparent;
  border: none;
  padding: 4px;
  transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.SearchInput > .search-bar > .tag {
  position: relative;
  border-radius: 4px;
  background: hsla(0, 0%, 100%, 0.1);
  border: 1px solid hsla(0, 0%, 100%, 0.12);
  margin-right: 5px;
  white-space: nowrap;
  padding: 1px 6px;
}
.SearchInput > .search-bar > .tag::before {
  content: "#";
  position: relative;
  color: #7f7f7f;
  margin-right: 5px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.SearchInput > .search-bar > .tag > .value {
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.SearchInput > .search-bar > .tag > .icon {
  position: absolute;
  left: 5px;
  top: 3.5px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  font-size: 9px;
  transform: scale(0.6);
}
.SearchInput > .search-bar > .tag:hover {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  border-color: hsla(0, 0%, 100%, 0.3);
}
.SearchInput > .search-bar > .tag:hover > .value {
  color: #999;
}
.SearchInput > .search-bar > .tag:hover > .icon {
  opacity: 1;
  transform: scale(1);
}
.SearchInput > .search-bar > .tag:hover::before {
  opacity: 0;
  transform: translateX(-6px);
}
.SearchInput > .search-bar > .tag:active {
  cursor: pointer;
  background-color: hsla(0, 0%, 0%, 0.6);
}
.SearchInput > .search-bar.with-value > .icon {
  color: #0784fa !important;
}
.SearchInput > .search-bar.selected-tag-for-removal > .tag {
  border-color: #0784fa;
}
.SearchInput > .search-bar.selected-tag-for-removal > .tag > .value {
  color: #0784fa;
}
.SearchInput > .search-bar.selected-tag-for-removal > .tag::before {
  color: hsla(207, 100%, 55%, 0.7);
}
.SearchInput.with-dropdown {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
}
.SearchInput.with-dropdown > .search-bar {
  width: 300px;
}
.SearchInput > .DropDown {
  height: 25px;
  width: 130px;
  margin-left: 5px;
}
.SearchInput > .DropDown > .container {
  height: inherit;
}
.SearchInput > .DropDown .value {
  font-size: 13px;
  height: inherit;
  font-weight: normal;
}
.SearchInput > .DropDown .value::after {
  right: 6px;
  font-size: 12px;
}
.SearchInput > .DropDown select {
  height: inherit;
}
.SearchInput > .tags-dropdown {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  right: 0;
  top: 28px;
  padding: 6px 18px 12px;
  background: #272727;
  border: 1px solid #4D4D4D;
  border-radius: 6px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
  transform: scale(0.98);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.SearchInput > .tags-dropdown > .tag {
  cursor: pointer;
  padding: 2px 4px;
  color: hsl(0, 0%, 80%);
  margin-top: 6px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.SearchInput > .tags-dropdown > .tag:not(:last-child) {
  margin-right: 12px;
}
.SearchInput > .tags-dropdown > .tag::before {
  content: "#";
  display: inline;
  color: #7f7f7f;
  margin-right: 2px;
}
.SearchInput > .tags-dropdown > .tag:hover {
  border-color: hsl(0, 0%, 25%);
}
.SearchInput > .tags-dropdown > .tag:active {
  border-color: hsl(0, 0%, 35%);
  background: black;
}
.SearchInput.opened > .tags-dropdown {
  pointer-events: all;
  transform: scale(1);
  opacity: 1;
}
.SearchInput.opened > .tags-dropdown > .tag.active {
  border-color: #0784fa;
  color: #0784fa;
}
.SearchInput.opened > .tags-dropdown > .tag.active::before {
  color: hsla(207, 100%, 55%, 0.7);
}
.SearchInput > .search-bar {
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  color: hsla(0, 0%, 0%, 0.8);
}
.SearchInput > .search-bar:not(.focus):hover {
  cursor: pointer;
  border-color: hsla(0, 0%, 0%, 0.22);
  background-color: hsla(0, 0%, 0%, 0.02);
}
.SearchInput > .search-bar.focus {
  border-color: hsla(0, 0%, 0%, 0.3);
}
.SearchInput > .search-bar > input::placeholder {
  color: hsla(0, 0%, 0%, 0.4);
}
.SearchInput > .search-bar > .icon {
  color: hsla(0, 0%, 0%, 0.3);
}
.SearchInput > .search-bar > .clear-button {
  color: hsla(0, 0%, 0%, 0.5);
}
.SearchInput > .search-bar > .clear-button:hover {
  color: hsla(0, 0%, 0%, 0.7);
}
.Header .SearchInput > .search-bar {
  border-radius: 4px;
  border: none;
  background: hsla(190, 3%, 38%, 0.75);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  color: rgba(255, 255, 255, 0.9);
}
.Header .SearchInput > .search-bar:not(.focus):not(:active):hover {
  cursor: pointer;
  border-color: hsla(0, 0%, 100%, 0.4);
  background-color: hsla(0, 0%, 100%, 0.15);
}
.Header .SearchInput > .search-bar > input::placeholder {
  color: hsla(0, 0%, 100%, 0.55);
}
.Header .SearchInput > .search-bar > .icon {
  color: hsla(0, 0%, 100%, 0.5);
}
.Header .SearchInput > .search-bar > .clear-button {
  color: hsla(0, 0%, 100%, 0.7);
}
.Header .SearchInput > .search-bar > .clear-button:hover {
  color: hsla(0, 0%, 100%, 0.8);
}
.Header .SearchInput.opened > .search-bar,
.Header .SearchInput > .search-bar.focus,
.Header .SearchInput > .search-bar:active {
  background-color: hsla(220, 5%, 11%, 0.5);
  box-shadow: 0 0 0 2px #0784fa, inset 0 1px 1px rgba(0, 0, 0, 0.15);
  border-color: hsla(0, 0%, 100%, 0.5);
  color: white;
}
.TextInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 26px;
  font-size: inherit;
}
.TextInput.loading > .value {
  animation: gui-standby-loading 1.1s ease-in-out infinite;
  pointer-events: none;
}
.TextInput .caption {
  flex-grow: 1;
  height: 24px;
  margin-right: 4px;
  border-radius: 0.25em;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1), padding 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: inherit;
}
.TextInput .value {
  position: relative;
  width: 120px;
  height: inherit;
}
.TextInput .value > input {
  display: block;
  box-sizing: border-box;
  width: inherit;
  height: inherit;
  padding: 0.15em 0.35em;
  margin: 0;
  border: 1px solid hsla(0, 0%, 50%, 0.12);
  border-bottom-color: hsla(0, 0%, 50%, 0.19);
  border-radius: 0.25em;
  font: inherit;
  transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: width, margin-left;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .TextInput .value > input {
    border: 0.5px solid hsla(0, 0%, 50%, 0.2);
    border-bottom-color: hsla(0, 0%, 50%, 0.3);
  }
}
.TextInput .value > input::placeholder {
  color: #a8a8a8;
}
.TextInput .value > input::-moz-placeholder {
  color: #a8a8a8;
}
.TextInput .value > input::-webkit-input-placeholder {
  color: #a8a8a8;
}
.TextInput .value > .action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  background: #fff;
  font-size: 12px;
  cursor: pointer;
}
.TextInput .value > .action.clear {
  color: #cacaca;
}
.TextInput .value > .action.clear:hover,
.TextInput .value > .action.clear:active {
  color: #7f7f7f;
}
.TextInput .value > .action:active {
  color: #0784fa;
}
.TextInput:not(.disabled) > .caption {
  cursor: pointer;
}
.TextInput:not(.disabled) > .caption:hover,
.TextInput:not(.disabled) > .caption:active {
  color: #005cc5;
  padding-left: 4px;
}
.TextInput:not(.disabled) > .caption:hover {
  background: rgba(0, 0, 0, 0.02);
}
.TextInput:not(.disabled) > .caption:active {
  background: rgba(0, 0, 0, 0.04);
}
.TextInput.disabled > .caption {
  pointer-events: none;
}
.TextInput.disabled > .caption,
.TextInput.disabled input {
  color: #979797;
}
.TextInput.disabled input {
  background-color: #fcfcfc;
}
.TextInput.auto-expand.editing .caption {
  pointer-events: none;
  opacity: 0;
}
.TextInput.auto-expand.editing .value > input:focus {
  width: 258px;
  margin-left: -138px;
  text-align: center;
}
.TextInput > .suggestions {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
  margin: 5px;
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 8px 22px rgba(0, 0, 0, 0.2), inset 0 1px 0 1px rgba(255, 255, 255, 0.25);
  animation: slight-zoom-in 0.1s cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  display: block;
  left: -6px;
  top: 28px;
  z-index: 10;
  max-height: 40vh;
  overflow-y: auto;
}
.TextInput > .suggestions > .item {
  cursor: default;
  position: relative;
  padding: 3px 30px 3px 11px;
  color: #141414;
  text-align: left;
  white-space: nowrap;
  max-width: 220px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.TextInput > .suggestions > .item.checked::after {
  content: "\e90c";
  font-family: 'seatsdesigner';
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -0.7em;
  right: 6px;
  color: #7f7f7f;
}
.TextInput > .suggestions > .item:hover:not(:disabled),
.TextInput > .suggestions > .item.selected:not(:disabled) {
  color: #fff;
  background: #0784fa;
}
.TextInput > .suggestions > .item:hover:not(:disabled).checked::after,
.TextInput > .suggestions > .item.selected:not(:disabled).checked::after {
  color: rgba(255, 255, 255, 0.5);
}
.TextInput > .suggestions > .item:disabled {
  color: #979797;
}
.Tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.Tags .tag {
  cursor: pointer;
  margin: 2px 10px 2px 0;
  position: relative;
  border: 3px solid transparent;
  padding: 0 2px 0 12px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  white-space: nowrap;
}
.Tags .tag.loading {
  pointer-events: none;
  animation: gui-standby-loading-out 1s ease-in-out infinite;
  opacity: 0.6;
}
.Tags .tag::before {
  content: "#";
  color: #7f7f7f;
  position: absolute;
  left: 1px;
  transform: translateX(0) rotate(0);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.Tags .tag > .caption {
  display: inline-block;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.Tags .tag > .icon {
  position: absolute;
  left: 0;
  top: 3px;
  color: #db0e11;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 12px;
  transform: translateX(-2px) rotate(-45deg);
}
.Tags .tag:hover > .caption {
  transform: translateX(3px);
  text-decoration: line-through;
}
.Tags .tag:hover > .icon {
  opacity: 1;
  transform: translateX(0) rotate(0deg);
}
.Tags .tag:hover::before {
  opacity: 0;
  transform: translateX(2px) rotate(45deg);
}
.Tags .tag:active {
  opacity: 0.5;
}
.Tags .button {
  cursor: pointer;
  color: #0784fa;
  margin: 2px 0 2px;
  border: 3px solid transparent;
}
.Tags .button > .icon {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-right: 5px;
}
.Tags .button:hover > .icon,
.Tags .button:hover > .caption {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Tags .button:active > .icon,
.Tags .button:active > .caption {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Tags .TextInput.loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.Tags .TextInput .value {
  position: relative;
  top: 2px;
}
.Tags .TextInput .value::before {
  display: block;
  content: "#";
  position: absolute;
  left: 3px;
  top: 1.5px;
  z-index: 1;
  color: #7f7f7f;
}
.Tags .TextInput .value input {
  position: relative;
  outline: none;
  width: 100px;
  padding: 1px 3px 2px 14px;
  height: 1.4em;
  border: 0;
  border-radius: 5px;
  margin: 2px 10px 2px 0;
  font-size: inherit;
  box-shadow: 0 0 0 3px hsla(207, 100%, 55%, 0.3);
  animation: grow-from-width-0 0.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  transition: box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  background: transparent;
}
.Tags .TextInput .value input.invalid-input {
  box-shadow: 0 0 0 3px #db0e11;
}
@keyframes grow-from-width-0 {
  from {
    width: 0px;
  }
}
.Tags .TextInput .value .floating-error {
  position: fixed;
  margin-top: 3px;
  pointer-events: none;
  background-color: #db0e11;
  border: 0;
  padding: 2px 12px 3px;
  border-radius: 1em;
  color: white;
  font-size: 12px;
  transform-origin: left top;
  animation: slight-zoom-in 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.WhitelistedDomains {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.WhitelistedDomains .domain {
  cursor: pointer;
  margin: 2px 10px 2px 0;
  position: relative;
  border: 3px solid transparent;
  padding: 0 2px 0 12px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  white-space: nowrap;
}
.WhitelistedDomains .domain.loading {
  pointer-events: none;
  animation: gui-standby-loading-out 1s ease-in-out infinite;
  opacity: 0.6;
}
.WhitelistedDomains .domain::before {
  color: #7f7f7f;
  position: absolute;
  left: 1px;
  transform: translateX(0) rotate(0);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.WhitelistedDomains .domain > .caption {
  display: inline-block;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.WhitelistedDomains .domain > .icon {
  position: absolute;
  left: 0;
  top: 3px;
  color: #db0e11;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 12px;
  transform: translateX(-2px) rotate(-45deg);
}
.WhitelistedDomains .domain:hover > .caption {
  transform: translateX(3px);
  text-decoration: line-through;
}
.WhitelistedDomains .domain:hover > .icon {
  opacity: 1;
  transform: translateX(0) rotate(0deg);
}
.WhitelistedDomains .domain:hover::before {
  opacity: 0;
  transform: translateX(2px) rotate(45deg);
}
.WhitelistedDomains .domain:active {
  opacity: 0.5;
}
.WhitelistedDomains .button {
  cursor: pointer;
  color: #0784fa;
  margin: 2px 0 2px;
  border: 3px solid transparent;
}
.WhitelistedDomains .button > .icon {
  position: relative;
  top: 1px;
  font-size: 14px;
  margin-right: 5px;
}
.WhitelistedDomains .button:hover > .icon,
.WhitelistedDomains .button:hover > .caption {
  background: linear-gradient(to top, #19b6ff 10%, #005eff);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.WhitelistedDomains .button:active > .icon,
.WhitelistedDomains .button:active > .caption {
  background: linear-gradient(to top, #39c0ff, #005cc5);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.WhitelistedDomains .TextInput.loading {
  pointer-events: none;
  animation: gui-standby-loading 1.1s ease-in-out infinite;
}
.WhitelistedDomains .TextInput .value {
  position: relative;
  top: 2px;
}
.WhitelistedDomains .TextInput .value input {
  position: relative;
  outline: none;
  width: 150px;
  padding: 1px 3px 2px 10px;
  height: 1.4em;
  border: 0;
  border-radius: 5px;
  margin: 2px 10px 2px 0;
  font-size: inherit;
  box-shadow: 0 0 0 3px hsla(207, 100%, 55%, 0.3);
  animation: grow-from-width-0 0.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  transition: box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  background: transparent;
}
.WhitelistedDomains .TextInput .value input.invalid-input {
  box-shadow: 0 0 0 3px #db0e11;
}
@keyframes grow-from-width-0 {
  from {
    width: 0px;
  }
}
.WhitelistedDomains .TextInput .value .floating-error {
  position: fixed;
  margin-top: 3px;
  pointer-events: none;
  background-color: #db0e11;
  border: 0;
  padding: 2px 12px 3px;
  border-radius: 1em;
  color: white;
  font-size: 12px;
  transform-origin: left top;
  animation: slight-zoom-in 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.Thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 160px;
  margin-left: 20px;
  margin-right: 30px;
  padding: 10px;
  flex-shrink: 0;
}
.Thumbnail > img {
  max-width: inherit;
  max-height: inherit;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), filter 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.Thumbnail.with-options > .options {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.Thumbnail.with-options > .options > button,
.Thumbnail.with-options > .options > a {
  cursor: pointer;
  display: block;
  border: none;
  width: 100%;
  max-width: 160px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: brightness(1.03);
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 11, 31, 0.08);
  padding: 8px 8px 9px;
  color: black;
  text-align: center;
  font-size: 14px;
  transform: scale(0.5);
  opacity: 0;
  transition: 0.5s linear;
  transition-property: transform, opacity;
}
.Thumbnail.with-options > .options > button:hover,
.Thumbnail.with-options > .options > a:hover {
  background: rgba(255, 255, 255, 0.4);
}
.Thumbnail.with-options > .options > button:active,
.Thumbnail.with-options > .options > a:active {
  background: rgba(241, 241, 241, 0.6);
  box-shadow: 0 1px 10px rgba(0, 11, 31, 0.16);
}
.Thumbnail.with-options:hover > img {
  transform: scale(0.95) translate3d(0, 0, 0);
  filter: blur(10px) opacity(0.8);
}
.Thumbnail.with-options:hover > .options {
  opacity: 1;
}
.Thumbnail.with-options:hover > .options > button,
.Thumbnail.with-options:hover > .options > a {
  opacity: 1;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.Thumbnail.with-options:hover > .options > button:nth-child(2),
.Thumbnail.with-options:hover > .options > a:nth-child(2) {
  transition-delay: 0.1s;
}
.Thumbnail.with-options:hover > .options > button:nth-child(3),
.Thumbnail.with-options:hover > .options > a:nth-child(3) {
  transition-delay: 0.2s;
}
.link-list {
  width: 400px;
}
.link-list > a {
  display: block;
  padding: 7px 12px;
  border: 1px solid hsl(0, 0%, 91%);
  border-radius: 5px;
  transition: background-color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.link-list > a:not(:first-child) {
  margin-top: 5px;
}
.link-list > a:hover {
  background-color: hsl(0, 0%, 98%);
}
.link-list > a:active {
  background-color: hsl(0, 0%, 96%);
}
.link-list.wide {
  position: relative;
  left: -30px;
  width: calc(100% + 30px * 2);
}
.link-list.wide > a {
  padding: 15px 30px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  font-size: 17px;
}
.link-list.wide > a:not(:first-child) {
  margin-top: 0;
  border-top: none;
}
.waiting-indicator .LoadingIndicator {
  position: relative;
  top: -6px;
  animation: fade-in 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.waiting-indicator .done-indicator {
  position: absolute;
  left: 0;
  right: 0;
  top: -2px;
  overflow: visible;
  visibility: hidden;
}
.waiting-indicator .done-indicator > .stamp {
  position: relative;
  width: 28px;
  height: 28px;
  margin: 0 auto;
}
.waiting-indicator .done-indicator > .stamp .stroke {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  box-shadow: inset 0 0 0 1.5px #2ebb18;
}
.waiting-indicator .done-indicator > .stamp .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  color: #2ebb18;
}
.waiting-indicator .done-indicator > .message {
  margin-top: 0.25em;
  color: #7f7f7f;
  font-size: 13px;
  text-align: center;
}
.waiting-indicator.done .LoadingIndicator {
  width: 16px;
  transform: scale(0.3);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.waiting-indicator.done .done-indicator {
  visibility: visible;
}
.waiting-indicator.done .done-indicator > .stamp {
  animation: stamp-scale-in 0.45s cubic-bezier(0.44, 2.16, 0.47, 0.74) 0.2s backwards;
}
.waiting-indicator.done .done-indicator > .stamp > .stroke {
  animation: stamp-stroke-in 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s backwards;
}
.waiting-indicator.done .done-indicator > .stamp > .icon {
  animation: fade-in 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.2s backwards;
}
.waiting-indicator.done .done-indicator > .message {
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1) 0.8s backwards;
}
.OccupancyProgressBar {
  cursor: default;
  user-select: none;
  width: 100%;
}
.OccupancyProgressBar .not-for-sale {
  background: hsl(219, 13%, 62%);
  box-shadow: inset 0 0 0 1px hsl(219, 13%, 48%), 0 5px 10px hsla(219, 32%, 41%, 0.22);
  text-shadow: 0 1px 1px hsl(219, 13%, 48%);
}
.OccupancyProgressBar .booked {
  background: hsl(211, 100%, 50%);
  box-shadow: inset 0 0 0 1px hsl(224, 79%, 49%), 0 5px 10px hsla(211, 100%, 36%, 0.26);
  text-shadow: 0 1px 1px hsl(224, 79%, 49%);
}
.OccupancyProgressBar .custom-status {
  background: hsl(333, 69%, 64%);
  box-shadow: inset -1px 0 0 1px hsl(336, 73%, 51%), 0 5px 10px hsla(336, 100%, 31%, 0.27);
  text-shadow: 0 1px 1px hsl(336, 65%, 51%);
}
.OccupancyProgressBar .temporarily-held {
  background: hsl(35, 93%, 64%);
  box-shadow: inset 0 0 0 1px hsl(25, 77%, 60%), 0 5px 10px hsla(28, 100%, 45%, 0.35);
  text-shadow: 0 1px 1px hsl(25, 77%, 60%);
}
.OccupancyProgressBar .disabled-by-rules {
  background: url("/assets/stripes-green.png");
  background-color: hsl(159, 52%, 57%);
  background-size: 11px;
  box-shadow: inset 0 0 0 1px hsl(135, 45%, 48%), 0 5px 10px hsla(135, 100%, 31%, 0.25);
  text-shadow: 0 1px 1px hsl(135, 45%, 48%);
}
.OccupancyProgressBar .free {
  background: hsl(0, 0%, 96%);
  box-shadow: inset 0 0 0 1px hsl(0, 0%, 88%);
}
.OccupancyProgressBar > .top-labels {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 6px;
  color: hsl(0, 0%, 50%);
}
.OccupancyProgressBar > .bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 22px;
  border-radius: 4px;
  background: hsl(0, 0%, 96%);
  box-shadow: inset 0 0 0 1px hsl(0, 0%, 88%);
}
.OccupancyProgressBar > .bar > .progress {
  height: inherit;
  min-width: 1px;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1), width 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}
.OccupancyProgressBar > .bar > .progress:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.OccupancyProgressBar > .bar > .progress:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.OccupancyProgressBar > .bar > .progress.highlighted {
  transform: scaleY(1.15);
  border-radius: 2px;
  filter: contrast(1.03) brightness(1.03);
}
.OccupancyProgressBar > .legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2px;
  font-size: 14px;
}
.OccupancyProgressBar > .legend > .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  border-radius: 10px;
  padding: 2px 12px 2px 6px;
  transition: 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: color, background-color, box-shadow;
}
.OccupancyProgressBar > .legend > .item:not(:last-child) {
  margin-right: 10px;
}
.OccupancyProgressBar > .legend > .item > .color {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 6px;
}
.OccupancyProgressBar > .legend > .item > .label {
  position: relative;
  top: -1px;
  font-weight: 500;
}
.OccupancyProgressBar > .legend > .item.highlighted {
  color: white;
}
.OccupancyProgressBar > .legend > .item.highlighted > .color {
  background: white;
  box-shadow: none;
}
.Reports {
  width: 100vw;
  padding-bottom: 10px;
}
.Reports > .container {
  max-width: 1040px;
  min-height: 30vh;
  margin-bottom: 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
}
.Reports > .container > .title {
  font-size: 26px;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 20px;
}
.Reports > .container > .title:first-child {
  margin-top: 10px;
}
.Reports > .container > .title > .soft {
  margin-top: 2px;
  font-size: 50%;
  color: hsl(201, 8%, 65%);
}
.Reports > .container > .time-controls {
  margin: 20px 0 50px;
}
.Reports > .container > .time-controls > .DropDown {
  position: relative;
  width: 360px;
}
.Reports > .container > .time-controls > .OptionSwitcher {
  float: right;
  margin: 0;
}
.Reports > .container > .sub-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.Reports > .container > .loading-placeholder {
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Reports > .container > .loading-placeholder .fake-item {
  width: 30px;
  height: 200px;
  animation-name: fake-item-vertical-grow;
}
.Reports > .container > .usage-details {
  display: flex;
  flex-direction: row;
  min-height: 300px;
  height: 70vh;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.Reports > .container > .usage-details .NavigationList {
  width: 40vw;
  max-width: 340px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
}
.Reports > .container > .usage-details .usage-by-events {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 0 25px 0;
}
.Reports > .container > .usage-details .usage-by-events > .title {
  margin-left: 30px;
  font-size: 22px;
  font-weight: 500;
}
.Reports > .container > .usage-details .usage-by-events > .chart-key {
  margin-top: 15px;
  margin-left: 30px;
  font-size: 16px;
  font-weight: 500;
}
.Reports > .container > .usage-details .usage-by-events > .chart-key .fixed-width {
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  font-size: 13px;
  font-weight: normal;
}
.Reports > .container > .usage-details .usage-by-events > .total {
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 30px;
  font-size: 16px;
  font-weight: 500;
}
.Reports > .container > .summary {
  margin-bottom: 50px;
}
.Reports > .container > .summary > .title {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}
.Reports > .container > .summary > .title > .icon {
  margin: 0 10px;
  font-size: 0.7em;
  cursor: pointer;
}
.Reports > .container > .summary > .title > .icon.hidden {
  visibility: hidden;
}
.Reports > .container > .summary > .subTitle {
  margin-top: 2px;
  text-align: center;
  font-size: 0.8em;
}
.Reports > .container > .summary > .summaryItemContainer {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.Reports > .container > .summary > .summaryItemContainer > .summaryItem {
  margin: 0 30px;
  text-align: center;
  width: 220px;
}
.Reports > .container > .summary > .summaryItemContainer > .summaryItem > .title {
  font-size: 1.4em;
  margin-bottom: 5px;
}
.Reports > .container > .summary > .summaryItemContainer > .summaryItem > .number {
  font-size: 1.7em;
  font-weight: bold;
}
.Reports > .container > .summary > .noUsageReport {
  text-align: center;
  font-size: 1.5em;
  margin-top: 10px;
}
.Reports > .container > .details {
  overflow-y: auto;
}
.Reports > .container > .details .usage > .mainUsage {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 7px;
}
.Reports > .container > .details .usage .button-link {
  vertical-align: middle;
  color: #0784fa;
}
.Reports > .container > .details .usage .button-link:active {
  color: #005cc5;
}
.Reports > .container > .details .whitelabeled {
  text-align: center;
}
.Reports > .container > .billing .billing-item {
  margin-bottom: 5px;
}
.usage-urls-dialog {
  width: 50vw;
}
.usage-urls-dialog .message {
  margin: 0 !important;
  max-width: 100% !important;
}
.usage-urls-dialog .usage-report-modal-content {
  max-height: 70vh;
  display: block;
  box-sizing: border-box;
  overflow-x: hidden;
}
.usage-urls-dialog .usage-report-modal-content ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}
.usage-urls-dialog .usage-report-modal-content ul li {
  overflow: hidden;
  text-overflow: ellipsis;
}
.usage-urls-dialog .usage-report-modal-content table {
  width: 100%;
}
.ManageSubscription {
  width: 100vw;
  padding-bottom: 10px;
}
.ManageSubscription > .container {
  max-width: 1040px;
  min-height: 30vh;
  margin-bottom: 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
}
.ManageSubscription > .container > .title {
  font-size: 32px;
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 50px;
  text-align: center;
}
.ManageSubscription > .container > .title:first-child {
  margin-top: 10px;
}
.ManageSubscription > .container > .title > .soft {
  margin-top: 2px;
  font-size: 50%;
  color: hsl(201, 8%, 65%);
}
.ManageSubscription > .container > .subscription-facts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.ManageSubscription > .container > .subscription-facts > .fact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 50px 30px;
  width: 250px;
  min-height: 160px;
  text-align: center;
}
.ManageSubscription > .container > .subscription-facts > .fact:not(:first-child) {
  margin-left: 20px;
  border-left: 1px solid hsl(201, 14%, 92%);
}
.ManageSubscription > .container > .subscription-facts > .fact > .title {
  font-size: 18px;
  color: hsl(201, 8%, 65%);
  font-weight: bold;
}
.ManageSubscription > .container > .subscription-facts > .fact > .value {
  font-size: 32px;
  font-weight: bold;
}
.ManageSubscription > .container > .subscription-facts > .fact > .description {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.3em;
  color: hsl(201, 8%, 35%);
}
.ManageSubscription > .container > .subscription-facts > .fact > .action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-size: 13px;
}
.ManageSubscription > .container > .subscription-facts > .fact > .action > .hint {
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.2em;
  color: hsl(201, 8%, 65%);
}
.ManageSubscription > .container > .subscription-facts > .fact > .separator {
  height: 40px;
}
.ManageSubscription > .container > .loading-placeholder {
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ManageSubscription > .container > .loading-placeholder .fake-item {
  width: 30px;
  height: 200px;
  animation-name: fake-item-vertical-grow;
}
.ManageSubscription > .container > .links {
  text-align: center;
}
.usage-urls-dialog {
  width: 50vw;
}
.usage-urls-dialog .message {
  margin: 0 !important;
  max-width: 100% !important;
}
.usage-urls-dialog .usage-report-modal-content {
  max-height: 70vh;
  display: block;
  box-sizing: border-box;
  overflow-x: hidden;
}
.usage-urls-dialog .usage-report-modal-content ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}
.usage-urls-dialog .usage-report-modal-content ul li {
  overflow: hidden;
  text-overflow: ellipsis;
}
.usage-urls-dialog .usage-report-modal-content table {
  width: 100%;
}
.Companies {
  width: 100vw;
  padding-bottom: 10px;
}
.Companies > .container {
  max-width: 1040px;
  min-height: 30vh;
  margin-bottom: 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
}
.Companies > .loading-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1040px;
  margin: 10px auto 10px;
}
.Companies > .loading-placeholder > .fake-item {
  width: 18%;
}
.Companies .DataTable {
  overflow-y: auto;
}
.Companies .DataTable tr:last-child > td:first-child {
  border-bottom-left-radius: 6px;
}
.Companies .DataTable tr:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
.Companies .DataTable table tr.enter {
  animation-delay: 0.5s;
}
.Companies .company-filters {
  padding: 18px 0;
}
.Companies .company-filters .form-field {
  margin: 1em 0 2em 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
}
.Companies .company-filters .form-field .container {
  padding: 18px;
  margin: 18px 0;
  display: flex;
  align-items: flex-end;
}
.Companies .company-filters .form-field .inline-field {
  display: inline-block;
  margin-right: 1em;
  overflow: hidden;
}
.Companies .company-filters .form-field .inline-field label {
  display: block;
  padding-bottom: 5px;
}
.Companies .company-filters .form-field .inline-field .select,
.Companies .company-filters .form-field .inline-field input {
  max-width: 80px;
}
.Companies .company-filters .form-field input#startYear {
  border-color: #767676;
  border-radius: unset;
}
.Companies .company-filters .form-field select {
  padding: 8px;
}
.Companies .company-filters .form-field select#startMonth {
  width: 90px;
}
.Companies .company-filters .form-field .country-dropdown {
  max-width: 180px;
}
.Companies .company-filters .form-field .FormButton {
  height: 40px;
  padding: 10px;
}
.Company > .container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: white;
  min-height: 30vh;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.Company > .container .section {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.Company > .container .section.loading {
  animation: gui-standby-loading 1.1s ease-in-out infinite;
  pointer-events: none;
}
.Company > .container .section .title-wrapper {
  display: flex;
}
.Company > .container .section .title-wrapper .title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 10px;
}
.Company > .container .section .item {
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.Company > .container .section .item > .value {
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  padding: 8px 14px;
  border-radius: 6px;
  color: black;
  display: inline-block;
}
.Company > .container .section .item > .dropdown-value {
  padding: 8px 20px;
  display: inline-block;
}
.Company > .container .section .item.toggleable > .value {
  background-color: inherit;
  padding: 0;
  border: 0;
}
.Company > .container .comment .title {
  margin: 0;
}
.Company > .container .comment .comment-area {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  resize: none;
  border: none;
  border-radius: 6px;
  padding: 6px;
  margin: 10px 0;
  font-size: 14px;
  background-color: #f5f5f5;
}
.Company > .container .comment .comment-area.loading {
  pointer-events: none;
  animation: gui-standby-loading-large 1.1s ease-in-out infinite;
}
.Company > .container .comment .comment-area.success {
  animation: list-item-success-pulse 1.5s ease-in-out backwards;
}
.Company > .container .comment .comment-area.failed {
  animation: list-item-failed-pulse 1.5s ease-in-out backwards;
}
.Company > .container .comment .btn {
  margin: auto;
  display: block;
}
.Company > .container .user-controls .OptionSwitcher {
  margin: 10px;
}
.Company > .container .caption {
  flex-grow: initial;
  width: 50%;
  height: 24px;
  margin-right: 4px;
  border-radius: 0.25em;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
}
.Company > .container .item > .value {
  width: 50%;
  box-sizing: border-box;
}
.Company > .container .item > .value > input {
  width: 100%;
}
.Company > .container .TextInput {
  width: 100%;
}
.Company > .container .TextInput .DropDown .value {
  position: absolute !important;
  width: inherit;
}
.Company > .container .TextInput .value {
  width: 100%;
}
.Company > .container .DropDown .value {
  font-size: 17px;
}
.Company .subscription-events .DataTable {
  height: auto;
}
.Company .subscription-events .error {
  color: #db0e11;
  text-align: center;
  font-weight: bold;
}
.Company .subscription-events .add {
  float: right;
  margin-top: -52px;
}
.Company .no-events-message {
  font-size: 20px;
  text-align: center;
  margin: 1em;
}
.Users {
  width: 100vw;
  padding-bottom: 10px;
}
.Users > .container {
  max-width: 1040px;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 20px auto;
  background-color: white;
  padding-bottom: 20px;
}
.Users > .container > .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-left: 30px;
}
.Users > .loading-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1040px;
  margin: 10px auto 10px;
}
.Users > .loading-placeholder > .fake-item {
  width: 18%;
}
.Users .DataTable {
  overflow-y: auto;
}
.Users .DataTable th:nth-child(5),
.Users .DataTable th:nth-child(6),
.Users .DataTable td:nth-child(5),
.Users .DataTable td:nth-child(6) {
  text-align: center;
}
.Users .DataTable tr:last-child > td:first-child {
  border-bottom-left-radius: 6px;
}
.Users .DataTable tr:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
.Users .DataTable table tr.enter {
  animation-delay: 0.5s;
}
.Users .DataTable table tr .action-cell .icon {
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.Users .DataTable table tr .action-cell .icon:hover {
  opacity: 1;
  transition-delay: 0.1s;
}
.Users .DataTable table tr > td div.user-comment {
  max-height: 100px;
  overflow: scroll;
}
.User .container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: white;
  min-height: 30vh;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.User .container .section {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.User .container .section .title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 10px;
}
.User .container .section .item {
  margin: 10px;
  box-sizing: border-box;
}
.User .container .section .item .value {
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  font-family: 'Monaco', 'Lucida Console', monospace;
  padding: 8px 20px;
  border-radius: 6px;
  color: black;
  display: inline-block;
}
.User .container .comment .title {
  margin: 0;
}
.User .container .comment .comment-area {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  resize: none;
  border: none;
  border-radius: 6px;
  padding: 6px;
  margin: 10px 0;
  font-size: 14px;
  background-color: #f5f5f5;
}
.User .container .comment .comment-area.loading {
  pointer-events: none;
  animation: gui-standby-loading-large 1.1s ease-in-out infinite;
}
.User .container .comment .comment-area.success {
  animation: list-item-success-pulse 1.5s ease-in-out backwards;
}
.User .container .comment .comment-area.failed {
  animation: list-item-failed-pulse 1.5s ease-in-out backwards;
}
.User .container .comment .btn {
  margin: auto;
  display: block;
}
.User .container .user-controls .OptionSwitcher {
  margin: 10px;
}
.EventReport {
  width: 100vw;
  padding-bottom: 10px;
}
.EventReport > .container {
  max-width: 1040px;
  min-height: 30vh;
  margin-bottom: 5px;
  padding-top: 30px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
}
.EventReport > .container > .loading-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.EventReport > .container > .loading-placeholder > .fake-item {
  width: 50%;
}
.EventReport > .container > .loading-placeholder > .fake-item:nth-child(1) {
  font-size: 30px;
}
.EventReport > .container > .title {
  margin: 0 auto 10px;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
}
.EventReport > .container > .sub-title {
  margin: 0 auto 5px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.EventReport > .container > .DataTable {
  margin-top: 30px;
  overflow-y: auto;
}
.EventReport > .container > .DataTable tr:last-child > td:first-child {
  border-bottom-left-radius: 6px;
}
.EventReport > .container > .DataTable tr:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
.AdminForm {
  width: 300px;
}
.AdminForm .waiting-indicator {
  width: 90px;
  margin: 30px auto;
  position: relative;
}
.AdminForm .errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  margin: 15px 0 0;
}
.AdminForm .form-field.single-field {
  display: flex;
}
.AdminForm .form-field.single-field input[type="checkbox"] {
  width: unset;
}
.AdminForm .copy-charts-warning {
  margin: 20px auto 0;
  width: 300px;
  text-align: left;
}
.AdminForm .copy-charts-warning > .warning-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  list-style: none;
  padding: 10px 5px;
  margin: 2px 0 0 0;
  background-color: white;
  border-radius: 6px;
}
.AdminForm .copy-charts-warning > .warning-item.warning {
  color: #eca200;
}
.AdminForm .copy-charts-warning > .warning-item.error {
  color: #db0e11;
}
.AdminForm .copy-charts-warning > .warning-item > .icon {
  padding: 0 10px 0 8px;
  font-size: 19px;
}
.AdminForm .copy-charts-warning > .warning-item > .caption {
  line-height: 1.1em;
}
.UserEventSearcher label {
  font-weight: bold;
}
.UserEventSearcher input.filter,
.UserEventSearcher input.formatter {
  width: 90%;
}
.UserEventSearcher .DropDown {
  width: 320px;
}
.UserEventSearcher .DropDown .value {
  font-weight: normal;
  font-size: 16px;
  padding-top: 0.75em;
}
.UserEventSearcher .filterError {
  color: red;
}
.UserEventSearcher .numMatchingEvents {
  font-size: 0.7em;
}
.UserEventSearcher .title {
  margin-bottom: 0 !important;
}
.UserEventSearcher .columns .add {
  margin-top: 0.5em;
}
.UserEventSearcher .columns .delete {
  margin-left: 8px;
}
.UserEventSearcher .search {
  margin-top: 3em;
}
.rendering-event-dialog {
  width: 50vw;
}
.rendering-event-dialog .message {
  margin: 0 !important;
  max-width: 100% !important;
}
.rendering-event-dialog .message textarea {
  width: 100%;
  height: 300px;
}
.edit-subscription-event-form .form-field {
  margin-top: 1em;
}
.edit-subscription-event-form .form-field input {
  width: 220px;
}
.edit-subscription-event-form .form-field select {
  margin-bottom: 5px;
}
.edit-subscription-event-form .add {
  float: right;
}
#billing-report .container {
  max-width: 1320px;
}
#billing-report .container .DataTable {
  max-height: 500px;
}
#billing-report .container .DataTable table th {
  position: sticky;
  top: 0;
  background: white;
}
#deploymentPipelines .DropDown {
  height: 25px;
  width: 350px;
  margin-top: 20px;
  margin-left: 13px;
}
.deploymentPipeline {
  margin-top: 20px;
  margin-bottom: 20px;
}
.deploymentPipeline h1 {
  background-color: #4D4D4D;
  color: white;
  padding: 5px 20px;
}
.deploymentPipeline .content {
  display: flex;
  justify-content: center;
}
.deploymentPipeline .content .environment {
  padding: 10px;
  width: 300px;
}
.deploymentPipeline .content .environment .links {
  text-align: center;
}
.deploymentPipeline .content .environment .links a {
  margin-right: 15px;
}
.deploymentPipeline .content .environment .title {
  font-size: 1.4em;
  font-weight: bold;
  display: inline-block;
  line-height: 35px;
}
.deploymentPipeline .content .environment .validationError {
  background-color: #db0e11;
}
.deploymentPipeline .content .environment .statusBadge {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 0.9em;
}
.deploymentPipeline .content .environment .statusBadge .left {
  padding: 5px;
  background-color: #6f7477;
  color: white;
}
.deploymentPipeline .content .environment .statusBadge .status {
  padding: 5px;
  color: white;
}
.deploymentPipeline .content .environment .statusBadge .status.success {
  background-color: #2ebb18;
}
.deploymentPipeline .content .environment .statusBadge .status.failure {
  background-color: #db0e11;
}
.deploymentPipeline .content .environment .statusBadge .status.in_progress {
  background-color: #fac532;
  color: black;
}
.deploymentPipeline .content .environment .statusBadge .status.never_ran {
  background-color: #fac532;
  color: black;
}
.deploymentPipeline .content .environment .commitInfo {
  margin-top: 3px;
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 0.9em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.deploymentPipeline .content .environment .item {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #f5f5f5;
}
.deploymentPipeline .content .environment .item .itemTitle {
  display: inline-block;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 25px;
}
.deploymentPipeline .content .environment .item .commitHashError {
  display: inline-block;
  margin-left: 10px;
  background-color: #db0e11;
  padding: 5px;
  color: white;
  border-radius: 6px;
}
.deploymentPipeline .content .environment .item .commitHash {
  display: inline-block;
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 0.9em;
}
.deploymentPipeline .content .action {
  margin-top: 100px;
  width: 150px;
  display: flex;
  justify-content: center;
}
.deploymentPipeline .content .action .diff {
  margin-top: 5px;
  text-align: center;
}
#region .body {
  margin: 15px 10px;
}
#region .body .region {
  font-size: 15px;
  font-weight: 500;
}
#regionMigrationForm .DropDown .value {
  font-size: 17px;
}
.sectioned-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
}
.sectioned-page > .sectioned-page-container {
  width: 100%;
  max-width: 800px;
  background-color: white;
  min-height: 80vh;
  min-height: calc(100vh - 52px);
  font-size: 14px;
}
.sectioned-page > .sectioned-page-container.with-expanded-header {
  min-height: calc(100vh - 85px);
}
.sectioned-page > .sectioned-page-container .button-container {
  text-align: center;
  margin-bottom: 4em;
}
.sectioned-page > .sectioned-page-container .loading-placeholder {
  padding: 15px 25px;
}
.sectioned-page > .sectioned-page-container .loading-placeholder + .loading-placeholder {
  border-top: 1px solid #e6e6e6;
}
.sectioned-page > .sectioned-page-container .loading-placeholder > .fake-item {
  height: 2em;
  width: 50%;
  border-radius: 0.25em;
  margin-top: 1em;
}
.sectioned-page > .sectioned-page-container > .section {
  padding: 25px 30px;
}
.sectioned-page > .sectioned-page-container > .section.wide {
  padding-left: 0;
  padding-right: 0;
}
.sectioned-page > .sectioned-page-container > .section:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}
.sectioned-page > .sectioned-page-container > .section::after {
  content: "";
  display: block;
  clear: both;
}
.sectioned-page > .sectioned-page-container > .section > .notice {
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  float: right;
  max-width: 280px;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  box-sizing: border-box;
}
.sectioned-page > .sectioned-page-container > .section > .notice > .title {
  font-size: 16px;
  font-weight: bold;
}
.sectioned-page > .sectioned-page-container > .section > .notice > .description {
  margin-top: 0.7em;
  line-height: 1.5em;
}
.sectioned-page > .sectioned-page-container > .section > .notice > .description > p {
  margin: 0;
  padding: 0;
}
.sectioned-page > .sectioned-page-container > .section > .notice > .description > p + p {
  margin-top: 8px;
}
.sectioned-page > .sectioned-page-container > .section > .notice > .description span {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Monaco', 'Lucida Console', monospace;
}
.sectioned-page > .sectioned-page-container > .section > .title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.sectioned-page > .sectioned-page-container > .section > .page-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.sectioned-page > .sectioned-page-container > .section > .page-description {
  font-size: 16px;
  font-weight: 400;
  color: #464646;
  line-height: 1.5em;
}
.sectioned-page > .sectioned-page-container > .section > ul {
  padding-left: 20px;
}
.sectioned-page > .sectioned-page-container > .section > ul li {
  margin-top: 0.25em;
}
.sectioned-page > .sectioned-page-container > .section > .key {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 6px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 15px;
}
.sectioned-page > .sectioned-page-container > .section > .text {
  max-width: 360px;
  margin-top: 25px;
  line-height: 1.5em;
}
.sectioned-page > .sectioned-page-container > .section .display-title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}
.sectioned-page > .sectioned-page-container > .section .display-text {
  width: 400px;
  margin: 10px auto;
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
}
.sectioned-page > .sectioned-page-container > .section > .NumericInput,
.sectioned-page > .sectioned-page-container > .section > .OptionSwitcher {
  margin-top: 15px;
}
.sectioned-page > .sectioned-page-container > .section > .subtitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: 40px;
}
.sectioned-page > .sectioned-page-container > .section .subtitle + * {
  margin-top: 10px;
}
.sectioned-page > .sectioned-page-container > .section span.error {
  color: #EA1E1D;
}
.sectioned-page > .sectioned-page-container > .section hr.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid hsl(0, 0%, 90%);
  border-width: 0 0 1px 0;
}
.sectioned-page .form-field {
  margin-top: 20px;
}
.sectioned-page .form-field label,
.sectioned-page .form-field input[type="text"],
.sectioned-page .form-field input[type="password"] {
  display: block;
  width: 100%;
  max-width: 260px;
  box-sizing: border-box;
}
.sectioned-page .form-field input,
.sectioned-page .form-field input[type="text"],
.sectioned-page .form-field input[type="password"] {
  margin-top: 6px;
  font-size: inherit;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}
.sectioned-page .form-field select {
  margin-top: 6px;
}
.sectioned-page .form-field.single-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-end;
}
.sectioned-page .form-field.single-line input,
.sectioned-page .form-field.single-line label {
  display: block;
}
.sectioned-page .form-field.single-line input[type=radio] {
  width: 1em;
  height: 1em;
  margin: 0;
  padding: 0;
}
.sectioned-page .form-field.single-line label {
  width: auto;
}
.sectioned-page .form-field.single-line input + label {
  margin-left: 8px;
}
.sectioned-page .form-text {
  font-size: 14px;
  line-height: 1.75em;
  margin-top: 50px;
}
.sectioned-page .form-text p {
  margin-top: 0.75em;
  margin-bottom: 0;
}
.sectioned-page .form-text + .form-actions {
  margin-top: 40px;
}
.Accordion {
  margin-top: 10px;
}
.Accordion .title {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 8px 25px;
  background: linear-gradient(to top, hsl(0, 1%, 92%), hsl(0, 1%, 97%));
  box-shadow: inset 0 0 0 1px rgba(0, 0, 10, 0.04);
  box-sizing: border-box;
  border-radius: 4px;
  color: hsl(0, 0%, 7%);
  font-size: 15px;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.4);
  transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
}
.Accordion .title .icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 14px;
  color: hsl(0, 0%, 13%);
}
.Accordion .title .icon.flipped {
  top: 9px;
  transform: rotate(180deg);
}
.Accordion .title:active {
  background: linear-gradient(to top, hsl(0, 1%, 89%), hsl(0, 1%, 94%));
}
.Accordion .title:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 10, 0.15);
}
.Accordion .title.expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: linear-gradient(to top, hsl(0, 1%, 85%), hsl(0, 1%, 93%));
}
.Accordion .title.expanded:active {
  background: linear-gradient(to top, hsl(0, 1%, 82%), hsl(0, 1%, 90%));
}
.Accordion .title.expanded .icon {
  color: hsl(0, 0%, 7%);
}
.Accordion .container {
  padding: 0;
  margin: 0;
  max-height: calc(70vh - 85px);
  border: 1px solid hsl(200, 5%, 87%);
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: none;
  overflow-y: auto;
}
.Accordion .container.expanded {
  display: block;
}
.ConfigurationKeys {
  width: 100%;
  background-color: white;
  margin: 0;
  padding: 10px 0;
}
.ConfigurationKeys .accordion-container {
  width: 60%;
  display: block;
  margin: 10px auto;
  padding: 0;
}
.ConfigurationKeys .accordion-container .companies-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
}
.ConfigurationKeys .accordion-container .companies-list .company {
  width: 40%;
  flex-grow: 1;
  padding: 8px 25px;
}
.ConfigurationKeys .accordion-container .companies-list .company:nth-child(4n+1),
.ConfigurationKeys .accordion-container .companies-list .company:nth-child(4n+2) {
  background: hsl(0, 0%, 97%);
}
.ConfigurationKeys .accordion-container .number-of-companies {
  position: absolute;
  right: 40px;
  top: 7px;
  color: hsl(220, 2%, 60%);
  font-size: 12px;
  width: 150px;
  text-align: left;
}
.ConfigurationKeys .accordion-container .number-of-companies .count {
  display: inline-block;
  width: 45px;
  margin-right: 4px;
  color: hsl(220, 2%, 20%);
  font-size: 16px;
  text-align: right;
}
.FeatureAnalytics {
  width: 100vw;
  padding-bottom: 10px;
}
.FeatureAnalytics > .container {
  max-width: 1040px;
  min-height: 30vh;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
}
.FeatureAnalytics .view-links-button {
  margin-left: 4px;
}
.CopyToForm {
  min-width: 310px;
}
.CopyToForm .copy-to-message {
  margin: 10px auto 20px;
  font-size: 18px;
  text-align: center;
}
.CopyToForm .copy-to-message > .icon {
  position: relative;
  top: 1px;
  margin-right: 8px;
}
.CopyToForm .copy-to-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
  background: hsl(0, 0%, 94%);
  box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.02);
  padding: 3px;
  border-radius: 12px;
}
.CopyToForm .copy-to-options .copy-to-option {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  border-radius: 10px;
  color: hsla(0, 0%, 35%);
  background: hsla(0, 0%, 100%, 0);
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.CopyToForm .copy-to-options .copy-to-option.selectable {
  cursor: pointer;
}
.CopyToForm .copy-to-options .copy-to-option.selectable:hover {
  color: hsla(0, 0%, 10%);
}
.CopyToForm .copy-to-options .copy-to-option.selectable:hover .copy-account {
  color: inherit;
}
.CopyToForm .copy-to-options .copy-to-option .icon {
  font-size: 48px;
}
.CopyToForm .copy-to-options .copy-to-option .copy-account {
  font-size: 12px;
  color: hsla(0, 0%, 65%);
}
.CopyToForm .copy-to-options .copy-to-option.selected {
  background: white;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1);
  color: hsla(0, 0%, 10%);
}
.CopyToForm .copy-to-options .copy-to-option.selected .label {
  color: #0784fa;
}
.CopyToForm .copy-to-options .copy-to-option.selected .copy-account {
  color: inherit;
}
.CopyToForm .copy-to-options .copy-to-option:not(.selected) + .copy-to-option:not(.selected) {
  border-left: 1px solid hsla(0, 0%, 90%);
  border-radius: 0;
}
.CopyToForm .copy-to-options input[type='radio'] {
  display: none;
}
.CopyToForm .empty-list {
  text-align: center;
}
.CopyToForm .error {
  color: #db0e11;
  text-align: center;
  margin: 6px;
}
@keyframes max-height-from-zero {
  from {
    max-height: 0;
  }
}
.invitations .DataTable tr .cancel-invitation {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.invitations .DataTable tr .cancel-invitation:active {
  color: rgba(180, 180, 180, 0.95);
}
.invitations .DataTable tr:hover .cancel-invitation {
  opacity: 1;
}
.preview-container {
  background-color: white;
}
.preview-container .options {
  min-width: 200px;
  position: fixed;
  border: 1px solid black;
  right: 20px;
  top: 20px;
  padding: 20px;
  background-color: white;
}
.preview-container .options .label {
  padding-left: 0.5em;
}
.preview-container .seating-chart {
  min-width: 450px;
  flex-grow: 1;
  height: 100%;
}
.InactiveWorkspacesList .section.wide {
  padding-top: 0;
}
.InactiveWorkspacesList .section.wide .page-title {
  padding: 30px;
  margin-bottom: 0;
}
.create-workspace-dialog {
  width: 320px;
}
.Webhooks {
  display: flex;
  flex-direction: column;
}
.Webhooks > .title {
  margin: 0 20px;
  padding: 30px;
  font-size: 32px;
  font-weight: 600;
  background-color: white;
}
.Webhooks > .iframe {
  height: 100%;
  margin: 0 20px;
  border: none;
}
.Invoices {
  padding-bottom: 10px;
}
.Invoices > .container {
  max-width: 1040px;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 20px auto;
  background-color: white;
  padding-bottom: 20px;
}
.Invoices > .container > .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-left: 30px;
}
.Invoices > .loading-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1040px;
  margin: 10px auto 10px;
}
.Invoices > .loading-placeholder > .fake-item {
  width: 18%;
}
.Invoices .DataTable {
  overflow-y: auto;
}
.Invoices .DataTable th:nth-child(5),
.Invoices .DataTable th:nth-child(6),
.Invoices .DataTable td:nth-child(5),
.Invoices .DataTable td:nth-child(6) {
  text-align: center;
}
.Invoices .DataTable tr:last-child > td:first-child {
  border-bottom-left-radius: 6px;
}
.Invoices .DataTable tr:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
.Invoices .DataTable table tr.enter {
  animation-delay: 0.5s;
}
.Invoices .DataTable table tr .action-cell .icon {
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.Invoices .DataTable table tr .action-cell .icon:hover {
  opacity: 1;
  transition-delay: 0.1s;
}
.AddPaymentMethod .StripeElement + .StripeElement {
  margin-top: 30px;
}
.AddPaymentMethod .actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.AddPaymentMethod .actions > a.action {
  cursor: pointer;
  padding: 0.625em 1.125em;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #888;
  background: white;
  color: #333;
  font-size: inherit;
  line-height: 1em;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 0 #888;
  transition: 0.1s cubic-bezier(0.23, 1, 0.32, 1) background, 0.1s cubic-bezier(0.23, 1, 0.32, 1) box-shadow;
}
.AddPaymentMethod .actions > a.action:hover {
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0 0 0 1px #888;
}
.AddPaymentMethod .actions > a.action:active {
  background: hsl(0, 0%, 90%);
  box-shadow: inset 0 0 0 1px #444;
}
.AddPaymentMethod .actions > .action + .action {
  margin-left: 16px;
}
.UpdateBillingDetails .vat {
  margin-top: 10px;
}
.UpdateBillingDetails .vat input {
  margin-top: 5px;
  height: 35px;
  width: 99%;
  border-radius: 5px;
  border-color: #999999;
  border-width: 1px;
  font-size: large;
}
.UpdateBillingDetails .actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.UpdateBillingDetails .actions > a.action {
  cursor: pointer;
  padding: 0.625em 1.125em;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #888;
  background: white;
  color: #333;
  font-size: inherit;
  line-height: 1em;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 0 #888;
  transition: 0.1s cubic-bezier(0.23, 1, 0.32, 1) background, 0.1s cubic-bezier(0.23, 1, 0.32, 1) box-shadow;
}
.UpdateBillingDetails .actions > a.action:hover {
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0 0 0 1px #888;
}
.UpdateBillingDetails .actions > a.action:active {
  background: hsl(0, 0%, 90%);
  box-shadow: inset 0 0 0 1px #444;
}
.UpdateBillingDetails .actions > .action + .action {
  margin-left: 16px;
}
.PaymentDetails .sectioned-page > .sectioned-page-container > .section {
  padding: 50px 30px;
}
.PaymentDetails .StripeElement + .StripeElement {
  margin-top: 30px;
}
.PaymentDetails .paymentDetails {
  margin: 1em 0 1.5em 0;
  font-size: 16px;
}
.PaymentDetails .paymentDetails .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.PaymentDetails .paymentForm .legal-notice {
  margin-top: 3em;
}
.PaymentDetails .paymentForm .firm-commitment-notice {
  text-align: center;
  margin-top: 2em;
}
.PaymentDetails .actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.PaymentDetails .actions > a.action {
  cursor: pointer;
  padding: 0.625em 1.125em;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #888;
  background: white;
  color: #333;
  font-size: inherit;
  line-height: 1em;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 0 #888;
  transition: 0.1s cubic-bezier(0.23, 1, 0.32, 1) background, 0.1s cubic-bezier(0.23, 1, 0.32, 1) box-shadow;
}
.PaymentDetails .actions > a.action:hover {
  background: hsl(0, 0%, 95%);
  box-shadow: inset 0 0 0 1px #888;
}
.PaymentDetails .actions > a.action:active {
  background: hsl(0, 0%, 90%);
  box-shadow: inset 0 0 0 1px #444;
}
.PaymentDetails .actions > .action + .action {
  margin-left: 16px;
}
.EmailTemplates {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background: white;
  transform: none !important;
}
.EmailTemplates .page {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}
.EmailTemplates .page > .NavigationList {
  width: 40vw;
  max-width: 370px;
}
.EmailTemplates .page .emailTemplate {
  width: 100%;
  min-width: 600px;
  flex-shrink: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.EmailTemplates .page .emailTemplate .contents {
  font: medium 'Monaco', 'Lucida Console', monospace;
  width: 80%;
  height: 400px;
  margin: 25px;
  border: #000000 solid 1px;
}
.timeline {
  display: inline-block;
  width: 100%;
}
.timeline .timelineContainer {
  padding-left: 25px;
  margin-top: 1em;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: visible;
  position: relative;
}
.timeline .timelineContainer .timeline-line {
  border-left: #888787 dashed 1px;
  position: absolute;
  width: 1px;
  height: calc(100% - 48px);
  left: 18px;
  top: 4px;
}
.timeline .title {
  margin: 0;
}
.timeline .title .icon-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  background: transparent;
  font-size: large;
  cursor: pointer;
}
.timeline .title .oldest-first::before {
  content: "\e93d";
  display: inline-block;
  transform: rotate(180deg);
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.8em;
  margin-right: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.timeline .title .newest-first::before {
  content: "\e93d";
  display: inline-block;
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.8em;
  margin-right: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.timeline .timeline-event {
  margin-bottom: 1em;
  padding: 3px;
  position: relative;
  top: -20px;
}
.timeline .timeline-event .timeline-event-point {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background: #888787;
  position: relative;
  left: -17px;
  top: 18px;
  border: 2px solid #fff;
}
.timeline .timeline-event .timeline-event-point > .timeline-event-point-inner {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #444444;
  position: relative;
  left: 3px;
  top: 3px;
}
.timeline .timeline-event .timeline-event-container {
  display: flex;
  justify-content: space-between;
}
.timeline .timeline-event .timeline-event-title {
  font-size: medium;
}
.timeline .timeline-event .timeline-event-title > .icon {
  font-size: small;
  margin-right: 0.25em;
}
.timeline .timeline-event .timeline-event-title > .icon.error {
  color: #d44944;
}
.timeline .timeline-event .timeline-event-user {
  font-size: smaller;
}
.timeline .timeline-event .timeline-event-user::before {
  content: "\e98a";
  display: inline-block;
  font-family: 'seatsdesigner';
  line-height: 1;
  font-size: 0.8em;
  margin-right: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.timeline .timeline-event .timeline-event-user > button {
  border: none;
  font-size: x-small;
  margin-left: 8px;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  padding: 0;
  border: 1px solid #ebebeb;
  cursor: pointer;
}
.timeline .timeline-event .timeline-event-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.33s ease-out;
}
.timeline .timeline-event .timeline-event-details.visible {
  max-height: 200px;
}
.timeline .timeline-event .timeline-event-datetime {
  font-size: 15px;
  color: #656565;
}
.timeline .timeline-event .timeline-event-datetime > span {
  font-size: smaller;
}
.timeline .timeline-event .timeline-event-datetime > .time {
  margin-left: 5px;
}
.timeline .timeline-event .timeline-event-data {
  margin-top: 10px;
}
.timeline .timeline-event .timeline-data-value:before {
  content: ': ';
}
.Billing {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
  min-height: 80vh;
  min-height: calc(100vh - 52px);
}
.Billing code {
  font-family: 'Monaco', 'Lucida Console', monospace;
  font-size: 0.9em;
  background-color: #f5f5f5;
}
.Billing .sidebar {
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  width: 150px;
}
.Billing .sidebar > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  padding: 10px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px 0 0 10px;
  transform-origin: center right;
  transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.Billing .sidebar > a.active {
  cursor: default;
  color: black;
  background-color: white;
}
.Billing .sidebar > a:not(.active):hover {
  transform: scale(1.05);
}
.Billing .simple-page {
  width: 100%;
  max-width: 800px;
  background-color: white;
  min-height: 80vh;
  min-height: calc(100vh - 52px);
  font-size: 14px;
}
.Billing .simple-page .loading-placeholder {
  padding: 15px 25px;
}
.Billing .simple-page .loading-placeholder + .loading-placeholder {
  border-top: 1px solid #e6e6e6;
}
.Billing .simple-page .loading-placeholder > .fake-item {
  height: 2em;
  width: 50%;
  border-radius: 0.25em;
  margin-top: 1em;
}
.Billing .simple-page > .page-title {
  padding-top: 30px;
  padding-left: 30px;
  font-size: 32px;
  font-weight: 600;
}
.ManagePaymentMethods {
  padding-bottom: 10px;
}
.ManagePaymentMethods > .container {
  max-width: 1040px;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 20px auto;
  background-color: white;
  padding-bottom: 20px;
}
.ManagePaymentMethods > .container > .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-left: 30px;
}
.ManagePaymentMethods > .loading-placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1040px;
  margin: 10px auto 10px;
}
.ManagePaymentMethods > .loading-placeholder > .fake-item {
  width: 18%;
}
.ManagePaymentMethods .actions {
  display: flex;
  margin-top: 8px;
  padding-left: 30px;
}
.ManagePaymentMethods .actions > .action-note:not(:last-child) {
  margin-right: 15px;
}
.ManagePaymentMethods .actions > .action-note {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  display: flex;
  height: 1.5em;
  flex-direction: row;
  align-items: center;
  color: #979797;
}
.ManagePaymentMethods .actions > .action-note .icon {
  position: relative;
  top: 1px;
  margin-inline-end: 5px;
  font-size: 16px;
}
.ManagePaymentMethods .actions > .action-note > .label {
  font-size: 14px;
  font-weight: 500;
}
.ManagePaymentMethods .DataTable {
  overflow-y: auto;
}
.ManagePaymentMethods .DataTable th:nth-child(5),
.ManagePaymentMethods .DataTable th:nth-child(6),
.ManagePaymentMethods .DataTable td:nth-child(5),
.ManagePaymentMethods .DataTable td:nth-child(6) {
  text-align: center;
}
.ManagePaymentMethods .DataTable td:last-of-type {
  text-align: right;
}
.ManagePaymentMethods .DataTable td:last-of-type > button {
  display: inline-block;
}
.ManagePaymentMethods .DataTable tr:last-child > td:first-child {
  border-bottom-left-radius: 6px;
}
.ManagePaymentMethods .DataTable tr:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
.ManagePaymentMethods .DataTable table tr.enter {
  animation-delay: 0.5s;
}
.ManagePaymentMethods .DataTable table tr > td.card-status {
  display: flex;
}
.ManagePaymentMethods .DataTable table tr .action-cell .icon {
  opacity: 0.5;
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.ManagePaymentMethods .DataTable table tr .action-cell .icon:hover {
  opacity: 1;
  transition-delay: 0.1s;
}
.two-factor-authentication-wizard {
  width: 320px;
}
.NewModalWizard {
  pointer-events: none;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  position: fixed;
  z-index: 5600;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NewModalWizard > .backdrop {
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(190, 190, 190, 0.97);
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.NewModalWizard > .backdrop:active {
  background-color: rgba(180, 180, 180, 0.95);
}
.NewModalWizard > .wizard {
  pointer-events: all;
  position: relative;
  z-index: 1;
  padding: 25px 40px;
  background: hsl(0, 0%, 97%);
  border-radius: 6px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.15);
  animation: zoom-in 0.4s cubic-bezier(0.17, 1.58, 0.22, 0.99);
}
.NewModalWizard > .wizard button.button-link {
  color: #0784fa;
  margin-left: 5px;
  margin-right: 5px;
}
.NewModalWizard > .wizard > .overlay-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background: inherit;
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1);
  color: #222;
}
.NewModalWizard > .wizard > .overlay-message > .title,
.NewModalWizard > .wizard > .overlay-message > .message,
.NewModalWizard > .wizard > .overlay-message > .actions {
  animation: fade-in 1s cubic-bezier(0.19, 1, 0.22, 1) 1s backwards;
}
.NewModalWizard > .wizard > .overlay-message > .title {
  font-size: 24px;
  margin-bottom: 0.75em;
}
.NewModalWizard > .wizard > .overlay-message > .message {
  font-size: 16px;
  padding: 0 20px;
  text-align: center;
  margin-bottom: 0;
}
.NewModalWizard > .wizard > .overlay-message > .actions {
  margin-bottom: 0;
}
.NewModalWizard > .wizard .title {
  max-width: 400px;
  margin: 0 auto 18px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
}
.NewModalWizard > .wizard .subtitle {
  max-width: 400px;
  margin: 32px auto 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.NewModalWizard > .wizard .message {
  padding: 0 10px;
  margin: 0 auto 25px;
  box-sizing: border-box;
  max-width: 400px;
  font-size: 15px;
  text-align: center;
  line-height: 1.5em;
}
.NewModalWizard > .wizard .facts {
  max-width: 400px;
  margin: 30px auto;
}
.NewModalWizard > .wizard .facts > .fact {
  margin-top: 8px;
  padding: 15px 20px;
  border-radius: 6px;
  background: white;
  font-size: 15px;
  line-height: 1.2em;
}
.NewModalWizard > .wizard .form {
  margin-top: 10px;
  margin-bottom: 10px;
}
.NewModalWizard > .wizard .form input[disabled] {
  border: 1px solid hsl(0, 0%, 85%);
}
.NewModalWizard > .wizard .form .DropDown .value {
  font-size: 18px;
}
.NewModalWizard > .wizard .message + .form {
  margin-top: 0;
}
.NewModalWizard > .wizard .draft-validation-warning {
  margin: 20px auto 0;
  width: 300px;
  text-align: left;
}
.NewModalWizard > .wizard .draft-validation-warning > .validation-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  list-style: none;
  padding: 10px 5px;
  margin: 2px 0 0 0;
  background-color: white;
  border-radius: 6px;
}
.NewModalWizard > .wizard .draft-validation-warning > .validation-item.warning {
  color: #eca200;
}
.NewModalWizard > .wizard .draft-validation-warning > .validation-item.error {
  color: #db0e11;
}
.NewModalWizard > .wizard .draft-validation-warning > .validation-item > .icon {
  padding: 0 10px 0 8px;
  font-size: 19px;
}
.NewModalWizard > .wizard .draft-validation-warning > .validation-item > .caption {
  line-height: 1.1em;
}
.NewModalWizard > .wizard .actions {
  position: relative;
  height: 37px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.NewModalWizard > .wizard .actions .action {
  cursor: pointer;
  display: inline-block;
  width: auto;
  border: none;
  background: transparent;
  color: hsl(0, 0%, 40%);
  font-size: 17px;
  font-weight: normal;
  padding: 0.5em 1em;
}
.NewModalWizard > .wizard .actions .action:active {
  color: hsl(0, 0%, 30%);
}
.NewModalWizard > .wizard .actions .action[disabled] {
  cursor: not-allowed;
  color: hsl(0, 0%, 70%) !important;
}
.NewModalWizard > .wizard .actions .action[disabled]:active {
  color: hsl(0, 0%, 60%);
}
.NewModalWizard > .wizard .actions .action.highlighted {
  color: #0784fa;
}
.NewModalWizard > .wizard .actions .action.highlighted:active {
  color: #005cc5;
}
.NewModalWizard > .wizard .actions .action.highlighted.danger {
  color: #db0e11;
}
.NewModalWizard > .wizard .actions .action.highlighted.danger:active {
  color: #be0a0d;
}
.NewModalWizard.closed {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.NewModalWizard.closed > .dialog {
  transform: scale(0.95);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes stamp-scale-in {
  from {
    transform: scale(0.1);
    opacity: 0;
  }
  5% {
    transform: scale(0.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
  }
}
@keyframes stamp-stroke-in {
  from {
    box-shadow: inset 0 0 0 17px #2ebb18;
  }
  50% {
    box-shadow: inset 0 0 0 17px #2ebb18;
  }
  to {
    box-shadow: inset 0 0 0 1.5px #2ebb18;
  }
}
