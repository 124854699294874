.ModalDialog.SplashScreen {
	.full-size-absolute;
	z-index: 0;
	.vertical-flex;
	justify-content: center;
	background: #1A1A1A;

	> .backdrop {
		pointer-events: none;
		.full-size-absolute;
		z-index: -1;
        background: radial-gradient(ellipse at center, #495864, #333638 30%, #2a2c2e 50%, #111111);
        background-size: cover;
        animation: fade-in 4s @ease-out-cubic 0.5s backwards;

		&::after {
			content: "";
			display: block;
			.full-size-absolute;
			background: url("/assets/noise@2x.png") repeat center center;
			background-size: 300px 300px;
			opacity: 0.01;
		}
	}

	> .logo {
		position: absolute;
		left: 0;
		right: 0;
		top: 50px;
		height: 25px;
		background: url("/assets/seatsio-logo-white.svg") no-repeat center center;
		background-size: contain;
        animation: fade-in 1.4s @ease-out-cubic 0.6s backwards;
	}

	> .dialog {
		animation: slight-zoom-in 1.5s @ease-out-cubic 0.2s;
        animation-fill-mode: backwards;
	}

	> .quick-links {
		.horizontal-flex;
		pointer-events: all;
		padding: 0 15px;
		margin-top: 30px;
		text-align: center;

		> .quick-link {
			display: block;
			font-size: 15px;
			padding: 1em;
			min-width: 150px;
			color: @color-soft-link;
			animation: shiny-quick-link-fade-in 1.4s @ease-in-out-cubic 1.2s backwards;

			&:nth-child(2) {
				animation-delay: 1.4s;
			}

			&:hover {
				color: @color-soft-link-highlight;
				text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.1);
			}

			&:active {
				color: @color-soft-link-deep;
				text-shadow: 0 0 12px hsla(0, 0%, 0%, 0.1);
			}
		}

		@keyframes shiny-quick-link-fade-in {
			from {
				color: hsl(185, 100%, 86%);
				text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.3);
				opacity: 0;
			}
			30% {
				color: hsl(185, 100%, 86%);
				text-shadow: 0 0 12px hsla(180, 100%, 92%, 0.3);
				opacity: 1;
			}
		}
	}
}
