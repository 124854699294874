.link-list {
    width: 400px;

    > a {
        display: block;
        padding: 7px 12px;
        border: 1px solid @color-list-border;
        border-radius: 5px;
        transition: background-color 0.2s @ease-out-cubic;

        &:not(:first-child) {
            margin-top: 5px;
        }

        &:hover {
            background-color: @color-list-background-hover;
        }

        &:active {
            background-color: hsl(0, 0%, 96%);
        }
    }

    &.wide {
        position: relative;
        left: -@section-side-padding;
        width: calc(100% + @section-side-padding * 2);

        > a {
            padding: (@section-side-padding / 2) @section-side-padding;
            border-radius: 0;
            border-left: none;
            border-right: none;
            font-size: 17px;

            &:not(:first-child) {
                margin-top: 0;
                border-top: none;
            }
        }
    }
}
