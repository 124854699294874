.CopyToForm {
    min-width: 310px;

    .copy-to-message {
        margin: 10px auto 20px;
        font-size: 18px;
        text-align: center;

        > .icon {
            position: relative;
            top: 1px;
            margin-right: 8px;
        }
    }

    .copy-to-options {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 15px;
        background: hsl(0, 0%, 94%);
        box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.02);
        padding: 3px;
        border-radius: 12px;

        .copy-to-option {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px 20px;
            border-radius: 10px;
            color: hsla(0, 0%, 35%);
            background: hsla(0, 0%, 100%, 0);
            box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0);
            transition: all 0.3s @ease-out-cubic;

            &.selectable {
                cursor: pointer;

                &:hover {
                    color: hsla(0, 0%, 10%);

                    .copy-account {
                        color: inherit;
                    }
                }
            }

            .icon {
                font-size: 48px;
            }

            .copy-account {
                font-size: 12px;
                color: hsla(0, 0%, 65%);
            }

            &.selected {
                background: white;
                box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1);
                color: hsla(0, 0%, 10%);

                .label {
                    color: @color-selected;
                }

                .copy-account {
                    color: inherit;
                }
            }
        }

        .copy-to-option:not(.selected) + .copy-to-option:not(.selected) {
            border-left: 1px solid hsla(0, 0%, 90%);
            border-radius: 0;
        }

        input[type='radio'] {
            display: none;
        }
    }

    .empty-list {
        text-align: center;
    }

    .error {
        color: @color-alert-danger;
        text-align: center;
        margin: 6px;
    }
}

@keyframes max-height-from-zero {
    from {
        max-height: 0;
    }
}
