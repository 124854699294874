.EmailTemplates {
    .vertical-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    background: white;
    transform: none !important; // Overriding so chart fullscreen can be truly positioned at the top left of the screen.

    .page {
        display: flex;
        flex-grow: 1;
        width: 100%;
        overflow: hidden;

        > .NavigationList {
            width: 40vw;
            max-width: 370px;
        }

        .emailTemplate {
            width: 100%;
            min-width: 600px;
            flex-shrink: 1;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 10px;

            .contents {
                font: medium 'Monaco', 'Lucida Console', monospace;
                width: 80%;
                height: 400px;
                margin: 25px;
                border: #000000 solid 1px;
            }
        }
    }
}
