.Button {
    position: relative;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    outline: none !important;

    .icon, .caption, img {
        pointer-events: none;
    }

    &.preset-contextual-menu-item {
        width: 100%;
        padding: 3px 20px 3px 16px;
        color: @color-panel-face;
        text-align: left;

        > .wrapper {
            display: block;
            .horizontal-flex;
            align-items: center;

            > .icon {
                display: block;
                margin-right: 8px;
                font-size: 18px;
            }
            > .caption {
                display: block;
                font-size: 14px;
                padding-bottom: 1px;
                font-weight: 500;
            }
        }

        &:hover:not(:disabled) {
            color: @color-textbox-surface;
            background: @color-selected;
        }

        &:active:not(:disabled) {
            color: @color-textbox-surface;
            background: @color-selected-deep;
        }

        &:disabled {
            color: @color-panel-face-disabled;
        }

        .wrapper.with-soft-icon {
            position: relative;
            padding-right: 1.5em;

            .softIcon {
                position: absolute;
                right: -0.5em;
                opacity: 0.5;
                font-size: 0.9em;
            }
        }
    }

    &.preset-round {
        .vertical-flex;
        justify-content: center;
        cursor: pointer;
        right: 0px;
        top: 6px;
        z-index: 10;
        width: 1.42em;
        height: 1.42em;
        border: none;
        border-radius: 20px;
        background: #e5e5e5;
        margin-right: 25px;
        text-align: center;
        font-size: 14px;
        transition: opacity 0.6s @ease-out-cubic;

        &:hover {
            background: #e0e0e0;
        }
        &:active {
            color: white;
            background: @color-selected-deep;
        }
    }
}
