.TextInput {
    .horizontal-flex;
    justify-content: space-between;
    position: relative;
    height: @datarow-height;
    font-size: inherit;

    &.loading > .value {
        animation: @gui-standby-loading-animation;
        pointer-events: none;
    }

    .caption {
        flex-grow: 1;
        height: @datarow-height - 2px;
        margin-right: 4px;
        border-radius: @textbox-border-radius;
        font-size: @label-font-size;
        font-weight: normal;
        line-height: @datarow-height - 2px;
        transition:
            opacity 0.3s @ease-out-cubic,
            padding 0.3s @ease-out-cubic;
        cursor: inherit;
    }

    .value {
        position: relative;
        width: @datarow-input-width;
        height: inherit;

        > input {
            display: block;
            box-sizing: border-box;
            width: inherit;
            height: inherit;
            padding: 0.15em 0.35em;
            margin: 0;
            border: 1px solid @color-textbox-border;
            border-bottom-color: @color-textbox-border-shadow;
            border-radius: @textbox-border-radius;
            font: inherit;
            transition: 0.3s @ease-out-cubic;
            transition-property: width, margin-left;

            @media @retina {
                border: 0.5px solid @color-textbox-border-hidpi;
                border-bottom-color: @color-textbox-border-shadow-hidpi;
            }

            &::placeholder {
                color: @color-panel-face-placeholder;
            }
            &::-moz-placeholder {
                color: @color-panel-face-placeholder;
            }
            &::-webkit-input-placeholder {
                color: @color-panel-face-placeholder;
            }
        }

        > .action {
            .horizontal-flex;
            justify-content: center;
            @padding: 2px;
            @size: @datarow-height - @padding * 2;
            position: absolute;
            right: @padding;
            top: @padding;
            width: @size;
            height: @size;
            border-radius: 3px;
            background: @color-textbox-surface;
            font-size: 12px;
            cursor: pointer;

            &.clear {
                color: @color-panel-face-quarter;

                &:hover,
                &:active {
                    color: @color-panel-face-half;
                }
            }

            &:active {
                color: @color-selected;
            }
        }
    }

    &:not(.disabled) {
        > .caption {
            cursor: pointer;
            &:hover,
            &:active {
                color: @color-selected-deep;
                padding-left: 4px;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.02);
            }
            &:active {
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }

    &.disabled {
        > .caption {
            pointer-events: none;
        }
        > .caption,
        input {
            color: @color-panel-face-disabled;
        }
        input {
            background-color: @color-textbox-surface-disabled;
        }
    }

    &.auto-expand.editing {
        .caption {
            pointer-events: none;
            opacity: 0;
        }
        .value > input:focus {
            width: 258px;
            margin-left: -138px;
            text-align: center;
        }
    }

    > .suggestions {
        pointer-events: all;
        .vertical-flex;
        padding: 5px 0;
        margin: 5px;
        background: rgb(250, 250, 250);
        border-radius: 5px;
        box-shadow:
            0 0 0 1px rgba(0, 0, 0, .05),
            0 8px 22px rgba(0, 0, 0, .2),
            inset 0 1px 0 1px rgba(255, 255, 255, .25);
        animation: slight-zoom-in 0.1s @ease-out-cubic;
        position: absolute;
        display: block;
        left: -6px;
        top: @datarow-height + 2px;
        z-index: 10;
        max-height: 40vh;
        overflow-y: auto;

        > .item {
            cursor: default;
            position: relative;
            padding: 3px 30px 3px 11px;
            color: @color-panel-face;
            text-align: left;
            white-space: nowrap;
            max-width: 220px;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &.checked::after {
                content: "\e90c";
                font-family: 'seatsdesigner';
                display: block;
                position: absolute;
                top: 50%;
                margin-top: -0.7em;
                right: 6px;
                color: @color-panel-face-half;
            }

            &:hover:not(:disabled),
            &.selected:not(:disabled) {
                color: @color-textbox-surface;
                background: @color-selected;

                &.checked::after {
                    color: rgba(255, 255, 255, .5);
                }
            }

            &:disabled {
                color: @color-panel-face-disabled;
            }
        }
    }
}
