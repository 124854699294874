.ChartList {
    width: 100vw;

    > .container {
        max-width: 1040px;
        margin: 0 auto;
        padding: 20px 0;
    }
}

.ChartListItem {
    .horizontal-flex;
    align-items: normal;
    background: #f7f8f9;
    height: 180px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid hsl(0, 0%, 87%);
    animation: slight-zoom-in 0.8s @ease-out-cubic;

    &.loading {
        pointer-events: none;
        animation: @gui-standby-loading-animation-large;

        > .chart,
        > .events {
            opacity: 0.6;
        }
    }

    > .chart {
        position: relative;
        .horizontal-flex;
        align-items: flex-start;
        flex-grow: 1;
        height: 100%;
        box-sizing: border-box;
        border-radius: inherit;
        box-shadow: 3px 0 20px rgba(0, 0, 0, .05),
        0 0 0 1px rgba(0, 0, 0, .03);

        > .Button.preset-round {
            pointer-events: all;
            position: absolute;
            right: -5px;
            top: 15px;
            opacity: 0;
            font-size: 16px;
            transition: opacity 0.3s @ease-out-cubic;
        }

        &:hover {
            > .Button.preset-round {
                opacity: 1;
                transition-delay: 0.3s;
            }
        }

        > .surface {
            .full-size-absolute;
            z-index: 0;
            box-sizing: border-box;
            background: white;
            border: 1.5px solid white;
            border-radius: inherit;
            transition: border-color 0.2s @ease-out-cubic;

            > .icon {
                position: absolute;
                right: 15px;
                top: 50%;
                margin-top: -17px;
                font-size: 30px;
                color: hsl(0, 0%, 80%);
                opacity: 0;
                transition: opacity 0.5s @ease-out-cubic;
            }

            &:hover {
                border-color: @color-selected;

                > .icon {
                    opacity: 1;
                }
            }

            &:active {
                border-color: @color-selected-deep;
                background: hsl(0, 0%, 98.5%);

                > .icon {
                    color: @color-selected-deep;
                    opacity: 1;
                }
            }
        }

        > .description {
            pointer-events: none;
            position: relative;
            .vertical-flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-grow: 1;
            padding: 13px;
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            .title {
                max-width: 380px;
                padding-right: 40px;
                box-sizing: border-box;
                font-size: 22px;
                font-weight: 500;
                word-wrap: break-word;
                overflow-wrap: break-word;
                overflow-x: hidden;
            }

            .status {
                .horizontal-flex;
                position: relative;
                left: -5px;
                margin-top: 7px;

                > .caption {
                    .horizontal-flex;
                    padding: 5px;
                    border-radius: 6px;
                    margin-right: 5px;
                    font-size: 14px;

                    &.published {
                        color: @color-alert-valid;
                    }

                    &.with-options {
                        pointer-events: all;
                        cursor: pointer;
                        color: @color-alert-warning;
                        padding-left: 8px;

                        &:hover {
                            background-color: hsla(44, 95%, 59%, 0.12);
                            color: @color-alert-warning-deep;
                        }

                        &:active,
                        &.showing-options {
                            background-color: hsla(44, 95%, 59%, 0.2);
                            color: @color-alert-warning-deep;
                        }
                    }

                    > .icon {
                        margin-right: 0.25em;
                        font-size: 15px;
                    }

                    > .icon-arrow-medium-down {
                        position: relative;
                        top: 1px;
                        display: inline-block;
                        margin-left: 5px;
                        font-size: 12px;

                        &.flipped {
                            transform: translateY(-1px) rotateX(180deg);
                        }
                    }
                }
            }

            .tags {
                max-height: 2.4em;
                margin-top: 0.5em;
                overflow: hidden;
                font-size: 15px;
                color: #999;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 300px;
                overflow-y: hidden;

                > .tag {
                    display: inline-block;
                    margin-right: 8px;

                    &::before {
                        content: "#"
                    }
                }
            }

            .validation {
                display: inline-block;
                pointer-events: all;
                position: relative;
                color: @color-alert-warning-deep;
                font-size: 14px;
                border-radius: 6px;
                left: -5px;
                margin-top: 5px;

                > .validation-text-and-icons {
                    .horizontal-flex;
                    padding: 5px;
                    border-radius: 6px;
                    cursor: pointer;
                    user-select: none;

                    .icon {
                        margin-right: 0.25em;
                        font-size: 16px;
                    }

                    .icon-arrow-medium-down {
                        position: relative;
                        top: 1px;
                        margin-left: 5px;
                        font-size: 12px;
                        display: inline-block;

                        &.flipped {
                            transform: translateY(-1px) rotateX(180deg);
                        }
                    }

                    &:hover {
                        background-color: hsla(44, 95%, 59%, 0.12);
                    }

                    &:active,
                    &.showing-options {
                        background-color: hsla(44, 95%, 59%, 0.2);
                        color: @color-alert-warning-deep;
                    }

                    &.error{
                        color: @color-alert-danger;

                        &:hover {
                            background-color: hsla(358, 93%, 86%, 0.3);
                        }

                        &:active,
                        &.showing-options {
                            background-color: hsla(358, 93%, 86%, 0.4);
                            color: @color-alert-danger;
                        }
                    }
                }
            }
        }
    }

    > .events {
        .vertical-flex;
        align-items: flex-start;
        position: relative;
        width: 390px;
        flex-shrink: 0;
        padding: 15px 0 15px 25px;
        box-sizing: border-box;

        > .title {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 8px;
        }

        > .footer-link {
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;
            font-size: 14px;
            text-align: center;
        }

        > .create-new-event {
            align-self: flex-end;
            position: absolute;
            right: 15px;
            top: 15px;

            &.loading {
                pointer-events: none;
                animation: @gui-standby-loading-animation;
            }

            .icon {
                position: relative;
                top: 1px;
                margin-right: 5px;
                font-size: 13px;
            }

            button {
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                color: @color-selected;
                font-size: 14px;

                &:hover > span {
                    .link-gradient-hover;
                }

                &:active > span {
                    .link-gradient-active;
                }
            }
        }

    }

    &.contextual-menu-opened > .chart {
        > .surface,
        > .thumbnail {
            pointer-events: none;
        }

        > .surface {
            border-color: @color-selected;
        }

        > .Button.preset-round {
            background-color: @color-selected;
            opacity: 1;
            color: white;
        }
    }

    &.loading-placeholder {
        position: relative;
        max-width: 1040px;

        > .chart {
            .vertical-flex;
            min-height: 170px;
            padding-left: 210px;
            padding-right: 40px;
            padding-top: 20px;

                .fake-item {
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.08);
                }
            }

            > .events {
                padding-top: 30px;
                padding-right: 40px;

            .fake-item {
                font-size: 16px;
                background-color: rgba(57, 85, 121, 0.12);
            }
        }
    }

    @media @narrow-width {
        border-radius: 0;
        min-height: 180px;
        height: auto;

        .surface {
            min-height: 180px;
        }

        > .chart {
            flex-direction: column-reverse;
            align-items: flex-start;
            padding-left: 10px;
            padding-bottom: 15px;

            > .description {
                padding-bottom: 10px;

                .title {
                    max-width: 100%;
                    font-size: 22px;
                }

                .tags {
                    font-size: 14px;
                }
            }
        }

        &.placeholder {
            > .chart {
                padding-left: 40px;
            }
        }
    }

    @media @thin-width {
        border-radius: 0;
        min-height: 180px;
        height: auto;

        .surface {
            min-height: 180px;
        }

        > .chart > .description {
            .title {
                max-width: none;
            }
        }

        > .events {
            display: none;
        }
    }

}
