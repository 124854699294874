@form-text-input-width: 320px;

form {
    position: relative;

    input.error,
    select.error {
        box-shadow: 0 0 0 2px @color-alert-danger;
    }

    &.submitting,
    &.submitted {
        input,
        select {
            pointer-events: none;
        }

        .form-field {
            pointer-events: none;
            opacity: 0.4;
        }
    }

    &.submitted {
        .form-actions, .firm-commitment-notice {
            visibility: hidden;
        }
        .submitted-notice {
            opacity: 1;
        }
        .form-field {
            height: 0;
        }
    }

    &:not(.submitted) {
        .submitted-notice {
            pointer-events: none;
        }
    }

    .submitted-notice {
        .full-size-absolute;
        .vertical-flex;
        z-index: 1;
        justify-content: center;
        background: white;
        opacity: 0;
        transition: opacity 1s @ease-out-cubic;
    }

    .errors {
        .vertical-flex;
        align-items: flex-start;
        .solidify;
        margin: 0 0 8px;

        > .error {
            padding: 2px 10px;
            border-radius: 1em;
            color: white;
            background: @color-alert-danger;
            font-size: 12px;
            font-weight: 500;
            margin: 4px auto;
        }
    }

    .form-field {
        input:not([type="checkbox"]) {
            width: @form-text-input-width;
            padding: .5rem;
            font-size: 16px;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            max-width: 100%;

            &.error {
                border-color: red;
            }
        }
    }

    .hint {
        margin: 0.5em 0 0;
        max-width: @form-text-input-width;
        color: @color-panel-face-half;
        font-size: 13px;
        text-align: left;
        line-height: 1.25em;

        &.center {
            text-align: center;
        }

        &.dark {
            color: @color-panel-face !important;
        }

    }

    .form-field + .hint {
        margin-top: 25px;
    }

    .form-field.single-field {
        justify-content: flex-start;
        align-items: flex-start;

        &.center {
            justify-content: center;
        }

        input[type="checkbox"] {
            margin: 3px 15px 0 0;
        }

        .checkbox-label {
            > label {
                max-width: none;
            }
            > a {
                display: inline-block;
                font-size: 0.9em;
                margin-top: 0.25em;
            }
        }
    }

    .form-field.checkbox-list {
        max-height: calc(100vh - 300px);
        overflow-y: auto;

        > label.field {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 8px;
            border-radius: 6px;
            color: hsl(0, 0%, 15%);

            > input {
                margin-right: 9px;
            }

            &.selected {
                color: black;
                background: hsla(211, 100%, 30%, 0.06);
            }

            + label.field {
                margin-top: 2px;
            }
        }
    }

    .input-list {
        overflow-y: scroll;
        width: 100%;
		max-height: 203px;
		animation: max-height-from-zero 0.5s @ease-out-cubic backwards;
        background-color: @color-subpanel-surface;
        border: 1px solid @color-subpanel-border;
        border-radius: 8px;

        &.with-sub-captions {
            max-height: 244px;
        }

        .list-item {
			box-sizing: border-box;
			width: 100%;
			display: flex;
			flex-direction: row;
            align-items: center;

            &:hover {
                background-color: @color-list-background-hover;
            }

			input {
                margin-left: 10px;
            }

			input[type="radio"] {
                flex-shrink: 0;
            }

			input[type="checkbox"] {
                flex-shrink: 1;
                width: 18px;
                height: 18px;
            }

            label {
                cursor: pointer;
                display: block;
                flex-shrink: 1;
                width: 100%;
                padding: 8px;
                box-sizing: border-box;
                color: @color-panel-face-soft;
                font-size: 15px;
                text-align: left;

                .sub-caption {
                    font-size: 13px;
                    color: @color-panel-face-disabled
                }
            }

			& + .list-item label {
                border-top: 1px solid @color-list-border;
			}

            input:checked + label {
                color: @color-selected;
            }

            input:disabled + label {
                cursor: default;
                color: @color-panel-face-disabled;
            }
        }
    }

	.field-options {
		display: flex;
		flex-direction: row;
		justify-content: center;
        margin: 25px auto 0;
        background: hsl(0, 0%, 94%);
		box-sizing: border-box;
		box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, 0.02);
        padding: 3px;
		border-radius: 12px;

		&:last-child {
			margin-bottom: 0;
		}

		.field-option {
			flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            color: hsla(0, 0%, 35%);
            background: hsla(0, 0%, 100%, 0);
            box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0);
            transition: all 0.3s @ease-out-cubic;

            label {
                cursor: pointer;
                padding: 5px 20px;

                .icon {
                    font-size: 44px;
                    text-align: center;
                }

                .caption {
                    margin-top: 2px;
                    font-size: 14px;
                }
            }

			input[type="radio"] {
				display: none;
			}

			input[type="radio"]:checked + label {
				color: @color-selected;
			}

			input[type="radio"]:disabled + label {
				color: lightgrey;
			}

			&.selected {
				background: white;
                box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1);
                color: hsla(0, 0%, 10%);
			}

			&:hover {
				color: hsla(0, 0%, 10%);
			}
		}

		label {
			text-align: center;

			&:hover {
				cursor: pointer;
			}
		}

		&.no-icons {
			width: auto;

			.field-option {
				flex-grow: 0;
				box-sizing: border-box;
				border: 1px solid transparent;

				label {
					font-size: 16px;
					padding: 5px 25px;
				}

				&:hover {
					cursor: pointer;
				}
			}

			.field-option.production.selected {
				border: 1px solid hsl(335, 57%, 82%);
                box-shadow: 0 1px 3px hsla(335, 100%, 44%, 0.3);

				label {
					color: hsl(335, 79%, 41%);
				}
			}

			.field-option.test.selected {
				border: 1px solid hsl(144, 48%, 78%);
                box-shadow: 0 1px 3px hsla(143, 100%, 40%, 0.3);

				label {
					color: hsl(143, 73%, 29%);
				}
			}
		}
	}

    .form-group {
        width: 100%;
		&.hidden {
			display: none;
		}
    }
}

.two-column {
    margin: 0 auto;
    width: 550px;

    .form-field {
        .horizontal-flex;
        justify-content: space-between;
        align-items: flex-start;
        width: inherit;
        margin-top: 2.5em;
        font-size: 15px;
        transition:
            opacity 0.5s @ease-out-cubic,
            height 1s @ease-out-cubic;

        &.wide {
            width: 100%;
        }

        > .description {
            width: 240px;
            padding-top: 6px;

            label {
                display: block;
            }

            .hint {
                margin-top: 0.25em;

                &:not(a) {
                    color: hsl(0, 0%, 55%);
                }
            }
        }

        > .field {
            width: 220px;

            .hint {
                margin-top: 0.5em;
                color: hsl(0, 0%, 55%);

                .price {
                    margin-top: 0.5em;
                }
            }
        }

        select {
            width: 220px;
            font-size: 16px;
            box-sizing: border-box;
        }
    }

    .form-actions {
        .horizontal-flex;
        justify-content: center;
        margin-top: 80px;
        height: 40px;

        input[type="submit"] {
            cursor: pointer;
            appearance: none;
            display: block;
            height: 2.4em;
            padding: 0 1.8em;
            border: none;
            line-height: 2.3em;
            border-radius: 0.6em;
            background: @color-selected;
            color: white;
            font-size: 18px;
            text-align: center;
            font-weight: 500;

            &:hover {
                background: @color-selected-highlight;
            }

            &:active {
                background: @color-selected-press;
            }

            &:disabled {
                cursor: default;
                background: hsl(0, 0%, 65%)
            }
        }
    }
}


.sectioned-page > .sectioned-page-container > .section form.newStyle {
    input, select {
        max-width: unset;
        display: block;
        padding: 12px;
        width: 100%;
        margin-top: 1em;
        font-size: inherit;
        border-color: #e6e6e6;
    }

    .flex-wrapper {
        display: flex;
        gap: 1em;
        justify-content: space-between;
    }

    label {
        font-size: 15px;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .hint {
        max-width: unset;
    }

    .action {
        display: block;
        margin: 40px auto;
    }

    .description {
        margin-top: 2.5em;
    }

    .termsAgreement {
        & > input {
            width: auto;
        }
        margin: 3em 0 .5em 0;
        display: flex;
        justify-self: start;
        justify-items: left;
        align-items: baseline;
        gap: .25em;

        & > input {
            position: relative;
            top: 2px;
        }

        & > .terms {
            margin-left: auto;
        }
    }
}