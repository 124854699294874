.WhitelistedDomains {
    .horizontal-flex;
    flex-wrap: wrap;

    .domain {
        cursor: pointer;
        margin: 2px 10px 2px 0;
        position: relative;
        border: @focus-ring-width solid transparent;
        padding: 0 2px 0 12px;
        transition: all .3s @ease-out-cubic;
        white-space: nowrap;

        &.loading {
            pointer-events: none;
            animation: @gui-standby-loading-out-animation;
            opacity: 0.6;
        }

        &::before {
            color: @color-panel-face-half;
            position: absolute;
            left: 1px;
            transform: translateX(0) rotate(0);
            transition: all .4s @ease-out-cubic;
        }

        > .caption {
            display: inline-block;
            transform: translateX(0);
            transition: transform .4s @ease-out-cubic;
        }

        > .icon {
            position: absolute;
            left: 0;
            top: 3px;
            color: @color-alert-danger;
            opacity: 0;
            transition:
                opacity .4s @ease-out-cubic,
                transform .4s @ease-out-cubic;
            font-size: 12px;
            transform: translateX(-2px) rotate(-45deg);
        }

        &:hover {
            > .caption {
                transform: translateX(3px);
                text-decoration: line-through;
            }

            > .icon {
                opacity: 1;
                transform: translateX(0) rotate(0deg);
            }

            &::before {
                opacity: 0;
                transform: translateX(2px) rotate(45deg);
            }
        }

        &:active {
            opacity: 0.5;
        }
    }

    .button {
        cursor: pointer;
        color: @color-selected;
        margin: 2px 0 2px;
        border: @focus-ring-width solid transparent;

        > .icon {
            position: relative;
            top: 1px;
            font-size: 14px;
            margin-right: 5px;
        }

        &:hover {
            > .icon,
            > .caption {
                .link-gradient-hover;
            }
        }

        &:active {
            > .icon,
            > .caption {
                .link-gradient-active;
            }
        }
    }

    .TextInput.loading {
        pointer-events: none;
        animation: @gui-standby-loading-animation;
    }

    .TextInput .value {
        position: relative;
        top: 2px;

        input {
            position: relative;
            outline: none;
            width: 150px;
            padding: 1px 3px 2px 10px;
            height: 1.4em;
            border: 0;
            border-radius: 5px;
            margin: 2px 10px 2px 0;
            font-size: inherit;
            box-shadow: 0 0 0 @focus-ring-width @color-focus-outline;
            animation: grow-from-width-0 .4s @ease-out-cubic forwards;
            transition: box-shadow .3s @ease-out-cubic;
            background: transparent;

            &.invalid-input {
                box-shadow: 0 0 0 @focus-ring-width @color-alert-danger;
            }

            @keyframes grow-from-width-0 {
                from {
                    width: 0px;
                }
            }
        }

        .floating-error {
            position: fixed;
            margin-top: 3px;
            pointer-events: none;
            background-color: @color-alert-danger;
            border: 0;
            padding: 2px 12px 3px;
            border-radius: 1em;
            color: white;
            font-size: 12px;
            transform-origin: left top;
            animation: slight-zoom-in 0.2s @ease-out-cubic;
        }
    }
}
