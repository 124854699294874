.EventDetail {
    --side-padding: 30px;

    .vertical-flex;
    align-items: flex-start;
    flex-grow: 1;
    height: 100%;
    position: relative;
    padding: 25px 0 0;
    box-sizing: border-box;

    > .top-panels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 var(--side-padding);
        box-sizing: border-box;

        > .controls-panel {
            > .over-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 3px;
                color: hsl(0, 0%, 60%);

                &:hover {
                    color: @color-selected-highlight;
                }

                > .icon {
                    position: relative;
                    top: 1px;
                    margin-inline-end: 5px;
                    font-size: 16px;
                }

                > .label {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            > .title {
                font-weight: 500;
                font-size: 26px;
                -webkit-user-select: all;
                user-select: all;
            }

            > .title > button {
                border: none;
                margin: 0 0 0 2px;
                padding: 3px 7px;
                background: none;
                outline: none;
                cursor: pointer;
                color: @color-selected;
                font-size: 0.7em;
            }

            > .title.small {
                font-size: 18px;
            }

            > .title.placeholder {
                color: hsl(0, 0%, 60%);
            }

            > .title.fixed-width {
                display: inline-block;
                background: hsl(0, 0%, 98%);
                border: 0.75px solid hsl(0, 0%, 88%);
                padding: 1px 5px 1px 8px;
                border-radius: 0.2em;

                > button {
                    position: relative;
                    top: -1px;
                    margin: 0 0 0 10px;
                    padding: 1px 3px 1px 10px;
                    border-left: 1px solid hsl(0, 0%, 85%);
                    font-size: 0.8em;
                }
            }

            > .title + .title {
                margin-top: 6px;
            }

            > .event-actions {
                display: flex;
                margin-top: 8px;

                > .action-note,
                > button {
                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }

                > .action-note {
                    .solidify;
                    display: flex;
                    height: 1.5em;
                    flex-direction: row;
                    align-items: center;
                    color: @color-panel-face-disabled;

                    > .icon {
                        position: relative;
                        top: 1px;
                        margin-inline-end: 5px;
                        font-size: 16px;
                    }

                    > .label {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                > button {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 1.5em;
                    color: @color-selected;
                    padding: 3px;
                    font-size: 14px;
                    font-weight: 500;
                    border: none;
                    background: transparent;

                    > .caption {
                        color: inherit;
                    }

                    > .icon {
                        position: relative;
                        top: 1px;
                        font-size: 16px;
                        margin-right: 5px;
                    }

                    &:hover > .icon,
                    &:hover > .caption {
                        .link-gradient-hover;
                    }

                    &:active > .icon,
                    &:active > .caption {
                        .link-gradient-active;
                    }

                    &.loading {
                        pointer-events: none;
                        animation: @gui-standby-loading-out-animation;
                    }
                }
            }
        }

        > .info-panel {
            flex-grow: 1;
            margin-left: 50px;
            max-width: 500px;

            > .OccupancyProgressBar {
                margin-bottom: 22px;
            }

            > .definition {
                font-size: 14px;
                color: hsl(0, 0%, 65%);

                &:not(:first-child) {
                    margin-top: 7px;
                }

                > .label {
                    margin-right: 0.2em;
                }
            }
        }
    }

    > .PageSwitcher {
        width: 100%;
        margin-top: 20px;
        width: 100%;
        padding: 0 calc(var(--side-padding) - 15px);
    }

    > .subpage {
        .vertical-flex;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        flex-shrink: 1;

        > .hint {
            margin: 10px var(--side-padding) 15px;
            color: hsl(0, 0%, 30%);
            font-size: 13px;
        }

        > .event-manager {
            width: 100%;
            min-height: calc(100% - 40px);
            flex-shrink: 1;
            overflow: hidden;
        }

        > .DataTable {
            position: relative;
            width: 100%;
            padding-top: 30px;
        }

        > .loading-placeholder {
            width: 100%;
        }

        > .loading-placeholder > .fake-item {
            width: 50%;
            background-color: rgba(0, 0, 0, 0.08);
        }
    }

    > .fake-item,
    > .loading-placeholder > .fake-item {
        width: 50%;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.08);

        &.big {
            width: 70%;
            font-size: 30px;
        }
    }

    .SeasonDetail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        > .events-column {
            flex-grow: 1;
            width: 50%;
            padding: var(--side-padding);
            box-sizing: border-box;

            > .title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            > .event {
                > .container {
                    display: block;
                    padding: 10px;

                    &:hover {
                        color: @color-selected-highlight;
                    }
                }

                & + .event {
                    border-top: 1px solid @color-list-border;
                }
            }
        }
    }
}
