.NumericInput {
    .horizontal-flex;
    justify-content: space-between;
    position: relative;
    height: @datarow-height;
    font-size: inherit;

    > .caption {
        cursor: inherit;
        flex-grow: 1;
        height: @datarow-height - 2px;
        margin-right: 4px;
        border-radius: @textbox-border-radius;
        font-size: @label-font-size;
        font-weight: normal;
        line-height: @datarow-height - 2px;
        transition: padding 0.3s @ease-out-cubic;
    }

    .value {
        position: relative;
        width: @datarow-input-width;
        
        .label,
        input[type=text] {
            display: block;
            box-sizing: border-box;
            width: inherit;
            height: @datarow-height;
            margin: 0;
            border: 1px solid @color-textbox-border;
            border-bottom-color: @color-textbox-border-shadow;
            border-radius: @textbox-border-radius;
            background: @color-textbox-surface;
            font: inherit;
            text-align: center;
            line-height: 1.6em;

            @media @retina {
                border: 0.5px solid @color-textbox-border-hidpi;
                border-bottom-color: @color-textbox-border-shadow-hidpi;
            }
        }

        input[type=text] {
            padding: 0.15em 0.5em;
            line-height: 1.7em;
            
            &:blur {
                overflow: hidden;
                white-space: nowrap; 
                text-overflow: ellipsis;
            }
        }

        .label {
            position: absolute;
            left: 0;
            top: 0;
            .horizontal-flex;
            justify-content: space-between;

            .caption,
            .placeholder {
                box-sizing: border-box;
                padding: 0 0.5em;
                flex-grow: 1;
                height: inherit;
                border-radius: 4px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1.9em;
            }
            .unit {
                margin-left: 0.05em;
                color: @color-panel-face-half;
            }
            .placeholder {
                color: @color-panel-face-placeholder;
            }
            
            .icon-arrow-light-left,
            .icon-arrow-light-right {
                cursor: default;
                font-size: 0.75em;
                height: inherit;
                line-height: 2.4em;
                color: @color-panel-face-quarter;
                transition: color 0.3s @ease-out-cubic;

                &.icon-arrow-light-left {
                    padding-left: 2px;
                }
                &.icon-arrow-light-right {
                    padding-right: 2px;
                }
                &.disabled {
                    opacity: 0.4;
                }
                &:not(.disabled) {
                    &:hover {
                        color: @color-panel-face-half;
                    }
                    &:active {
                        color: @color-selected;
                    }
                }
            }

            box-shadow: 0 0 0 @focus-ring-width @color-focus-outline-transparent;
            transition: box-shadow 0.3s @ease-out-cubic;
        }

        .editable {
            width: inherit;
        }
    }

    &.scrubbing {
        > .value > .label {
            border-color: @color-selected-deep;
            box-shadow: 0 0 0 @focus-ring-width @color-selected;
            
            .icon-arrow-light-left,
            .icon-arrow-light-right {
                color: @color-panel-face-half;
            }
        }
    }

    &.previewing-value {
        > .value > .label {
            color: @color-preview-value;
        }
    }

    &:not(.disabled) {
        > .caption {
            cursor: pointer;
            &:hover,
            &:active {
                color: @color-selected-deep;
                padding-left: 4px;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.02);
            }
            &:active {
                background: rgba(0, 0, 0, 0.04);
            }
        }
        > .value > .label {
            cursor: ew-resize;

            .caption:hover,
            .placeholder:hover {
                background: rgba(30, 30, 30, .03);
            }
        }
        .caption,
        .placeholder {
            cursor: ew-resize;
        }
        
        &:active {
            > .value > .label {
                background: @color-textbox-surface;
            }
        }

        &.pulse {
            z-index: 1;
            .label {
                animation: focus-ring-pulse 3s @ease-out-cubic infinite;
            }
        }
    }

    &.disabled {
        > .value > .label {
            background-color: @color-textbox-surface-disabled;
        }
        > .caption,
        > .value > .label {
            color: @color-panel-face-disabled;
        }
    }

	&.loading > .value {
		pointer-events: none;
        animation: @gui-standby-loading-animation;
        transform-origin: center center;
        
        .label,
        input[type=text] {
            border-color: black;
        }
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.NumericInput {
    .label,
    input[type=text] {
        border: 0.5px solid @color-textbox-border-hidpi;
        border-bottom-color: @color-textbox-border-shadow-hidpi;
    }
}
}