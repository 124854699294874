@datarow-height: 26px;
@datarow-input-width: 120px;

@textbox-border-radius: 0.25em;
@label-font-size: 13px;

@focus-ring-width: 3px;

.horizontal-flex {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.vertical-flex {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.zero-top-right-bottom-left {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.full-size-absolute {
	position: absolute;
	.zero-top-right-bottom-left;
}


/*** Media Queries ***/

@retina: ~"(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
@narrow-width: ~"(max-width: 1039px)";
@thin-width: ~"(max-width: 740px)";
