.EmailCompanies {
    & .sectioned-page-container 
    {
        padding: 2em;
        background: red;
        
    }

    .container {
        padding: 2em;
    }

    & textarea {
        width: 100%;
        height: 300px;
    }

    & input[type="text"] {
        max-width: unset !important;
        margin-bottom: 0.5em;
    }

    & .preview {
        position: absolute;
        width: 60%;
        height: 100%;
        z-index: 1000;
    }

    & .button {
        margin: 1em auto;
    }
}
